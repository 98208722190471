import { Stack, Avatar, Typography, Grid, Box } from "@mui/material";
import { LiveLoan } from "../../../clients/LoanClient";
import InformationIcon from '../../../assets/img/webp/information-2x.webp';
import ChevronRight from '../../../assets/img/chevron-right.png';
import OverdueIcon from '../../../assets/img/OverdueIcon.png';
import { theme } from "../../../theme/Theme";
import { formatMoneyWithoutRounding } from "../../../utils/Helpers/SliderHelperFunctions";
import { formatDateWithDefault } from "../../../utils/Helpers/DateHelpers";
import { useNavigate } from "react-router-dom";
import { getFriendlyOpenLoanStatus, OpenLoanStatusEnum } from "../../../utils/Helpers/OpenLoanStatusEnum";

type LiveLoansProps = {
    loans: LiveLoan[];
}

const LiveLoans: React.FunctionComponent<LiveLoansProps> = (props: LiveLoansProps) => {
    const navigate = useNavigate();
    const liveLoans = props.loans.filter(l => getFriendlyOpenLoanStatus(l.openLoanStatus) === OpenLoanStatusEnum.Active || getFriendlyOpenLoanStatus(l.openLoanStatus) === OpenLoanStatusEnum.InArrears);

    if (liveLoans.length === 0)
        return <></>;

    const isLastItem = (index: number) => {
        return index === (liveLoans.length - 1);
    }

    return (
        <>
            <Stack flexDirection={'row'} alignItems={'flex-start'} justifyContent={'flex-start'} gap={'1rem'} marginTop={{ xs: '2.4rem', md: '2.8rem' }}>
                <Avatar src={InformationIcon} alt="icon" sx={{ width: '1.6rem', height: '1.6rem' }} />
                <Typography fontSize={'1.5rem'} fontWeight={400} lineHeight={'2rem'} marginTop={{ xs: '-3.5px' }}>
                    Please select a loan to view all account & document related information.
                </Typography>
            </Stack>
            <Typography fontSize={{ xs: '1.8rem', md: '2.4rem' }} fontWeight={400} lineHeight={{ xs: '3.2rem', md: '6.4rem' }} letterSpacing={'-0.3px'} marginTop={'1.6rem'}>
                Active loans
            </Typography>
            {liveLoans.map((loan: LiveLoan, index) => {
                return (
                    <Grid
                        key={index}
                        container
                        flexDirection={"row"}
                        padding={'1.2rem'}
                        marginTop={'1rem'}
                        marginBottom={{ xs: isLastItem(index) ? '0' : '2rem', md: isLastItem(index) ? '0' : '2.4rem' }}
                        sx={{ backgroundColor: theme.palette.grey[200], borderRadius: '1rem', cursor: 'pointer' }}
                        onClick={() => navigate(`${loan.loanId}`)}
                    >
                        {/* Left column */}
                        <Grid item xs={11} md={9}>
                            <Box>
                                <Box display={'flex'} flexDirection={'row'} gap={'1.2rem'}>
                                    <Typography variant="body1" fontSize={'1.8rem'} fontWeight={600} lineHeight={'2.2rem'} color={theme.palette.primary.dark}>
                                        R {formatMoneyWithoutRounding(loan.currentBalance ?? 0, 2)}
                                    </Typography>
                                    <Avatar src={OverdueIcon} sx={{ display: { xs: 'initial', md: 'none' }, width: '74px', height: '26px', borderRadius: 0 }} />
                                </Box>
                                <Typography fontSize={{ xs: '1.2rem' }} fontWeight={300} lineHeight={{ xs: '2,4rem' }} letterSpacing={'-0.3px'}>
                                    Settlement balance
                                </Typography>
                            </Box>
                            <Grid container flexDirection={"row"}>
                                <Grid xs={4} md={2} item sx={{ marginTop: '1.6rem ' }}>
                                    <Typography variant="body1" fontSize={'1.2rem'} fontWeight={500}>Applied</Typography>
                                    <Typography variant="body1" fontSize={'1.2rem'} fontWeight={300} lineHeight={'24px'}>{formatDateWithDefault(loan.createdOn)}</Typography>
                                </Grid>
                                <Grid xs={4} md={2} item sx={{ marginTop: '1.6rem ' }}>
                                    <Typography variant="body1" fontSize={'1.2rem'} fontWeight={500}>Amount</Typography>
                                    <Typography variant="body1" fontSize={'1.2rem'} fontWeight={300} lineHeight={'24px'}>R{formatMoneyWithoutRounding(loan.loanAmount ?? 0, 2)}</Typography>
                                </Grid>
                                <Grid xs={4} md={2} item sx={{ marginTop: '1.6rem ' }}>
                                    <Typography variant="body1" fontSize={'1.2rem'} fontWeight={500}>Account Number</Typography>
                                    <Typography variant="body1" fontSize={'1.2rem'} fontWeight={300} lineHeight={'24px'}>{loan.accountNumber}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Right column */}
                        <Grid item xs={1} md={3} container flexDirection={'row'} alignItems={'center'} justifyContent={'center'} gap={'1.6rem'}>
                            {getFriendlyOpenLoanStatus(loan.openLoanStatus) === OpenLoanStatusEnum.InArrears && <Avatar src={OverdueIcon} sx={{ display: { xs: 'none', md: 'initial' }, width: '74px', height: '26px', borderRadius: 0 }} />}

                            <Avatar src={ChevronRight} alt="icon" sx={{ width: '13.12px', height: '20.6px' }} />
                        </Grid>
                    </Grid>
                );
            })}
        </>
    );
}

export default LiveLoans;