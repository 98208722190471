import React from "react";
import { Box, Stack } from "@mui/material";
import Loading from "./Loading";

const ButtonLoadingIndicator = () => {
    return (
        <Stack
            flexDirection={{ xs: 'column', sm: 'row' }}
            justifyContent={{ xs: 'center', sm: 'flex-end' }}
        >
            <Box width={{ xs: "100%", sm: "25rem" }}>
                <Loading />
            </Box>
        </Stack>
    );
};

export default ButtonLoadingIndicator;
