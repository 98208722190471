import React from 'react';
import Typography from "@mui/material/Typography";
import { Link, useMediaQuery } from '@mui/material';
import { theme } from '../../../../theme/Theme';

const DoNotEngage = () => {
    const moneyAcademyUrl = "https://www.moneyacademy.co.za/home";
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <>
            <Typography variant="h2" marginBottom={"2.4rem"} fontSize={{ xs: "2.4rem", sm: "3.2rem" }}>
                Loan application unavailable
            </Typography>
            <Typography variant="body1" fontWeight={300} marginBottom={isMobile ? "1.4rem" : "0.2rem"}>
                Unfortunately due to your previous loan application behaviour, we are unable to assist you at this time.
            </Typography>
            <Typography variant="body1" fontWeight={300} marginBottom={"1.4rem"}>
                To help you get back on track, check out the&nbsp;
                <Link
                    rel="noreferrer"
                    target="_blank"
                    href={moneyAcademyUrl}
                    sx={{ textDecoration: "none" }}>
                    Money Academy
                </Link>
                &nbsp;to learn more about money.
            </Typography>
        </>
    )
}

export default DoNotEngage
