/* eslint  @typescript-eslint/no-non-null-assertion: 0 */
/* Turn rule off for file as used for setting values on page from response. If response is null then logic should remain. */
import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
    AffordabilityDetails,
    ErrorResponse,
    IGetEmploymentResponse,
    UpdateAffordabilityResponse,
    IGetAffordabilityResponse,
    IAffordabilityDetails,
} from "../../../../../clients/AccountClient";
import { Form, useForm } from "../../../../../components/Form/Form";
import FormInputLabel from "../../../../../components/Form/FormInputLabel";
import Loading from "../../../../../components/Loading/Loading";
import { useSnackBar } from "../../../../../contexts/SnackBarContext";
import useAccountClient from "../../../../../hooks/account/Client";
import { affordabilitySchema } from "../../../../../schemas/Schemas";
import InputFormController from "../../../../../components/MuiInput/FormControllers/InputFormController";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import LegalInformation from "../../../../../components/LegalInformation/LegalInformation";
import FormInputWrapper from "../../../../../components/Form/FormInputWrapper";
import { FULL_COLUMN_SIZE } from "../../../../../utils/GridColumnSizeDefinitions";
import { useWizardOutletContext } from "../../../Wizard";
import { useTracking } from "../../../../../Tracking/TrackingContext";

interface ChangeAffordabilityLNProps {
    employmentDetailResponse: IGetEmploymentResponse;
    affordabilityDetailsResponse: IGetAffordabilityResponse;
    isFetchingData: boolean;
    isEditing: boolean;
}

/* eslint-disable  @typescript-eslint/no-unused-vars*/
const ChangeAffordabilityLN: React.FC<ChangeAffordabilityLNProps> = (props: ChangeAffordabilityLNProps) => {
    const { employmentDetailResponse, affordabilityDetailsResponse, isFetchingData, isEditing } = props;
    const { next } = useWizardOutletContext();
    const { displaySnackBar } = useSnackBar();
    const client = useAccountClient();
    const [submitLoading, setSubmitLoading] = useState(false);
    const { TrackError } = useTracking();

    const affordabilityForm = useForm({
        criteriaMode: "all",
        mode: "onChange",
        schema: affordabilitySchema,
    });

    const rentOnChange = affordabilityForm.watch("rentAndTaxes");
    const goodsOnChange = affordabilityForm.watch("goods");
    const transportOnChange = affordabilityForm.watch("transport");
    const loanRepaymentsOnChange = affordabilityForm.watch("loanRepayments");
    const childMaintenanceOnChange = affordabilityForm.watch("childMaintenance");
    const disposableIncome = affordabilityForm.watch("disposableMonthlyIncome");

    const updateAffordabilityData = useCallback(() => {
        if (affordabilityDetailsResponse) {
                affordabilityForm.setValue(
                    "rentAndTaxes",
                    affordabilityDetailsResponse.affordability?.accomodation !== undefined ? affordabilityDetailsResponse.affordability.accomodation?.toString() : ""
                );
                affordabilityForm.setValue(
                    "goods",
                    affordabilityDetailsResponse.affordability?.household !== undefined ? affordabilityDetailsResponse.affordability.household!.toString() : ""
                );
                affordabilityForm.setValue(
                    "transport",
                    affordabilityDetailsResponse.affordability?.transport !== undefined ? affordabilityDetailsResponse.affordability.transport.toString() : ""
                );
                affordabilityForm.setValue(
                    "loanRepayments",
                    affordabilityDetailsResponse.affordability?.otherLoans !== undefined ? affordabilityDetailsResponse.affordability.otherLoans.toString() : ""
                );
                affordabilityForm.setValue(
                    "childMaintenance",
                    affordabilityDetailsResponse.affordability?.childMaintenance !== undefined ? affordabilityDetailsResponse.affordability.childMaintenance.toString() : ""
                );
        }
    }, [affordabilityDetailsResponse, affordabilityForm]);

    const updateEmploymentData = useCallback(() => {
        if (employmentDetailResponse) {
                affordabilityForm.setValue(
                    "grossIncome",
                    employmentDetailResponse.employment!.grossIncome!.toString()
                );
                affordabilityForm.setValue(
                    "netIncome",
                    employmentDetailResponse.employment!.netIncome!.toString()
                );
            }
    }, [employmentDetailResponse, affordabilityForm]);

    useEffect(() => {
        const disposable =
            +(employmentDetailResponse.employment?.netIncome ?? 0) -
            +affordabilityForm.getValues("rentAndTaxes") -
            +affordabilityForm.getValues("goods") -
            +affordabilityForm.getValues("transport") -
            +affordabilityForm.getValues("loanRepayments") -
            +affordabilityForm.getValues("childMaintenance");

        affordabilityForm.setValue(
            "disposableMonthlyIncome",
            disposable.toString()
        );
    }, [
        rentOnChange,
        goodsOnChange,
        transportOnChange,
        loanRepaymentsOnChange,
        childMaintenanceOnChange,
        affordabilityForm,
        employmentDetailResponse
    ]);

    useEffect(() => {
        updateEmploymentData();
    }, [updateEmploymentData]);

    useEffect(() => {
        updateAffordabilityData();
    }, [updateAffordabilityData]);

    useEffect(() => {
        affordabilityForm.trigger("disposableMonthlyIncome");
    }, [disposableIncome, affordabilityForm]);

    /* eslint-disable  @typescript-eslint/no-explicit-any*/
    const updateAffordability = async (data: any) => {
        try {
            setSubmitLoading(true);
            const affordabilityDetails: IAffordabilityDetails = {
                grossMonthlyIncome: data.grossIncome,
                monthlyIncome: data.netIncome,
                accomodation: data.rentAndTaxes,
                transport: data.transport,
                household: data.goods,
                otherLoans: data.loanRepayments,
                childMaintenance: data.childMaintenance,
            };

            const response: UpdateAffordabilityResponse =
                await client.updateAffordabilitylDetails(
                    new AffordabilityDetails(affordabilityDetails)
                );

            if (response.affordabilityUpdated) {
                displaySnackBar("Affordability saved", "success");
                next();
            }
        } catch (error) {
            displaySnackBar("Oops, an error has occurred please try again", "error");
            if (error instanceof ErrorResponse && error.errorCode === 400) {
                TrackError(error, error.message);
                displaySnackBar(error.message ?? "An validation error has occurred", 'error');
            } else {
                displaySnackBar('Oops, an error has occurred please try again', 'error');
            }
        } finally {
            setSubmitLoading(false);
        }
    };

    if (isFetchingData)
        return <Loading text="Please wait while we retrieve your details." />;

    return (
        <>
            <Typography variant="h2" fontSize={{ xs: "2.4rem" }} marginBottom={"1.4rem"} lineHeight={"3.5rem"} fontWeight={400}>
                Please confirm your monthly expenses
            </Typography>
            <Form form={affordabilityForm} onSubmit={updateAffordability}>
                <Grid container>
                    <FormInputLabel>
                        Monthly rent rates & taxes (exclude bond repayment)
                    </FormInputLabel>
                    <FormInputWrapper>
                        <InputFormController
                            name="rentAndTaxes"
                            label="Rent and taxes"
                            placeholder=""
                            type="number"
                            register={affordabilityForm.register}
                            control={affordabilityForm.control}
                            currencyField
                            disabled={isEditing}
                        />
                    </FormInputWrapper>

                    <FormInputLabel>
                        Monthly groceries & household goods
                    </FormInputLabel>
                    <FormInputWrapper>
                        <InputFormController
                            name="goods"
                            label="Goods"
                            placeholder=""
                            type="number"
                            register={affordabilityForm.register}
                            control={affordabilityForm.control}
                            currencyField
                            disabled={isEditing}
                        />
                    </FormInputWrapper>

                    <FormInputLabel>
                        Monthly commuting costs (exclude car repayments)
                    </FormInputLabel>
                    <FormInputWrapper>
                        <InputFormController
                            name="transport"
                            label="Transport"
                            placeholder=""
                            type="number"
                            register={affordabilityForm.register}
                            control={affordabilityForm.control}
                            currencyField
                            disabled={isEditing}
                        />
                    </FormInputWrapper>

                    <FormInputLabel>Monthly loan repayments</FormInputLabel>
                    <FormInputWrapper>
                        <InputFormController
                            name="loanRepayments"
                            label="Loan Repayments"
                            placeholder=""
                            type="number"
                            register={affordabilityForm.register}
                            control={affordabilityForm.control}
                            currencyField
                            disabled={isEditing}
                        />
                    </FormInputWrapper>

                    <FormInputLabel>Monthly child maintenance</FormInputLabel>
                    <FormInputWrapper>
                        <InputFormController
                            name="childMaintenance"
                            label="Child Maintenance"
                            placeholder=""
                            type="number"
                            register={affordabilityForm.register}
                            control={affordabilityForm.control}
                            currencyField
                            disabled={isEditing}
                        />
                    </FormInputWrapper>

                    <FormInputLabel>Calculated disposable income</FormInputLabel>
                    <FormInputWrapper>
                        <InputFormController
                            name="disposableMonthlyIncome"
                            label="Disposable Monthly Income"
                            type="number"
                            register={affordabilityForm.register}
                            control={affordabilityForm.control}
                            currencyField
                            disabled
                        />
                    </FormInputWrapper>
                </Grid>
                <LegalInformation />
                {submitLoading &&
                    <Grid item xs={FULL_COLUMN_SIZE}>
                        <Stack
                            alignItems={{ xs: 'center', sm: 'flex-end' }}
                            marginBottom={"5rem"}
                            marginTop={3}
                        >
                            <Box textAlign={"center"} width={{ xs: "100%", sm: "25rem" }}>
                                <Loading />
                            </Box>
                        </Stack>
                    </Grid>
                }
                {!submitLoading && (
                    <Box width={"100%"}>
                        <Stack
                            alignItems={{ md: "flex-end", xs: "center" }}
                            gap={1}
                            marginBottom={"5rem"}
                            marginTop={3}
                        >
                            <PrimaryButton disabled={isEditing} type="submit">Next</PrimaryButton>

                        </Stack>
                    </Box>
                )}
            </Form>
        </>
    );
};

export default ChangeAffordabilityLN;
