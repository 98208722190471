import { OpenLoanStatus } from "../../clients/LoanClient";

export enum OpenLoanStatusEnum {
    Unspecified = 0,
    PendingApplicationSigning = 1,
    PendingIvDocUpload = 2,
    PendingIncomeAndBankVerification = 3,
    PendingMandateAcceptance = 4,
    Disbursing = 5,
    Active = 6,
    InArrears = 7,
    CounterofferAvailable = 8,
}

export function getFriendlyOpenLoanStatus(value: OpenLoanStatus | undefined): OpenLoanStatusEnum {
    if (!value)
        value = OpenLoanStatus._0;

    switch (value) {
        case OpenLoanStatus._0:
            return OpenLoanStatusEnum.Unspecified;
        case OpenLoanStatus._1:
            return OpenLoanStatusEnum.PendingApplicationSigning;
        case OpenLoanStatus._2:
            return OpenLoanStatusEnum.PendingIvDocUpload;
        case OpenLoanStatus._3:
            return OpenLoanStatusEnum.PendingIncomeAndBankVerification;
        case OpenLoanStatus._4:
            return OpenLoanStatusEnum.PendingMandateAcceptance;
        case OpenLoanStatus._5:
            return OpenLoanStatusEnum.Disbursing;
        case OpenLoanStatus._6:
            return OpenLoanStatusEnum.Active;
        case OpenLoanStatus._7:
            return OpenLoanStatusEnum.InArrears;
        case OpenLoanStatus._8:
            return OpenLoanStatusEnum.CounterofferAvailable;
        default:
            throw new Error("Unexpected OpenLoanStatus value");
    }
}