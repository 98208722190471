/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stack, Avatar, Typography, useMediaQuery } from "@mui/material";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import { theme } from "../../../theme/Theme";
import { Link, useNavigate } from "react-router-dom";
import InformationOrangeIcon from "../../../assets/img/InformationOrangeIcon.png";
import SavingsIcon from "../../../assets/img/PiggyBank.png";
import { IGetLoansSummaryResponse, OpenLoanStatus } from "../../../clients/LoanClient";
import React from "react";
import { getFriendlyOpenLoanStatus, OpenLoanStatusEnum } from "../../../utils/Helpers/OpenLoanStatusEnum";

type OpenLoanSummaryProps = {
    loansSummaryResponse: IGetLoansSummaryResponse;
}

const OpenLoanSummary: React.FunctionComponent<OpenLoanSummaryProps> = (props: OpenLoanSummaryProps) => {
    const content = useLoanSummaryContent(props.loansSummaryResponse);

    if (!content)
        return <></>;

    return (
        <Stack
            flexDirection={"column"}
            alignItems={'center'}
            justifyContent={'center'}
            padding={{ xs: '1.2rem 1.6rem' }}
            sx={{ backgroundColor: theme.palette.grey[200], borderRadius: '1rem' }}
            gap={'1.2rem'}
        >
            {content}
        </Stack>
    );
}

export default OpenLoanSummary;

const Title = (props: { text: 'Open application' | 'Overdue' }) => {
    return <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={'1rem'}
    >
        <Avatar src={InformationOrangeIcon} alt="icon" sx={{ width: { xs: '1.6rem', md: '2.2rem' }, height: { xs: '1.6rem', md: '2.2rem' } }} />
        <Typography
            variant="h2"
            fontSize={'1.8rem'}
            fontWeight={600}
            lineHeight={'2rem'}
            color={theme.palette.secondary.main}
        >
            {props.text}
        </Typography>
    </Stack>;
}

const useLoanSummaryContent = (response: IGetLoansSummaryResponse) => {
    const { hasOpenLoanOrApplication, openApplication, loans } = response;
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (hasOpenLoanOrApplication) {
        const hasLoanInArrears = loans?.find(l => getFriendlyOpenLoanStatus(l.openLoanStatus) == OpenLoanStatusEnum.InArrears);

        if (openApplication !== null && openApplication !== undefined) {
            const openLoanStatus = getFriendlyOpenLoanStatus(openApplication.openLoanStatus);

            if (openLoanStatus === OpenLoanStatusEnum.Unspecified) {
                return (
                    <>
                        <Title text={"Open application"} />
                        <Typography
                            fontSize={'1.5rem'}
                            fontWeight={300}
                            lineHeight={'2rem'}
                        >
                            You have an open loan application. Please click here to continue.
                        </Typography>
                        <PrimaryButton onClick={() => navigate(`${openApplication.loanId}`)}>View application outcome</PrimaryButton>
                    </>
                );
            }

            if (openLoanStatus === OpenLoanStatusEnum.PendingApplicationSigning) {
                return (
                    <>
                        <Title text={"Open application"} />
                        <Typography
                            fontSize={'1.5rem'}
                            fontWeight={300}
                            lineHeight={'2rem'}
                        >
                            You have an open loan application. Please click here to continue before it expires.
                        </Typography>
                        <PrimaryButton onClick={() => navigate(`${openApplication.loanId}`)}>Sign loan agreement</PrimaryButton>
                    </>
                );
            }

            if (openLoanStatus === OpenLoanStatusEnum.PendingIvDocUpload) {
                return (
                    <>
                        <Title text={"Open application"} />
                        <Typography
                            fontSize={'1.5rem'}
                            fontWeight={300}
                            lineHeight={'2rem'}
                        >
                            You have an open loan application. Please click here to continue before it expires.
                        </Typography>
                        <PrimaryButton onClick={() => navigate(`${openApplication.loanId}`)}>Submit proof of income</PrimaryButton>
                    </>
                );
            }

            if (openLoanStatus === OpenLoanStatusEnum.PendingIncomeAndBankVerification) {
                return (
                    <>
                        <Title text={"Open application"} />
                        <Typography
                            fontSize={'1.5rem'}
                            fontWeight={300}
                            lineHeight={'2rem'}
                        >
                            We are currently reviewing your income & bank account details.{' '}
                            {!isMobile && (
                                <Link to={`${openApplication.loanId}`} style={{ textDecoration: 'none' }}>
                                    <Typography
                                        component={'span'}
                                        fontSize={'1.5rem'}
                                        fontWeight={300}
                                        lineHeight={'2rem'}
                                        color={theme.palette.primary.main}

                                    >View loan</Typography>
                                </Link>
                            )}
                        </Typography>
                        {isMobile && (
                            <Link to={`${openApplication.loanId}`} style={{ textDecoration: 'none', width: '100%', paddingLeft: '3.6rem' }}>
                                <Typography
                                    fontSize={'1.5rem'}
                                    fontWeight={300}
                                    lineHeight={'2rem'}
                                    color={theme.palette.primary.main}
                                >View loan</Typography>
                            </Link>
                        )}
                    </>
                );
            }

            if (openLoanStatus === OpenLoanStatusEnum.PendingMandateAcceptance) {
                return (
                    <>
                        <Title text={"Open application"} />
                        <Typography
                            fontSize={'1.5rem'}
                            fontWeight={300}
                            lineHeight={'2rem'}
                        >
                            You have an open loan application. Please accept your DebiCheck mandate before your application expires.{' '}
                            {!isMobile && (
                                <Link to={`${openApplication.loanId}`} style={{ textDecoration: 'none' }}>
                                    <Typography
                                        component={'span'}
                                        fontSize={'1.5rem'}
                                        fontWeight={300}
                                        lineHeight={'2rem'}
                                        marginRight={'1rem'}
                                        color={theme.palette.primary.main}

                                    >View loan</Typography>
                                </Link>
                            )}
                        </Typography>
                        {isMobile && (
                            <Link to={`${openApplication.loanId}`} style={{ textDecoration: 'none', width: '100%', paddingLeft: '3.6rem' }}>
                                <Typography
                                    fontSize={'1.5rem'}
                                    fontWeight={300}
                                    lineHeight={'2rem'}
                                    color={theme.palette.primary.main}
                                >View loan</Typography>
                            </Link>
                        )}
                    </>
                );
            }

            if (openLoanStatus === OpenLoanStatusEnum.Disbursing) {
                return (
                    <>
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            gap={'1rem'}
                        >
                            <Avatar src={SavingsIcon} alt="icon" sx={{ width: '2.6rem', height: '2.5rem', borderRadius: 'none' }} />
                            <Typography
                                fontSize={'1.5rem'}
                                fontWeight={300}
                                lineHeight={'2rem'}
                            >
                                Your recent loan application has been <strong>approved</strong> & the cash is on its way!{' '}
                                {!isMobile && (
                                    <Link to={`${openApplication.loanId}`} style={{ textDecoration: 'none' }}>
                                        <Typography
                                            width={'100%'}
                                            component={'span'}
                                            fontSize={'1.5rem'}
                                            fontWeight={300}
                                            lineHeight={'2rem'}
                                            marginRight={'1rem'}
                                            color={theme.palette.primary.main}

                                        >View loan</Typography>
                                    </Link>
                                )}
                            </Typography>
                        </Stack>
                        {isMobile && (
                            <Link to={`${openApplication.loanId}`} style={{ textDecoration: 'none', width: '100%', paddingLeft: '3.6rem' }}>
                                <Typography
                                    fontSize={'1.5rem'}
                                    fontWeight={300}
                                    lineHeight={'2rem'}
                                    color={theme.palette.primary.main}
                                >View loan</Typography>
                            </Link>
                        )}
                    </>
                );
            }

            if (openLoanStatus === OpenLoanStatusEnum.CounterofferAvailable) {
                return (
                    <>
                        <Title text={"Open application"} />
                        <Typography
                            fontSize={'1.5rem'}
                            fontWeight={300}
                            lineHeight={'2rem'}
                        >
                            We have reviewed your loan application & have an adjusted offer. Please click here to continue before it expires.
                        </Typography>
                        <PrimaryButton onClick={() => navigate(`${openApplication.loanId}`)}>View loan offer</PrimaryButton>
                    </>
                );
            }

            if (hasLoanInArrears) {
                return (
                    <>
                        <Title text={"Overdue"} />
                        <Typography
                            fontSize={'1.5rem'}
                            fontWeight={300}
                            lineHeight={'2rem'}
                        >
                            One or more of your loans is currently{' '}
                            <Typography
                                fontSize={'1.5rem'}
                                fontWeight={400}
                                lineHeight={'2rem'}
                                component={'span'}
                                color={theme.palette.secondary.main}
                            >overdue.</Typography>{' '}
                            Please settle this amount to avoid interest accruing on your outstanding amount. If paid already, please ignore.
                        </Typography>
                    </>
                );
            }
        }
    }
}