import Grid from '@mui/material/Grid';
import './../../../../../styles/_forms.scss';
import './../../../../../styles/_buttons.scss';
import React, { useRef, useState } from 'react';
import {
  Captcha,
  ConfirmMobileVerificationRequest,
  ConfirmMobileVerificationResponse,
  ErrorResponse,
  IConfirmMobileVerificationRequest,
  IContactDetails,
  IMobileVerificationRequest,
  MobileVerificationRequest,
  MobileVerificationResponse,
} from '../../../../../clients/AccountClient';
import Loading from '../../../../../components/Loading/Loading';
import { Form, useForm } from '../../../../../components/Form/Form';
import { changeMobileNumberSchema, OTPSchema } from '../../../../../schemas/Schemas';
import {
  FULL_COLUMN_SIZE,
  INPUT_COLUMN_SIZE,
  LABEL_COLUMN_SIZE,
  TWO_THIRDS_COLUMN_SIZE,
} from '../../../../../utils/GridColumnSizeDefinitions';
import useAccountClient from '../../../../../hooks/account/Client';
import InputFormController from '../../../../../components/MuiInput/FormControllers/InputFormController';
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { useSnackBar } from '../../../../../contexts/SnackBarContext';
import { CFTurnstile } from '../../../../../components/CloudflareTurnstile/CFTurnstile';
import { useAppSettings } from '../../../../../contexts/AppSettingsContext';
import OtpTimer from '../../../../../components/OtpTimer/OtpTimer';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import { theme } from '../../../../../theme/Theme';
import TextButton from '../../../../../components/Buttons/TextButton';
import { TurnstileInstance } from '@marsidev/react-turnstile';
import TurnstileSize from '../../../../../utils/Turnstile';
import { useTracking } from '../../../../../Tracking/TrackingContext';

interface IChangeContactLNProps {
  closeEditing: (isUpdated: boolean) => Promise<void>;
  contact?: IContactDetails;
}

const ChangeContactLN: React.FunctionComponent<IChangeContactLNProps> = ({
  closeEditing,
  contact,
}: IChangeContactLNProps) => {
  const [otpRequested, setOtpRequested] = useState<boolean>(false);
  const [countDown, setCountDown] = useState<{
    minute: number;
    seconds: number;
  }>({
    minute: 1,
    seconds: 0,
  });
  const client = useAccountClient();
  const [isSubmitLoading, setSubmitLoading] = useState<boolean>(false);
  const { displaySnackBar } = useSnackBar();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const cellphoneForm = useForm({
    criteriaMode: 'all',
    mode: 'onBlur',
    schema: changeMobileNumberSchema(contact?.mobileNumber ?? ''),
  });
  const OTPForm = useForm({
    criteriaMode: 'all',
    mode: 'onChange',
    schema: OTPSchema,
  });

  const turnstileWidgetRef = useRef<TurnstileInstance>(null);
  const appSettings = useAppSettings();
  const { TrackError } = useTracking();
  const cfTurnstileDisabled = appSettings.cfTurnstileConfig.cfTurnstileDisabled;
  const [turnstileSize, setTurnstileSize] = useState<TurnstileSize>('invisible');
  const [cfTurnstileToken, setToken] = useState<string | null>(null);

  const requestOtp = async () => {
    setSubmitLoading(true);

    const mobileNumber = cellphoneForm.getValues().cellphoneNumber;
    try {
      const request: IMobileVerificationRequest = { mobileNumber };
      const captcha = new Captcha();
      captcha.token = cfTurnstileToken || undefined;
      captcha.providerName = appSettings.captchaProvider;
      request.captcha = captcha;

      const response: MobileVerificationResponse = await client.requestMobileVerification(
        new MobileVerificationRequest(request)
      );

      if (!cfTurnstileDisabled && !response.captchaSuccess) {
        if (turnstileWidgetRef.current) turnstileWidgetRef.current.reset();
        TrackError(`MobileVerificationRequest: Failed due to unsuccessful CAPTCHA response`);
        return;
      }

      if (response.success) {
        setOtpRequested(true);
        displaySnackBar("OTP on it's way", 'success');
        OTPForm.setValue('cellphoneNumber', mobileNumber);
      }
    } catch (error) {
      if (error instanceof ErrorResponse && error.errorCode === 400) {
        TrackError(error, error.message);
        displaySnackBar(error.message ?? "An validation error has occurred", 'error');
      } else {
        displaySnackBar('We’re currently experiencing a temporary technical issue. Please try again later.', 'error');
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  const confirmOtp = async () => {
    const pin = OTPForm.getValues().otp;
    setSubmitLoading(true);

    try {
      const request: IConfirmMobileVerificationRequest = { pin };
      const captcha = new Captcha();
      captcha.token = cfTurnstileToken || undefined;
      captcha.providerName = appSettings.captchaProvider;
      request.captcha = captcha;

      const response: ConfirmMobileVerificationResponse = await client.confirmMobileVerification(
        new ConfirmMobileVerificationRequest(request)
      );

      if (!cfTurnstileDisabled && !response.captchaSuccess) {
        if (turnstileWidgetRef.current) turnstileWidgetRef.current.reset();
        TrackError(`ConfirmMobileVerificationRequest: Failed due to unsuccessful CAPTCHA response`);
        return;
      }

      if (response.success) {
        await closeEditing(true);
      } else {
        displaySnackBar(response.message ?? 'OTP verification error', 'error');
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
      displaySnackBar('We’re currently experiencing a temporary technical issue. Please try again later.', 'error');
    }
  };

  const resendMobileVerification = async () => {
    setCountDown((prev) => {
      return {
        ...prev,
        seconds: 59,
      };
    });

    if (cfTurnstileToken === null && !cfTurnstileDisabled) {
      if (turnstileWidgetRef.current) turnstileWidgetRef.current.reset();
      return;
    }

    setSubmitLoading(true);

    const mobileNumber = cellphoneForm.getValues().cellphoneNumber;

    try {
      const request: IMobileVerificationRequest = { mobileNumber };
      const captcha = new Captcha();
      captcha.token = cfTurnstileToken || undefined;
      captcha.providerName = appSettings.captchaProvider;
      request.captcha = captcha;

      const response: MobileVerificationResponse = await client.resendMobileVerification(
        new MobileVerificationRequest(request)
      );

      if (!cfTurnstileDisabled && !response.captchaSuccess) {
        if (turnstileWidgetRef.current) turnstileWidgetRef.current.reset();
        TrackError(`MobileVerificationRequest: Failed due to unsuccessful CAPTCHA response`);
        return;
      }

      if (response.success) {
        setOtpRequested(true);
        displaySnackBar('OTP resent', 'success');
      }
    } catch (error) {
      displaySnackBar('Oops, an error has occurred please try again', 'error');
    } finally {
      setSubmitLoading(false);
    }
  };

  const showLoading = (): JSX.Element => {
    return (
      <Grid item xs={FULL_COLUMN_SIZE}>
        <Stack alignItems={{ xs: 'center', sm: 'flex-end' }} marginTop={'1rem'}>
          <Box textAlign={'center'} width={{ xs: '100%', sm: '25rem' }}>
            <Loading />
          </Box>
        </Stack>
      </Grid>
    );
  };

  return (
    <>
      {!otpRequested && (
        <Form form={cellphoneForm} onSubmit={requestOtp}>
          <Grid container marginBottom={'2.2rem'}>
            <Grid item xs={FULL_COLUMN_SIZE}>
              <Typography
                variant="h3"
                variantMapping={{ h3: 'h2' }}
                fontSize={{ xs: '2.4rem' }}
                marginBottom={'2.4rem'}
              >
                Change your cell phone number
              </Typography>
            </Grid>
            <Grid item xs={FULL_COLUMN_SIZE} sm={LABEL_COLUMN_SIZE}>
              <label>Cell phone number</label>
            </Grid>
            <Grid item xs={FULL_COLUMN_SIZE} sm={INPUT_COLUMN_SIZE}>
              <InputFormController
                name="cellphoneNumber"
                label=""
                control={cellphoneForm.control}
                placeholder="Cell phone number"
                register={cellphoneForm.register}
              />
            </Grid>

            <Grid item sm={LABEL_COLUMN_SIZE} sx={{ display: { xs: 'none', sm: 'initial' } }}></Grid>
            <Grid item xs={FULL_COLUMN_SIZE} sm={INPUT_COLUMN_SIZE}>
              <Typography variant="body1" fontWeight={'300'}>
                We will send you a one time pin to verify your cell phone number.
              </Typography>
            </Grid>

            <Grid
              container
              justifyContent={{ xs: 'center', md: 'flex-end' }}
              paddingBottom={'1rem'}
              paddingTop={'1rem'}
            >
              <Stack>
                {!cfTurnstileDisabled && (
                  <CFTurnstile
                    ref={turnstileWidgetRef}
                    siteKey={appSettings.cfTurnstileConfig.cfTurnstileOtpWidgetSiteKey}
                    component="ChangeContact"
                    action="Otp"
                    turnstileSize={turnstileSize}
                    setTurnstileSize={setTurnstileSize}
                    setToken={setToken}
                  />
                )}
              </Stack>
            </Grid>

            {isSubmitLoading ? (
              showLoading()
            ) : (
              <>
                <Grid item sm={LABEL_COLUMN_SIZE} sx={{ display: { xs: 'none', sm: 'initial' } }}></Grid>
                <Grid item xs={FULL_COLUMN_SIZE} sm={TWO_THIRDS_COLUMN_SIZE}>
                  <Stack
                    flexGrow={1}
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                    justifyContent={{ xs: 'center', sm: 'space-between' }}
                    marginTop={'1rem'}
                  >
                    <TextButton removePadding onClick={() => closeEditing(false)}>
                      Cancel
                    </TextButton>
                    <PrimaryButton type="submit" disabled={!cfTurnstileDisabled && cfTurnstileToken === null}>
                      Send OTP
                    </PrimaryButton>
                  </Stack>
                </Grid>
              </>
            )}
          </Grid>
        </Form>
      )}
      {otpRequested && (
        <Form form={OTPForm} onSubmit={confirmOtp}>
          <Grid container marginBottom={'2.2rem'}>
            <Grid item xs={FULL_COLUMN_SIZE}>
              <Typography
                variant="h3"
                variantMapping={{ h3: 'h2' }}
                fontSize={{ xs: '2rem', sm: '3.2rem' }}
                lineHeight="6.4rem"
              >
                Change your cell phone number
              </Typography>
            </Grid>
            <Grid item xs={FULL_COLUMN_SIZE} sm={LABEL_COLUMN_SIZE}>
              <label>Cell phone number</label>
            </Grid>
            <Grid item xs={FULL_COLUMN_SIZE} sm={INPUT_COLUMN_SIZE}>
              <InputFormController
                disabled={otpRequested}
                name="cellphoneNumber"
                label=""
                control={OTPForm.control}
                placeholder="Cell phone number"
                register={OTPForm.register}
              />
            </Grid>
            <Grid item xs={FULL_COLUMN_SIZE} sm={LABEL_COLUMN_SIZE}>
              <label>Please enter the 4 digit OTP</label>
            </Grid>
            <Grid item xs={FULL_COLUMN_SIZE} sm={INPUT_COLUMN_SIZE}>
              <InputFormController
                name="otp"
                type="number"
                label=""
                control={OTPForm.control}
                placeholder="OTP"
                register={OTPForm.register}
              />
            </Grid>
            {!isMobile && <Grid item xs={FULL_COLUMN_SIZE} sm={LABEL_COLUMN_SIZE}></Grid>}
            <Grid container justifyContent={{ xs: 'center', md: 'flex-end' }} paddingBottom={'1rem'}>
              <Stack>
                {!cfTurnstileDisabled && (
                  <CFTurnstile
                    ref={turnstileWidgetRef}
                    siteKey={appSettings.cfTurnstileConfig.cfTurnstileOtpWidgetSiteKey}
                    component="ChangeContact"
                    action="Otp"
                    turnstileSize={turnstileSize}
                    setTurnstileSize={setTurnstileSize}
                    setToken={setToken}
                  />
                )}
              </Stack>
            </Grid>

            {isSubmitLoading ? (
              showLoading()
            ) : (
              <>
                <Grid item xs={FULL_COLUMN_SIZE} md={LABEL_COLUMN_SIZE}></Grid>

                <Grid item xs={FULL_COLUMN_SIZE} md={LABEL_COLUMN_SIZE}>
                  <Stack alignItems={'start'} justifyContent={'center'} height={'100%'}>
                    {countDown.minute > 0 || countDown.seconds > 0 ? (
                      <OtpTimer countDown={countDown} setCountDown={setCountDown} />
                    ) : (
                      <TextButton removePadding onClick={resendMobileVerification}>
                        Resend OTP
                      </TextButton>
                    )}
                  </Stack>
                </Grid>

                <Grid item xs={FULL_COLUMN_SIZE} md={LABEL_COLUMN_SIZE}>
                  <Stack alignItems={'flex-end'}>
                    <PrimaryButton
                      type="submit"
                      disabled={!OTPForm.formState.isValid || (!cfTurnstileDisabled && cfTurnstileToken === null)}
                    >
                      Confirm OTP
                    </PrimaryButton>
                  </Stack>
                </Grid>
              </>
            )}
          </Grid>
        </Form>
      )}
    </>
  );
};

export default ChangeContactLN;
