import { Dialog, Stack, Box, Typography, Avatar } from '@mui/material';
import PrimaryButton from '../Buttons/PrimaryButton';
import InfoIcon from '../../assets/img/webp/information-2x.webp';

interface RepayNoticeModalProps {
  showModal: boolean;
  onClose: () => void;
}

const RepayNoticeModal: React.FunctionComponent<RepayNoticeModalProps> = (props: RepayNoticeModalProps) => {
  const { showModal, onClose } = props;

  return (
    <Dialog
      open={showModal}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '50rem',
            margin: '1.6rem',
            borderRadius: '0.8rem',
          },
        },
      }}
    >
      <Stack spacing={'1.6rem'} padding={'3.4rem 2rem'}>
        <Box>
          <Avatar
            src={InfoIcon}
            alt="Information image icon"
            sx={{ margin: 'auto', width: { xs: '6rem', md: '7.8rem' }, height: { xs: '6rem', md: '7.8rem' } }}
          />
        </Box>
        <Typography textAlign={{ xs: 'left', md: 'center' }} fontWeight={500} fontSize={'2.4rem'} lineHeight={'3rem'}>
          Heads up...
        </Typography>
        <Typography fontSize={'1.5rem'} fontWeight={300} lineHeight={'2rem'}>
          Making a repayment{' '}
          <Typography component={'span'} fontSize={'1.5rem'} fontWeight={600} lineHeight={'2rem'} display={'inline'}>
            will not cancel
          </Typography>{' '}
          your next scheduled debit order.
        </Typography>

        <Typography fontSize={'1.5rem'} fontWeight={300} lineHeight={'2rem'} marginTop={{ md: '0.5rem !important' }}>
          Payments can take{' '}
          <Typography component={'span'} fontSize={'1.5rem'} fontWeight={600} lineHeight={'2rem'} display={'inline'}>
            2-3 business days
          </Typography>{' '}
          to reflect on your loan account.
        </Typography>
        <br />
        <PrimaryButton fullWidth onClick={onClose}>
          Okay
        </PrimaryButton>
      </Stack>
    </Dialog>
  );
};

export default RepayNoticeModal;
