import React, { useEffect } from "react";
import { Typography } from "@mui/material";

interface IOtpTimerProps {
    countDown: { minute: number; seconds: number };
    setCountDown: React.Dispatch<React.SetStateAction<{ minute: number; seconds: number }>>;
}

const OtpTimer: React.FC<IOtpTimerProps> = ({ countDown, setCountDown }) => {

    useEffect(() => {
        const interval = setInterval(() => {
            if (countDown.seconds > 0) {
                setCountDown((prev) => {
                    return {
                        ...prev,
                        seconds: prev.seconds - 1,
                    };
                });
            }

            if (countDown.seconds === 0) {
                if (countDown.minute === 0) {
                    clearInterval(interval)
                } else {
                    setCountDown((prev) => {
                        return {
                            ...prev,
                            seconds: 59,
                            minute: prev.minute - 1
                        };
                    });
                }
            }
        }, 1000)

        return () => {
            clearInterval(interval)
        };
        // eslint-disable-next-line
    }, [countDown.seconds])

    return (
        <>
            <Typography variant="body1" color='#333333' sx={{ paddingTop: "1rem" }}>
                Resend OTP {countDown.minute < 10 ? `0${countDown.minute}` : countDown.minute}:
                {countDown.seconds < 10 ? `0${countDown.seconds}` : countDown.seconds}
            </Typography>
        </>

    )

}

export default OtpTimer;