import React from 'react';
import { Skeleton, Typography } from '@mui/material';
import styles from './BlogTile.module.scss';

interface IBlogTile {
  title: string;
  description: string;
  imageUrl: string;
  linkUrl: string;
  linkTitle: string;
}

const BlogTile: React.FC<IBlogTile> = ({ title, description, imageUrl, linkUrl, linkTitle }: IBlogTile) => {
  const getUmbracoPageLink = (url: string) => {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.pathname + parsedUrl.search + parsedUrl.hash;
    } catch (e) {
      console.error('Invalid URL', e);
      return '';
    }
  };

  return (
    <>
      <div className={styles['blog-container']}>
        <div className={styles['blog-tile']}>
          <div className={styles['blog-tile-img']}>
            <a href={getUmbracoPageLink(linkUrl)}>
              <img src={imageUrl} alt="blog image" loading="lazy" width={'300px'} height={'200px'} />
            </a>
          </div>
          <div className={styles['blog-tile-content']}>
            <div className={styles['blog-tile-content-heading']}>
              <Typography
                variant="body1"
                fontWeight={700}
                fontSize={'1.4rem'}
                letterSpacing={'-0.7px'}
                lineHeight={'1.7rem'}
              >
                {title}
              </Typography>
            </div>
            <div className={styles['blog-tile-content-text']}>
              <Typography
                variant="body2"
                fontWeight={300}
                fontSize={'1.4rem'}
                letterSpacing={'-0.4px'}
                lineHeight={'1.7rem'}
              >
                {description}
              </Typography>
            </div>
          </div>
          <a className={styles['blog-tile-content-link']} href={getUmbracoPageLink(linkUrl)}>
            {linkTitle}
          </a>
        </div>
      </div>
    </>
  );
};

export const BlogTileSkeleton = () => {
  return (
    <div className={styles['blog-container']}>
      <div className={styles['blog-tile']}>
        <div className={styles['blog-tile-img']}>
          <Skeleton variant="rectangular" width={300} height={200} />
        </div>
        <div className={styles['blog-tile-content']}>
          <div className={styles['blog-tile-content-heading']}>
            <Skeleton variant="text" width={260} height={26} />
          </div>
          <div className={styles['blog-tile-content-text']}>
            <Skeleton variant="text" width={260} height={125} />
          </div>
        </div>
        <Skeleton variant="rounded" width={300} height={100} />
      </div>
    </div>
  );
};

export default BlogTile;
