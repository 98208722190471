import React from "react";
import ReactDOM from 'react-dom/client';
import "./index.scss";
import App from "./App";

import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme/Theme";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

const root = document.getElementById("root") as HTMLElement;
const reactRoot = ReactDOM.createRoot(root);

reactRoot.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </ThemeProvider>
  </BrowserRouter>
);