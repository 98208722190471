import { PollingMode } from "configcat-react";

export function parseJson<T>(jsonString: string, fallback: T): T {
    try {
        return JSON.parse(jsonString) as T;
    } catch (error) {
        console.error('Failed to parse JSON:', error);
        return fallback;
    }
}

export const getPollingMode = (pollingMode: string): PollingMode => {
    switch (pollingMode) {
        case 'Lazy':
            return PollingMode.LazyLoad;
        case 'Auto':
            return PollingMode.AutoPoll;
        case 'Manual':
            return PollingMode.ManualPoll;
        default:
            return PollingMode.AutoPoll;
    }
};
