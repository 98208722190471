import React, { ForwardedRef, forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import Blog from '../../../models/Blog';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import BlogTile, { BlogTileSkeleton } from '../BlogTile/BlogTile';

const BlogSection = forwardRef(function BlogSection(_props, ref: ForwardedRef<HTMLDivElement>) {
  const [blogs, setBlogs] = useState<Blog[]>();
  const { displaySnackBar } = useSnackBar();
  const hasFetchedRef = useRef(false);

  const fetchBlogs = useCallback(async () => {
    try {
      const blogsResponse = await fetch(`/blogjson`);
      if (blogsResponse.ok) {
        const data: Blog[] = await blogsResponse.json();
        setBlogs(data);
      } else {
        throw new Error('Failed to load blogs');
      }
    } catch (error) {
      displaySnackBar('Could not load blogs at this time. Please try again later.', 'error');
    }
  }, [displaySnackBar]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !hasFetchedRef.current) {
          fetchBlogs();
          hasFetchedRef.current = true;
          observer.disconnect();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1, // Trigger when 10% of the component is visible
      }
    );

    if (ref && typeof ref !== 'function' && ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [fetchBlogs, ref]);

  return (
    <>
      {blogs ? (
        <>
          {blogs?.map((item, index) => (
            <BlogTile
              key={index}
              title={item.heading}
              description={item.description}
              linkUrl={item.url}
              imageUrl={item.image}
              linkTitle={item.linkname}
            />
          ))}
        </>
      ) : (
        <>
          <BlogTileSkeleton />
          <BlogTileSkeleton />
          <BlogTileSkeleton />
        </>
      )}
    </>
  );
});

export default BlogSection;
