import React from 'react';
import styles from './PepDefinition.module.scss';
import closeLogo from '../../../../assets/img/svg/Close.svg';

interface PepDefinitionProps {
  showPipOrPepDefinition: boolean;
  setShowPipOrPepDefinition: React.Dispatch<React.SetStateAction<boolean>>;
}

const PepDefinition: React.FunctionComponent<PepDefinitionProps> = (props) => {
  const { showPipOrPepDefinition, setShowPipOrPepDefinition } = props;
  return (
    <React.Fragment>
      <div
        className={styles['Pep-definition-container-mobile']}
        style={{
          display: showPipOrPepDefinition ? 'flex' : 'none',
        }}
      >
        <div className={styles['pep-definition-header']}>
          What is this?
          <img src={closeLogo} alt="X" onClick={() => setShowPipOrPepDefinition(false)} />
        </div>
        <div className={styles['pep-definition-content']}>
          A PEP is any individual who holds or has in the past held a position of public trust, such as government
          officials, important political party officials, etc. It is not always limited to these individuals but also
          includes their immediate family members & close business associates. Prominent Influential Persons (PIPs)
          includes the likes of local influencers, such as religious leaders or chiefs of provinces.
        </div>
      </div>
    </React.Fragment>
  );
};

export default PepDefinition;
