import React, { useContext, useState } from 'react';
import { AppSettingsContext } from './AppSettingsContext';

interface LimiterContextType {
    showLimiter: boolean;
    isConnectionFailure: boolean;
    limiterHeading: string;
    limiterSubtitle: string;
    setIsConnectionFailure: (value: boolean) => void;
}

interface LimiterProvider {
    children: React.ReactNode
}

export const LimiterContext = React.createContext<LimiterContextType>({
    showLimiter: false,
    isConnectionFailure: false,
    limiterHeading: "",
    limiterSubtitle: "",
    /* eslint-disable  @typescript-eslint/no-unused-vars*/
    setIsConnectionFailure: (value: boolean) => { /* do nothing */ },
})

const LimiterProvider = (props: LimiterProvider) => {
    const appSettings = useContext(AppSettingsContext);
    const showLimiter = appSettings.limiter;
    const limiterHeading = appSettings.limiterHeading;
    const limiterSubtitle = appSettings.limiterSubtitle;
    const [isConnectionFailure, setIsConnectionFailure] = useState<boolean>(false);

    return (
        <LimiterContext.Provider value={{ showLimiter, isConnectionFailure, limiterHeading, limiterSubtitle, setIsConnectionFailure }}>
            {props.children}
        </LimiterContext.Provider>
    )
}

export default LimiterProvider;
