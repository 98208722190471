/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import classNames from 'classnames';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { RegisterProps, FieldName } from '../FormControllers/types';
import classes from './CustomInput.module.scss';

interface InputComponentProps {
  placeholder?: string;
  name: FieldName;
  label: string;
  value: string;
  type?: string;
  isError: boolean;
  disabled?: boolean;
  errorText?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  required?: boolean;
  register: RegisterProps;
  onBlur: (...event: any[]) => void;
  onChange: (...event: any[]) => void;
  currencyField?: boolean;
  salaryDay?: number;
  disableError?: boolean;
}

const CustomInput: React.FC<InputComponentProps> = ({
  type = 'text',
  name,
  placeholder,
  isError,
  errorText,
  required,
  register,
  value,
  onBlur,
  onChange,
  disabled,
  currencyField,
  salaryDay,
  disableError,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const inputClass = classNames(classes.inputContainer, {
    [classes.invalid]: isError && !isFocused,
    [classes.focus]: !isError && isFocused,
    [classes.valid]: value && !isError && !isFocused,
    [classes.focus]: isFocused,
  });

  const errorTextContainer = classNames([classes.errorText], { [classes.errorTextContainer]: !disableError });
  const [salaryDayValue, setSalaryDayValue] = useState<string | undefined>(salaryDay?.toString());

  useEffect(() => {
    setSalaryDayValue(salaryDay?.toString());
  }, [salaryDay]);

  const rest = register(name);

  const handleBlur = () => {
    if (onBlur) {
      onBlur();
    }
    setIsFocused(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSalaryDayValue(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <div className={classes.inputContainer}>
      <TextField
        id={name}
        style={{
          border: '1px solid $color-gray-light',
          borderRadius: '8px',
          outline: '0',
          width: '100%',
          background: '$color-white',
          borderLeft: '6px solid $color-gray-light',
          borderLeftStyle: isFocused || isError || value ? 'solid' : 'solid',
          borderLeftColor: isFocused ? '#008fd5' : isError ? '#d0021b' : value ? '#7ac206' : '$color-gray-light',
          fontSize: '15px',
          color: 'inherit',
          padding: '0 0 0 15px',
          transition: 'border-left-color 0.15s linear',
        }}
        onFocus={() => setIsFocused(true)}
        inputProps={{
          className: inputClass,
        }}
        InputProps={{
          startAdornment: currencyField && <InputAdornment position="start">R</InputAdornment>,
        }}
        error={isError}
        disabled={disabled}
        placeholder={placeholder}
        type={type}
        required={required}
        {...rest}
        value={value ? value : salaryDayValue ? salaryDayValue : ''}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <div className={errorTextContainer}>
        {isError && !isFocused && <div className={classes.errorText}>{!disableError && errorText?.toString()}</div>}
      </div>
    </div>
  );
};

export default CustomInput;
