import { Button } from "@mui/material";
import React, { ReactNode } from "react";

interface SecondaryButtonProps {
    children: ReactNode;
    type?: "button" | "submit" | "reset";
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    fullWidth?: boolean;
}

const SecondaryButton = ({
    type,
    onClick,
    disabled,
    children,
    fullWidth,
}: SecondaryButtonProps) => {
    return (
        <Button
            disabled={disabled}
            color="primary"
            variant="outlined"
            type={type}
            onClick={onClick}
            sx={{
                width: fullWidth ? { xs: "100%" } : { md: "25rem", xs: "100%" },
                height: "5rem",
                borderRadius: "0.8rem",
                boxShadow: "none",
                fontSize: '1.5rem',
                fontWeight: 500,
                ":hover": {
                    boxShadow: "none",
                },
            }}
        >
            {children}
        </Button>
    );
};

export default SecondaryButton;
