import { Limits } from '../../clients/LoanClient';
import { DurationWrapper } from '../../components/Sliders/DurationWrapper';
import { AmountRange, Product, SliderConfig, SliderLimit } from '../../components/Sliders/Types';
import { initialAmountRange } from '../../components/Sliders/constants';

export const getAmountRangeAcrossAllProducts = (sliderConfig: SliderConfig) => {
  const newAmountRange = {
    min: sliderConfig.shortTermLoanConfig.amountMin,
    max: sliderConfig.shortTermLoanConfig.amountMax,
  };
  const limitsPerDuration = sliderConfig.installmentLoanConfig.limitsPerDuration;
  if (limitsPerDuration) {
    limitsPerDuration.forEach((limit) => {
      if (newAmountRange.min > limit.amountMin) {
        newAmountRange.min = limit.amountMin;
      }
      if (newAmountRange.max < limit.amountMax) {
        newAmountRange.max = limit.amountMax;
      }
    });
  }
  return newAmountRange;
};

export const getAmountRangeByDuration = (product: Product, loanDuration: DurationWrapper): AmountRange => {
  const newAmountRange = { ...initialAmountRange };
  const limitPerDuration = product.limitsPerDuration.find((limit) => limit.duration == loanDuration.value);
  if (limitPerDuration) {
    newAmountRange.max = limitPerDuration.amountMax;
    newAmountRange.min = limitPerDuration.amountMin;
  } else {
    newAmountRange.max = product.amountMax;
    newAmountRange.min = product.amountMin;
  }
  return newAmountRange;
};

export const findLimitsPerDurationByProductId = (productId: string, limits: Limits[]): SliderLimit[] => {
  const limitsPerDuration: SliderLimit[] = [];
  limits.filter((limit) => {
    if (limit.productId === productId) {
      limit.limitPerDurations?.forEach((item) => {
        limitsPerDuration.push({
          duration: item.duration ?? 0,
          amountMax: item.amountMax ?? 0,
          amountMin: item.amountMin ?? 0,
        } as SliderLimit);
      });
    }
  });
  return limitsPerDuration;
};

export function formatMoney(value: number): string {
  return value.toLocaleString('en', {
    useGrouping: false,
    minimumFractionDigits: 2,
  });
}

/**
 * Formats a number into currency format without rounding
 *
 * @param {number} num The number to format.
 * @param {number} decimalPlaces The number of decimal places to be returned.
 * @returns {string} The formatted number as a string.
 */
export function formatMoneyWithoutRounding(num: number, decimalPlaces: number): string {
  const numStr = num.toString();
  const parts = numStr.split('.');

  // If there's no decimal part and no decimal places needed, just return the integer part
  if (parts.length === 1 && decimalPlaces === 0) {
    return num.toString();
  }

  // Get the integer part and the decimal part (or an empty string if no decimal part)
  const integerPart = parts[0];
  let decimalPart = parts.length > 1 ? parts[1] : '';

  // Truncate or pad the decimal part to the specified number of decimal places
  if (decimalPart.length > decimalPlaces) {
    decimalPart = decimalPart.substring(0, decimalPlaces);
  } else {
    decimalPart = decimalPart.padEnd(decimalPlaces, '0');
  }

  // Combine the integer part and the decimal part
  const formattedNumStr = integerPart + (decimalPlaces > 0 ? '.' + decimalPart : '');

  return formattedNumStr;
}

export function formatMoneyWithThousandSeperation(value: number): string {
  // Convert the number to fixed 2 decimal places
  const [wholePart, decimal] = value.toFixed(2).split('.');

  // Use a regex to add space every third character from the end
  const whole = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return `${whole}.${decimal}`;
}

export function formatRepaymentDay(salaryDay: number) {
  if (salaryDay > 3 && salaryDay < 21) return salaryDay + 'th';
  switch (salaryDay % 10) {
    case 1:
      return salaryDay + 'st';
    case 2:
      return salaryDay + 'nd';
    case 3:
      return salaryDay + 'rd';
    default:
      return salaryDay + 'th';
  }
}

export async function wait(milliseconds: number) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}
