import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";
import React from "react";

interface AdBlockPopupProps {
  open: boolean;
  handleDialogClose: () => void;
}

export const AdBlockPopup = ({open, handleDialogClose} : AdBlockPopupProps) => {

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" variant={"h3"}>
        {"It looks like you’re using an ad-blocker"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">

          <Typography gutterBottom>
            This might impact the quality of your application experience. To ensure a seamless experience, follow the instructions below and then reapply.
          </Typography>
          <Typography gutterBottom>
            1. Consider utilizing an alternative web browser. <br />
            2. Alternatively, disable your ad-blocker for this session. <br />
          </Typography>
          <Typography gutterBottom>
            If the suggestions above fail to resolve the issue, please ensure you have internet connectivity and that there are no other scripts blocking extensions within your browsers.
          </Typography>
          <Typography gutterBottom>
            <strong style={{ color: '#33A5DD' }}>
              Note: When you reapply, you will not have to re-enter the information in the forms you had previously filled.
            </strong>
          </Typography>

        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} autoFocus>Continue to site</Button>
      </DialogActions>
    </Dialog>
  );
}
