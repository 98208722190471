import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Controller } from "react-hook-form";

interface FormInputProps {
  name: string
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  control: any;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  options: any
}

export const RadioButton: React.FunctionComponent<FormInputProps> = ({
  name, control, options,
}) => {
  const RadioOptions = () => {
    return options.map((data: any) => (
      <FormControlLabel
        value={data.value}
        key={data.label}
        label={data.label}
        control={<Radio />}
      />
    ));
  };
  return (
    <FormControl>
      <Controller
        name={name}
        control={control}
        render={({
          field
        }) => (
          <RadioGroup row {...field} value={field.value ?? ""}
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: 28,
              },
            }}
          >
            {RadioOptions()}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};
