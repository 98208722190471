import { Avatar, Box, Container, Stack, Typography, useMediaQuery } from '@mui/material';
import { useFeatureFlag } from 'configcat-react';
import { useEffect, useState } from 'react';
import { useTracking } from '../../../../../../src/Tracking/TrackingContext';
import HandshakeIcon from '../../../../../assets/img/Handshake.png';
import InfoIcon from '../../../../../assets/img/info-icon.png';
import GreatOfferIcon from '../../../../../assets/img/webp/great-offer-orange.webp';
import {
  GetInstallmentLoanQuotationResponse,
  GetShortTermLoanQuotationResponse,
} from '../../../../../clients/LoanClient';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import SecondaryButton from '../../../../../components/Buttons/SecondaryButton';
import DeathBenefitDescriptionPopup from '../../../../../components/CreditLifePlus/DeathBenefitDescriptionPopup';
import QuotationFeeBreakdownPopup from '../../../../../components/CreditLifePlus/QuotationFeeBreakdownPopup';
import Loading from '../../../../../components/Loading/Loading';
import { initialSliderConfig } from '../../../../../components/Sliders/constants';
import { CONFIGCAT_KEYS } from '../../../../../configCat/configCatKeys';
import { defaultAppSettings } from '../../../../../contexts/AppSettingsContext';
import { useSnackBar } from '../../../../../contexts/SnackBarContext';
import useLoanClient from '../../../../../hooks/loan/Client';
import { theme } from '../../../../../theme/Theme';
import { useWizardOutletContext } from '../../../Wizard';
import CreditLifePlusFeeBreakdownPopup from '../CreditLifePlus/CreditLifePlusFeeBreakdownPopup';
import { CreditLifePlusBeneficiaryDetailsKey } from './CreditLifePlusBeneficiary';

export interface StateProps {
  quotation: GetInstallmentLoanQuotationResponse | GetShortTermLoanQuotationResponse | undefined;
  creditLifeSavings?: number;
}

const CreditLifePlus: React.FunctionComponent = () => {
  const { deathBenefitPrimaryCTAText, deathBenefitSecondaryCTAText } = defaultAppSettings.insurance;
  const loanClient = useLoanClient();
  const { next } = useWizardOutletContext();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(true);
  const [quotationWithCreditLife, setQuotationWithCreditLife] = useState<
    GetInstallmentLoanQuotationResponse | GetShortTermLoanQuotationResponse | undefined
  >();
  const [quotationWithDeathBenefit, setQuotationWithDeathBenefit] = useState<
    GetInstallmentLoanQuotationResponse | GetShortTermLoanQuotationResponse | undefined
  >();
  const { displaySnackBar } = useSnackBar();
  const [showDeathBenefitPopup, setShowDeathBenefitPopup] = useState<boolean>(false);
  const [showCreditLifePlusFeeBreakdownPopup, setShowCreditLifePlusFeeBreakdownPopup] = useState<boolean>(false);
  const [showQuotationBreakdownPopup, setShowQuotationBreakdownPopup] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { goToProcessing } = useWizardOutletContext();
  const { configCatUserDetails, TrackFeatureFlagEvent } = useTracking();
  const { value: deathBenefitVariant, loading: deathBenefitVariantLoading } = useFeatureFlag(CONFIGCAT_KEYS.DEATH_BENEFIT_LANDING_PAGE_VARIANT.key, CONFIGCAT_KEYS.DEATH_BENEFIT_LANDING_PAGE_VARIANT.defaultValue, configCatUserDetails);

  const getQuotation = async (
    deathBenefitOptedIn: boolean
  ): Promise<GetShortTermLoanQuotationResponse | GetInstallmentLoanQuotationResponse | undefined> => {
    const applyValues = getApplyValues();
    const isInstallmentLoan = initialSliderConfig.installmentLoanConfig.productId === applyValues?.productId;

    try {
      if (isInstallmentLoan) {
        const ILQuotationResponse = await loanClient.getInstallmentLoanQuotation(
          applyValues.productId,
          applyValues.amount,
          applyValues.term,
          applyValues.salaryDay,
          new Date(applyValues.applicationDate),
          !deathBenefitOptedIn,
          false,
          deathBenefitOptedIn
        );

        return ILQuotationResponse;
      } else {
        const STLQuotationResponse = await loanClient.getShortTermLoanQuotation(
          applyValues.productId,
          applyValues.amount,
          applyValues.term,
          applyValues.salaryDay,
          new Date(applyValues.applicationDate),
          !deathBenefitOptedIn,
          false,
          deathBenefitOptedIn
        );

        return STLQuotationResponse;
      }
    } catch (error) {
      throw `${isInstallmentLoan
        ? 'A server error occured while calling getInstallmentLoanQuotation'
        : 'A server error occured while calling getShortTermLoanQuotation'
      }`;
    }
  };

  const getApplyValues = () => {
    const applyValuesString = localStorage.getItem('applyValues');
    if (!applyValuesString) throw 'Apply values missing from local storage';
    return JSON.parse(applyValuesString);
  };

  const onLoad = async () => {
    try {
      setIsFetchingData(true);
      const quotationWithDeathBenefitPromise = getQuotation(true);
      const quotationWithCreditLifePromise = getQuotation(false);
      const [quotationWithDeathBenefitResponse, quotationWithCreditLifeResponse] = await Promise.all([
        quotationWithDeathBenefitPromise,
        quotationWithCreditLifePromise,
      ]);
      setQuotationWithDeathBenefit(quotationWithDeathBenefitResponse);
      setQuotationWithCreditLife(quotationWithCreditLifeResponse);
      setIsFetchingData(false);
    } catch (error) {
      displaySnackBar('Oops! An error occurred. Please try again.', 'error');
    }
  };

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!deathBenefitVariantLoading)
      TrackFeatureFlagEvent({ featureFlag: CONFIGCAT_KEYS.DEATH_BENEFIT_LANDING_PAGE_VARIANT.key, value: deathBenefitVariant });

  }, [deathBenefitVariantLoading, deathBenefitVariant, TrackFeatureFlagEvent]);

  useEffect(() => {
    if (showQuotationBreakdownPopup && showCreditLifePlusFeeBreakdownPopup) {
      setShowCreditLifePlusFeeBreakdownPopup(false);
    }
  }, [showQuotationBreakdownPopup, showCreditLifePlusFeeBreakdownPopup]);

  const handleContinue = (continueWithDeathBenefit: boolean) => {
    const applyValuesString = localStorage.getItem('applyValues');
    const applyValues = JSON.parse(applyValuesString ?? '');
    localStorage.setItem(
      'applyValues',
      JSON.stringify({ ...applyValues, includeInsurance: !continueWithDeathBenefit })
    );

    // handle navigation
    if (!continueWithDeathBenefit) {
      localStorage.removeItem(CreditLifePlusBeneficiaryDetailsKey);
      next({ quotation: quotationWithCreditLife });
    } else {
      if (deathBenefitVariant == CONFIGCAT_KEYS.VARIANT.A) {
        goToProcessing();
      } else {
        next({ quotation: quotationWithDeathBenefit, creditLifeSavings: quotationWithCreditLife?.insuranceFee });
      }
    }
  };

  if (isFetchingData) return <Loading text="Please wait while we retrieve your details." />;

  return (
    <Container disableGutters sx={{ marginTop: { xs: '0', sm: '6rem' }, padding: '0 1.6rem' }}>
      <Stack direction={{ xs: 'column', sm: 'row' }}>
        <Stack justifyContent={{ xs: 'center', sm: 'flex-start' }} alignItems={{ xs: 'center', sm: 'flex-start' }}>
          <img
            src={HandshakeIcon}
            alt="Shaking hands with trust icon"
            style={
              isMobile
                ? {
                  justifyContent: 'center',
                  width: '8.5rem',
                  height: '7.1rem',
                  marginBottom: '24px'
                }
                : {
                  justifyContent: 'center',
                  width: '10.6rem',
                  height: '8.8rem',
                  marginLeft: '3rem',
                  marginRight: '3rem',
                }
            }
          />
        </Stack>
        <Stack>
          <Typography
            variant="h1"
            fontSize={{ xs: '2.4rem', md: '3.2rem' }}
            fontWeight={400}
            lineHeight={{ xs: '3rem', md: '3.5rem' }}
            marginBottom={{ xs: '2rem', sm: '1.2rem' }}
          >
            Get Death Benefit cover
          </Typography>
          <Typography fontSize={'1.5rem'} fontWeight={300} lineHeight={'2rem'}>
            Ensure{' '}
            <Typography component={'span'} fontSize={'inherit'} fontWeight={600} lineHeight={'inherit'}>
              your loved ones{' '}
            </Typography>
            are taken care of with an{' '}
            <Typography component={'span'} fontSize={'inherit'} fontWeight={600} lineHeight={'inherit'}>
              immediate cash{' '}
            </Typography>
            payout. Plus, we’ll cover your loan at{' '}
            <Typography component={'span'} fontSize={'inherit'} fontWeight={600} lineHeight={'inherit'}>
              no additional cost
            </Typography>
            , so you can have peace of mind knowing everything is handled.{'  '}
            <br />
            <br />
            <Typography component={'span'} fontSize={'inherit'} lineHeight={'inherit'}>
              Alternatively, continue with the Credit Life cover only that will be paid by you and charged in your loan.{'  '}
            </Typography>
            <Typography
              component={'span'}
              fontSize={'1.5rem'}
              fontWeight={400}
              fontStyle={'normal'}
              color={theme.palette.primary.main}
              onClick={() => setShowDeathBenefitPopup(true)}
              sx={{ cursor: 'pointer' }}
            >
              Read more
            </Typography>
            <DeathBenefitDescriptionPopup
              open={showDeathBenefitPopup}
              handleClose={() => setShowDeathBenefitPopup(false)}
            />
          </Typography>
          <Stack spacing={'0.8rem'} marginTop={{ xs: '2.8rem', sm: '3.2rem' }}>
            <Typography fontSize={'1.6rem'} fontWeight={400} lineHeight={'2.2rem'}>
              Benefits
            </Typography>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography fontSize={'1.5rem'} fontWeight={300} lineHeight={'2.2rem'}>
                Cover amount
              </Typography>
              <Typography
                fontSize={'1.5rem'}
                fontWeight={600}
                lineHeight={'2.2rem'}
              >{`R ${quotationWithDeathBenefit?.deathBenefitTotalCover ?? 0}`}</Typography>
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography fontSize={'1.5rem'} fontWeight={300} lineHeight={'2.2rem'}>
                Term of cover
              </Typography>
              <Typography fontSize={'1.5rem'} fontWeight={600} lineHeight={'2.2rem'}>
                Loan term + 2½ months
              </Typography>
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography fontSize={'1.5rem'} fontWeight={300} lineHeight={'2.2rem'}>
                Credit Life cover
              </Typography>
              <Typography fontSize={'1.5rem'} fontWeight={600} lineHeight={'2.2rem'}>
                No charge
              </Typography>
            </Stack>
            <br />
            {!deathBenefitVariantLoading && deathBenefitVariant == CONFIGCAT_KEYS.VARIANT.A && <>
              <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                <Typography fontSize={'1.5rem'} fontWeight={300} lineHeight={'2.2rem'}>
                  Repayment details
                </Typography>
                <Box position={'relative'} display={'inline-block'}>
                  <Avatar
                    src={InfoIcon}
                    alt="Information icon"
                    sx={{
                      width: '1.6rem',
                      height: '1.6rem',
                      marginLeft: '0.8rem',
                      cursor: 'pointer',
                    }}
                    onClick={() => setShowCreditLifePlusFeeBreakdownPopup(true)}
                  ></Avatar>
                </Box>
              </Stack>
            </>}

          </Stack>
        </Stack>
      </Stack>
      <Stack
        direction={{ xs: 'column-reverse', sm: 'row' }}
        justifyContent={'space-between'}
        margin={{ xs: '5rem 0 5rem 0', sm: '7.9rem 0 5rem 3rem' }}
        spacing={'2rem'}
      >
        <SecondaryButton onClick={() => handleContinue(false)}>
          {deathBenefitSecondaryCTAText || 'Continue with Credit Life'}
        </SecondaryButton>
        <Box sx={{ position: 'relative' }}>
          <img
            src={GreatOfferIcon}
            width={'82px'}
            height={'29px'}
            style={{ position: 'absolute', right: 0, top: '-34px' }}
          />
          <PrimaryButton type="submit" onClick={() => handleContinue(true)}>
            {deathBenefitPrimaryCTAText || 'Continue with Death Benefit'}
          </PrimaryButton>
        </Box>
      </Stack>
      <CreditLifePlusFeeBreakdownPopup
        open={showCreditLifePlusFeeBreakdownPopup}
        getQuotationResponse={quotationWithDeathBenefit}
        creditLifeSaving={quotationWithCreditLife?.insuranceFee}
        deathBenefitOptedIn={true}
        setShowQuotationBreakdownPopup={() => { setShowQuotationBreakdownPopup(true) }}
        handleClose={() => { setShowCreditLifePlusFeeBreakdownPopup(false) }} />

      <QuotationFeeBreakdownPopup
        open={showQuotationBreakdownPopup}
        handleClose={() => setShowQuotationBreakdownPopup(false)}
        deathBenefitOptedIn={true}
        getQuotationResponse={quotationWithDeathBenefit}
      />
    </Container>
  );
};

export default CreditLifePlus;
