import React from 'react'
import Typography from "@mui/material/Typography";
import styles from "./Declined.module.scss";
import OptimonkContainer from '../../../../../components/OptimonkContainer/OptimonkContainer';

const Declined = () => {

    return (
        <div className={styles["declined-container"]}>
            <Typography variant="h2" paddingTop={"3rem"} marginBottom={"1.6rem"} fontSize={{ xs: "2.4rem", sm: "3.2rem" }} fontWeight={300}>
                We&apos;re sorry we can&apos;t approve you this time.
            </Typography>
            <Typography variant="body1" marginBottom={"1.6rem"} fontWeight={300}>
                Our assessments show that you may get into financial difficulty in repaying this loan.
            </Typography>
            <Typography variant="body1" fontWeight={300} marginBottom={"3.4rem"}>
                Below are some possible ways to <b>save</b> on your monthly expenses, why not give it a try?
            </Typography>
            <OptimonkContainer showContainer={true} />
        </div>
    )
}

export default Declined