import {
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { HomeStatusEnum, HomeLanguageEnum, MaritalStatusEnum } from "../../../../clients/AccountClient";

interface Option {
  label: string;
  value: boolean;
}

interface SubscribeOnMailProps {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  customerDetailsForm: UseFormReturn<{ dependents: string; homeStatus: HomeStatusEnum; homeLanguage: HomeLanguageEnum; maritalStatus: MaritalStatusEnum; marketing: boolean; }, any>;
}

const SubscribeOnMail = ({ customerDetailsForm }: SubscribeOnMailProps) => {
  const [marketingPreference, setMarketingPreference] = useState<boolean>(customerDetailsForm.getValues("marketing"));
  const [showLegalInformation, setShowLegalInformation] =
    useState<boolean>(false);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const optInValue = event.target.value === "true";
    setMarketingPreference(optInValue);
    customerDetailsForm.setValue("marketing", optInValue);
  };
  const options: Option[] = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];
  const RadioOptions = () => {
    return options.map((data: Option) => (
      <FormControlLabel
        value={data.value}
        key={data.label}
        label={data.label}
        control={<Radio />}
      />
    ));
  };
  console.log(marketingPreference);
  return (
    <Container style={{ padding: "0 0 2rem 0" }}>
      <Typography
        style={{
          fontSize: "15px",
          margin: "10px 0 10px 0",
          lineHeight: "22px",
          fontWeight: "300",
        }}
      >
        Click here to be included on newsletters featuring competitions, product
        information and financial tips.
      </Typography>
      <RadioGroup
        row
        value={marketingPreference}
        onChange={handleRadioChange}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          "& .MuiSvgIcon-root": {
            fontSize: 28,
          },
        }}
      >
        {RadioOptions()}
      </RadioGroup>
      <Typography
        style={{
          fontSize: "15px",
          margin: "10px 0 15px 0",
          lineHeight: "22px",
          fontWeight: "600",
        }}
      >
        Please read this important
        {" "}
        <span
          onClick={() => setShowLegalInformation(!showLegalInformation)}
          style={{
            color: "#008fd5",
            fontSize: "15px",
            fontWeight: "600",
            cursor: "pointer",
            marginLeft: 0
          }}
        >
          legal information
        </span>{" "}
        by clicking the button below, you are confirming you have read this.
      </Typography>
      {showLegalInformation && (
        <>
          <Typography variant="h2" style={{ margin: "20px 0" }}>
            Legal Declaration
          </Typography>
          <Typography variant="body2" style={{ paddingLeft: "10px", fontWeight: 300, fontSize: "1.4rem" }}>
            I, the Applicant, hereby agree and declare that:{" "}
          </Typography>
          <br></br>
          <ol style={{ paddingLeft: "10px", fontWeight: 300, fontSize: "1.4rem" }}>
            <li>I hereby apply for a loan to be made to me by Wonga.</li>
            <li>
              I understand that Wonga will decide in its sole and absolute
              discretion as to whether to grant the loan or not.
            </li>
            <li>
              I understand that Wonga made cede, transfet or otherwise dispose
              of its rights under this Application form and the Loan Agreement
              and all documents incorporated therein to any third party. By
              signing the Appication Form, i give my general consent to Wonga to
              cede, assign, transfer or otherwise dispose of its rights under
              the Application Form and the Loan Agreement and all documents
              incorporated therein to any third party.
            </li>
            <li>
              By signing this Application Form, I agee that any person
              interested in the Loan Agreement or any part thereof, now or inthe
              future, may rely upon the truth, correctness and accuracy of the
              information contained in this Application Form and any other
              supporting documentation or information provided by me.
            </li>
            <li>
              I have been advised by Wonga that it is collects and uses the
              personal information that i give it in the Application Form to
              assess my eligibility for and understanding of a Wonga loan.
            </li>
          </ol>
          <br></br>
          <Typography variant="body2" style={{ paddingLeft: "10px", fontWeight: 300, fontSize: "1.4rem" }}>
            I acknoledge and agree that the information provided to Wonga in
            this Application Form and the supporting documentation may, subject
            to any law to the contrary, be used by:
          </Typography>
        </>
      )}
    </Container>
  );
};
export default SubscribeOnMail;
