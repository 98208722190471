import { useContext, useMemo } from "react";
import { IConfig, AccountClient } from "../../clients/AccountClient";
import { AppSettingsContext } from "../../contexts/AppSettingsContext";
import { AuthContext } from "../../contexts/AuthContext";

const useAccountClient = () => {
  const appSettings = useContext(AppSettingsContext);
  const ACCOUNT_BASE_URL = appSettings.apiEndpoints.account;
  const { logout } = useContext(AuthContext);
  const config: IConfig = new IConfig(logout);

  const client = useMemo(() => {
    return new AccountClient(config, ACCOUNT_BASE_URL);
    // eslint-disable-next-line
  }, [ACCOUNT_BASE_URL]);

  return client;
};
export default useAccountClient;
