import { BankAccountType, EmploymentIndustryEnum, EmploymentStatusEnum, GenderEnum, HomeLanguageEnum, HomeStatusEnum, IncomeFrequencyEnum, MaritalStatusEnum, PositionEnum } from "../clients/AccountClient";
import KeyValuePair from "./KeyValuePair";
import { BeneficiaryRelationshipEnum } from "./enums/BeneficiaryRelationshipEnum";

export const homeLanguageMappings: KeyValuePair<HomeLanguageEnum>[] = [
  { key: "Please select", value: HomeLanguageEnum.Unspecified },
  { key: "English", value: HomeLanguageEnum.English },
  { key: "Afrikaans", value: HomeLanguageEnum.Afrikaans },
  { key: "isiNdebele", value: HomeLanguageEnum.IsIndebele },
  { key: "isiXhosa", value: HomeLanguageEnum.IsIxhosa },
  { key: "isiZulu", value: HomeLanguageEnum.IsIzulu },
  { key: "Northern Sotho", value: HomeLanguageEnum.NorthernSotho },
  { key: "Sesotho", value: HomeLanguageEnum.Sesotho },
  { key: "Setswana", value: HomeLanguageEnum.Setswana },
  { key: "siSwati", value: HomeLanguageEnum.SiSwati },
  { key: "Tshivenda", value: HomeLanguageEnum.Tshivenda },
  { key: "Xitsonga", value: HomeLanguageEnum.XitSonga },
  { key: "Other", value: HomeLanguageEnum.Other },
];

export const homeStatusMappings: KeyValuePair<HomeStatusEnum>[] = [
  { key: "Please select", value: HomeStatusEnum.Unspecified },
  { key: "Owner Occupier", value: HomeStatusEnum.OwnerOccupier },
  { key: "Living with Parents", value: HomeStatusEnum.LivingWithParents },
  { key: "Tenant Furnished", value: HomeStatusEnum.TenantFurnished },
  { key: "Tenant Unfurnished", value: HomeStatusEnum.TenantUnfurnished },
  { key: "Council Tenant", value: HomeStatusEnum.CouncilTenant },
  { key: "Tenant", value: HomeStatusEnum.Tenant },
  { key: "Joint Owner", value: HomeStatusEnum.JointOwner },
  { key: "Other", value: HomeStatusEnum.Other },
];

export const maritalStatusMappings: KeyValuePair<MaritalStatusEnum>[] = [
  { key: "Please select", value: MaritalStatusEnum.Unspecified },
  { key: "Married out of community of property", value: MaritalStatusEnum.MarriedOutOfCommunityOfProperty },
  { key: "Married in community of property", value: MaritalStatusEnum.MarriedInCommunityOfProperty },
  { key: "Single", value: MaritalStatusEnum.Single },
  { key: "Divorced", value: MaritalStatusEnum.Divorced },
  { key: "Widowed", value: MaritalStatusEnum.Widowed },
  { key: "Living Together", value: MaritalStatusEnum.LivingTogether },
  { key: "Separated", value: MaritalStatusEnum.Separated },
  { key: "Other", value: MaritalStatusEnum.Other },
];

export const dependentMappings: KeyValuePair<string>[] = [
  { key: "Please select", value: "Unspecified" },
  { key: "0", value: "0" },
  { key: "1", value: "1" },
  { key: "2", value: "2" },
  { key: "3", value: "3" },
  { key: "4", value: "4" },
  { key: "5", value: "5" },
  { key: "6", value: "6" },
  { key: "7", value: "7" },
  { key: "8", value: "8" },
  { key: "9", value: "9" },
  { key: "10", value: "10" },
];

export const genderMappings: KeyValuePair<GenderEnum>[] = [
  { key: "Please select", value: GenderEnum.Unspecified },
  { key: "Male", value: GenderEnum.Male },
  { key: "Female", value: GenderEnum.Female },
];

//Bank related options
export const bankNameMappings: KeyValuePair<string>[] = [
  { key: "Please Select", value: "" },
  { key: "ABSA", value: "ABSA" },
  { key: "Capitec", value: "Capitec" },
  { key: "First National Bank", value: "First National Bank" },
  { key: "Nedbank", value: "Nedbank" },
  { key: "Standard Bank", value: "Standard Bank" },
];

export const bankCodeMappings: KeyValuePair<string>[] = [
  {
    key: "ABSA",
    value: "632005",
  },
  {
    key: "Capitec",
    value: "470010",
  },
  {
    key: "First National Bank",
    value: "250655",
  },
  {
    key: "Nedbank",
    value: "198765",
  },
  {
    key: "Standard Bank",
    value: "051001",
  },
];

export const bankAccountTypeMappings: KeyValuePair<BankAccountType>[] = [
  { key: "Please Select", value: BankAccountType.Unspecified },
  { key: "Savings", value: BankAccountType.Savings },
  { key: "Current", value: BankAccountType.Current },
  { key: "Cheque", value: BankAccountType.Cheque },
  { key: "Transmission", value: BankAccountType.Transmission },
];

export const employmentStatusMappings: KeyValuePair<EmploymentStatusEnum>[] = [
  { key: "Please Select", value: EmploymentStatusEnum.Unspecified },
  { key: "Employed Full Time", value: EmploymentStatusEnum.EmployedFullTime },
  { key: "Employed Part Time", value: EmploymentStatusEnum.EmployedPartTime },
  { key: "Employed Temporary", value: EmploymentStatusEnum.EmployedTemporary },
  { key: "Self Employed", value: EmploymentStatusEnum.SelfEmployed },
  { key: "Student", value: EmploymentStatusEnum.Student },
  { key: "HomeMaker", value: EmploymentStatusEnum.HomeMaker },
  { key: "Retired", value: EmploymentStatusEnum.Retired },
  { key: "Unemployed", value: EmploymentStatusEnum.Unemployed },
  { key: "On Benefits", value: EmploymentStatusEnum.OnBenefits },
  { key: "Armed Forces", value: EmploymentStatusEnum.ArmedForces }
];

export const incomeFrequencyMappings: KeyValuePair<IncomeFrequencyEnum>[] = [
  { key: "Please Select", value: IncomeFrequencyEnum.Unspecified },
  { key: "Monthly", value: IncomeFrequencyEnum.Monthly },
  { key: "Twice Monthly", value: IncomeFrequencyEnum.TwiceMonthly },
  { key: "Weekly", value: IncomeFrequencyEnum.Weekly },
  { key: "Bi Weekly", value: IncomeFrequencyEnum.BiWeekly },
  { key: "Last Day Of Month", value: IncomeFrequencyEnum.LastDayOfMonth },
  { key: "Last Friday Of Month", value: IncomeFrequencyEnum.LastFridayOfMonth },
  { key: "Twice Monthly 15th And 30th", value: IncomeFrequencyEnum.TwiceMonthly15ThAnd30Th }
];

export const yearsMappings: KeyValuePair<string>[] = [
  { key: "Please Select", value: "" },
  { key: "0 years", value: "0" },
  { key: "1 years", value: "1" },
  { key: "2 years", value: "2" },
  { key: "3 years", value: "3" },
  { key: "4 years", value: "4" },
  { key: "5 years", value: "5" },
  { key: "6 years", value: "6" },
  { key: "7 years", value: "7" },
  { key: "8 years", value: "8" },
  { key: "9 years", value: "9" },
  { key: "10 years", value: "10" },
  { key: "11 years", value: "11" },
  { key: "12 years", value: "12" },
  { key: "13 years", value: "13" },
  { key: "14 years", value: "14" },
  { key: "15 years", value: "15" },
  { key: "16 years", value: "16" },
  { key: "17 years", value: "17" },
  { key: "18 years", value: "18" },
  { key: "19 years", value: "19" },
  { key: "20 years", value: "20" },
  { key: "21 years", value: "21" },
  { key: "22 years", value: "22" },
  { key: "23 years", value: "23" },
  { key: "24 years", value: "24" },
  { key: "25 years", value: "25" },
  { key: "26 years", value: "26" },
  { key: "27 years", value: "27" },
  { key: "28 years", value: "28" },
  { key: "29 years", value: "29" },
  { key: "30 years", value: "30" },
  { key: "31 years", value: "31" },
];

export const monthsMappings: KeyValuePair<string>[] = [
  { key: "Please Select", value: "" },
  { key: "0 months", value: "0" },
  { key: "1 months", value: "1" },
  { key: "2 months", value: "2" },
  { key: "3 months", value: "3" },
  { key: "4 months", value: "4" },
  { key: "5 months", value: "5" },
  { key: "6 months", value: "6" },
  { key: "7 months", value: "7" },
  { key: "8 months", value: "8" },
  { key: "9 months", value: "9" },
  { key: "10 months", value: "10" },
  { key: "11 months", value: "11" },
  { key: "12 months", value: "12" },
];

export const industryMappings: KeyValuePair<EmploymentIndustryEnum>[] = [
  { key: "Please select", value: EmploymentIndustryEnum.Unspecified },
  { key: "Accountancy", value: EmploymentIndustryEnum.Accountancy },
  { key: "Advertising and Media", value: EmploymentIndustryEnum.AdvertisingAndMedia },
  { key: "Business Consultancy", value: EmploymentIndustryEnum.BusinessConsultancy },
  { key: "Call Centre Operations", value: EmploymentIndustryEnum.CallCentreOperations },
  { key: "Cleaning", value: EmploymentIndustryEnum.Cleaning },
  { key: "Computer Services", value: EmploymentIndustryEnum.ComputerServices },
  { key: "Construction", value: EmploymentIndustryEnum.Construction },
  { key: "Education", value: EmploymentIndustryEnum.Education },
  { key: "Electricity", value: EmploymentIndustryEnum.Electricity },
  { key: "Finance", value: EmploymentIndustryEnum.Finance },
  { key: "Health", value: EmploymentIndustryEnum.Health },
  { key: "Hotels Restaurants", value: EmploymentIndustryEnum.HotelsRestaurants },
  { key: "Insurance", value: EmploymentIndustryEnum.Insurance },
  { key: "Legal Services", value: EmploymentIndustryEnum.LegalServices },
  { key: "Leisure, Cultural, Travel and Tourism", value: EmploymentIndustryEnum.LeisureCulturalTravelAndTourism },
  { key: "Manufacturing", value: EmploymentIndustryEnum.Manufacturing },
  { key: "Mining", value: EmploymentIndustryEnum.Mining },
  { key: "Public Administration", value: EmploymentIndustryEnum.PublicAdministration },
  { key: "Publishing", value: EmploymentIndustryEnum.Publishing },
  { key: "Property", value: EmploymentIndustryEnum.Property },
  { key: "Research Development", value: EmploymentIndustryEnum.ResearchDevelopment },
  { key: "Retail", value: EmploymentIndustryEnum.Retail },
  { key: "Telecommunications, Internet and IT", value: EmploymentIndustryEnum.TelecommunicationsInternetAndIT },
  { key: "Transportation and Logistics", value: EmploymentIndustryEnum.TransportationAndLogistics }
];

export const positionMappings: KeyValuePair<PositionEnum>[] = [
  { key: "Please select", value: PositionEnum.Unspecified },
  { key: "Actor, Musician, Artist, Writer, Journalist", value: PositionEnum.ActorMusicianArtistWriterJournalist },
  { key: "Administration", value: PositionEnum.Administration },
  { key: "Bus Driver", value: PositionEnum.BusDriver },
  { key: "Business Development", value: PositionEnum.BusinessDevelopment },
  { key: "Business Owner", value: PositionEnum.BusinessOwner },
  { key: "Consultancy", value: PositionEnum.Consultancy },
  { key: "Doctor", value: PositionEnum.Doctor },
  { key: "Engineering", value: PositionEnum.Engineering },
  { key: "Marketing", value: PositionEnum.Marketing },
  { key: "Cab Driver", value: PositionEnum.CabDriver },
  { key: "Musician", value: PositionEnum.Musician },
  { key: "Nurse", value: PositionEnum.Nurse },
  { key: "Other", value: PositionEnum.Other },
  { key: "Sales", value: PositionEnum.Sales },
  { key: "Senior management/Director", value: PositionEnum.SeniorManagementDirector },
  { key: "Services", value: PositionEnum.Services },
  { key: "Teacher", value: PositionEnum.Teacher },
  { key: "TruckDriver", value: PositionEnum.TruckDriver },
  { key: "Firefighter", value: PositionEnum.Firefighter },
  { key: "Management", value: PositionEnum.Management },
  { key: "TaxiDriver", value: PositionEnum.TaxiDriver },
  { key: "Mining", value: PositionEnum.Mining },
];

export const provinceMappings: KeyValuePair<string>[] = [
  { key: "Please select", value: "" },
  { key: "Eastern Cape", value: "Eastern Cape" },
  { key: "Free State", value: "Free State" },
  { key: "Gauteng", value: "Gauteng" },
  { key: "KwaZulu-Natal", value: "KwaZulu-Natal" },
  { key: "Limpopo", value: "Limpopo" },
  { key: "Mpumalanga", value: "Mpumalanga" },
  { key: "Northern Cape", value: "Northern Cape" },
  { key: "North West", value: "North West" },
  { key: "Western Cape", value: "Western Cape" },
];

export const beneficiaryRelationshipMappings:  KeyValuePair<BeneficiaryRelationshipEnum>[] = [
  { key: "Please select", value: BeneficiaryRelationshipEnum.Unspecified },
  { key: "Spouse", value: BeneficiaryRelationshipEnum.Spouse },
  { key: "Child", value: BeneficiaryRelationshipEnum.Child },
  { key: "Sibling", value: BeneficiaryRelationshipEnum.Sibling },
  { key: "Parent", value: BeneficiaryRelationshipEnum.Parent },
  { key: "Grandchild", value: BeneficiaryRelationshipEnum.Grandchild },
  { key: "Other Relative", value: BeneficiaryRelationshipEnum.OtherRelative },
];

