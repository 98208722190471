import { Container, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import FacebookIcon from '../../assets/img/webp/facebook.webp';
import InstagramIcon from '../../assets/img/webp/instagram.webp';
import TwitterXIcon from '../../assets/img/webp/twitter.webp';
import YouTubeIcon from '../../assets/img/webp/youtube.webp';
import styles from './WongaFooter.module.scss';
import { useAppSettings } from '../../contexts/AppSettingsContext';

const WongaFooter = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { companyDetails } = useAppSettings();
  return (
    <footer className={styles['footer']}>
      <Container maxWidth={'lg'}>
        <Grid container>
          <Grid item xs={4} md={3}>
            <Stack>
              <Typography className={styles['footer-title']}>Customer</Typography>
              <a href="/help" className={styles['footer-link']}>
                Help
              </a>
              <a href="/trust-rating" className={styles['footer-link']}>
                Trust rating
              </a>
              <a href="/quick-loans" className={styles['footer-link']}>
                Quick Loans
              </a>
              <a href="/about" className={styles['footer-link']}>
                Why use us
              </a>
            </Stack>
          </Grid>
          <Grid item xs={4} md={3}>
            <Stack>
              <Typography className={styles['footer-title']}>Corporate</Typography>
              <a href="https://explore.wonga.co.za/" target="_blank" rel="noreferrer" className={styles['footer-link']}>
                About us
              </a>
              <a
                href="https://explore.wonga.co.za/careers/"
                target="_blank"
                rel="noreferrer"
                className={styles['footer-link']}
              >
                Careers
              </a>
              <a href="/blog" className={styles['footer-link']}>
                News
              </a>
              {/* <a href="/affiliates" className={styles['footer-link']}>
                Affiliates
              </a> */}
            </Stack>
          </Grid>
          <Grid item xs={4} md={3}>
            <Stack>
              <Typography className={styles['footer-title']}>Legal</Typography>
              <a href="/code-of-practice" className={styles['footer-link']}>
                Code of Practice
              </a>
              <a href="/privacy" className={styles['footer-link']}>
                Privacy
              </a>
              <a href="/terms-of-use" className={styles['footer-link']}>
                Terms of Use
              </a>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack className={styles[isMobile ? 'footer-community-container-mobile' : 'footer-community-container']}>
              <Typography className={styles['footer-title']}>Join our community</Typography>
              <Stack className={styles['footer-social-icon-container']}>
                <a href="https://www.facebook.com/wongaza" target="_blank" rel="noreferrer">
                  <img
                    src={FacebookIcon}
                    aria-label="Facebook Icon"
                    alt="Facebook Icon"
                    className={styles['footer-social-icon']}
                  />
                </a>
                <a href="https://twitter.com/wonga_ZA" target="_blank" rel="noreferrer">
                  <img
                    src={TwitterXIcon}
                    aria-label="Twitter Icon"
                    alt="Twitter Icon"
                    className={styles['footer-social-icon']}
                  />
                </a>
                <a href="https://www.instagram.com/wonga_za" target="_blank" rel="noreferrer">
                  <img
                    src={InstagramIcon}
                    aria-label="Instagram Icon"
                    alt="Instagram Icon"
                    className={styles['footer-social-icon']}
                  />
                </a>
                <a href="https://www.youtube.com/wongaza" target="_blank" rel="noreferrer">
                  <img
                    src={YouTubeIcon}
                    aria-label="YouTube Icon"
                    alt="YouTube Icon"
                    className={styles['footer-social-icon']}
                  />
                </a>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Typography className={styles[isMobile ? 'footer-copyright-mobile' : 'footer-copyright']}>
          {`@2019 Wonga Online (Pty) Ltd | Physical Address: 1st floor, 14 Kloof Street, Gardens, Cape Town, 8001, South
          Africa. Telephone: ${companyDetails.CompanyContactNumber} Registered in South Africa, Company no: 2019/167500/07. A registered Credit
          Provider, NCRCP12875.`}
        </Typography>
      </Container>
    </footer>
  );
};

export default WongaFooter;
