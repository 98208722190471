import { Button } from "@mui/material";
import React, { ReactNode } from "react";

interface NavigationButtonProps {
    children: ReactNode;
    type?: "button" | "submit" | "reset";
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    fullWidth?: boolean;
}

const NavigationButton = ({
    type,
    onClick,
    disabled,
    children,
    fullWidth,
}: NavigationButtonProps) => {
    return (
        <Button
            disabled={disabled}
            variant="contained"
            type={type}
            onClick={onClick}
            sx={{
                backgroundColor: '#F5F5F5',
                color: '#333333',
                width: fullWidth ? { xs: "100%" } : { xs: "100%" },
                height: { xs: "5rem", sm: '5.9rem' },
                borderRadius: "0.8rem",
                boxShadow: "none",
                ":hover": {
                    backgroundColor: '#F5F5F5',
                    color: '#333333',
                    boxShadow: "none",
                },
            }}
        >
            {children}
        </Button>
    );
};

export default NavigationButton;
