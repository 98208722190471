import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useFeatureFlag } from 'configcat-react';
import React, { useEffect, useState } from "react";
import { useTracking } from "../../Tracking/TrackingContext";
import { UrgentBanner } from "../../configCat/interfaces";
import styles from "./UrgentNotificationBanner.module.scss";
import { CONFIGCAT_KEYS } from "../../configCat/configCatKeys";

export const notificationBannerKey = "notificationBannerDismissed";

const UrgentNotificationBanner: React.FunctionComponent = () => {

    const { TrackFeatureFlagEvent } = useTracking();
    const [isNotificationBannerDismissed, setNotificationBannerDismissed] = useState(() => {
        const storedValue = localStorage.getItem(notificationBannerKey);
        return storedValue ? JSON.parse(storedValue) : false;
    });

    const [urgentBanner, setUrgentBanner] = useState<UrgentBanner>({
        urgentMessageHeading: "",
        urgentMessageText: ""
    });

    const { value: showBanner, loading: showBannerLoading } = useFeatureFlag(CONFIGCAT_KEYS.SHOW_BANNER.key, CONFIGCAT_KEYS.SHOW_BANNER.defaultValue);
    const { value: bannerText, loading: bannerTextLoading } = useFeatureFlag(CONFIGCAT_KEYS.BANNER_TEXT.key, CONFIGCAT_KEYS.BANNER_TEXT.defaultValue);

    const closeBanner = () => {
        setNotificationBannerDismissed(true);
        localStorage.setItem(notificationBannerKey, JSON.stringify(true));
    };

    useEffect(() => {

        if (!showBannerLoading && !bannerTextLoading) {
            try {
                const banner = JSON.parse(bannerText) as UrgentBanner;
                TrackFeatureFlagEvent({ featureFlag: CONFIGCAT_KEYS.SHOW_BANNER.key, value: showBanner });
                setUrgentBanner(banner);
            } catch (e) {
                setUrgentBanner({
                    urgentMessageHeading: '',
                    urgentMessageText: ''
                });
            }
        }
    }, [TrackFeatureFlagEvent, bannerText, bannerTextLoading, showBanner, showBannerLoading])

    return (
        <>
            {
                showBanner && !isNotificationBannerDismissed ?
                    <Box sx={{ backgroundColor: "#188ece", padding: "1rem 0.5rem" }}>
                        <Typography sx={{ color: "white", textAlign: "center", fontSize: "2.1rem", fontWeight: "700", padding: "0 4rem" }}>
                            {urgentBanner.urgentMessageHeading}
                            <span className={styles["close-banner-button"]} onClick={closeBanner}>x</span>
                        </Typography>
                        <Typography sx={{ color: "white", textAlign: "center", fontWeight: "300", fontSize: "1.4rem" }}>{urgentBanner.urgentMessageText}</Typography>
                    </Box>
                    :
                    <></>
            }
        </>
    );
};

export default UrgentNotificationBanner;