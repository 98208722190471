import { useContext, useMemo } from "react";
import { LoanClient, IConfig } from "../../clients/LoanClient";
import { AppSettingsContext } from "../../contexts/AppSettingsContext";
import { AuthContext } from "../../contexts/AuthContext";

const useLoanClient = () => {
  const appSettings = useContext(AppSettingsContext);
  const LOAN_BASE_URL = appSettings.apiEndpoints.loan;
  const { logout } = useContext(AuthContext);
  const config: IConfig = new IConfig(logout);

  const client = useMemo(() => {
    return new LoanClient(config, LOAN_BASE_URL);
    // eslint-disable-next-line
  }, [LOAN_BASE_URL]);

  return client;
};

export default useLoanClient;
