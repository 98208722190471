import { ReactNode } from "react";

import * as React from "react";
import { Box, Container, Typography, useTheme } from "@mui/material";

interface FormWrapperProps {
  title: string;
  children: ReactNode;
  removeMargin?: boolean;
}

export default function FormWrapper(props: FormWrapperProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        marginTop: props.removeMargin ? theme.spacing(0) : theme.spacing(4),
      }}
    >
      <Container>
        <Typography
          variant="h2"
          variantMapping={{ h2: "h1" }}
          fontSize={{ xs: "2.4rem", sm: "3.2rem" }}
          sx={{
            fontWeight: 300,
            marginBottom: "1.2rem",
          }}
        >
          {props.title}
        </Typography>
        {props.children}
      </Container>
    </Box>
  );
}
