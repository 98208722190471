import { useOutletContext } from "react-router-dom";
import { IGetPersonalDetailsResponse } from "../../clients/AccountClient";
import { IGetLoansSummaryResponse } from "../../clients/LoanClient";

export type MyLoanContext = {
    personalDetailsResponse: IGetPersonalDetailsResponse;
    loansSummaryResponse: IGetLoansSummaryResponse;
    refreshLoansSummary: () => Promise<void>;
}

export function useMyLoanContext() {
    return useOutletContext<MyLoanContext>();
}