import { Box, Container, Grid, Link, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ErrorBlock from '../ErrorBlock/ErrorBlock';
import { theme } from '../../theme/Theme';
import PrimaryButton from '../Buttons/PrimaryButton';
import { FULL_COLUMN_SIZE, THIRD_COLUMN_SIZE } from '../../utils/GridColumnSizeDefinitions';
import SliderWithFlap from '../Sliders/slider-with-flap/slider-with-flap';

const ChangeLoanValues: React.FC<{ onEditing?: (isEditing: boolean) => void }> = ({ onEditing }) => {
  const [loanAmount, setLoanAmount] = useState();
  const [showSlider, setShowSlider] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getApplyNowValues = () => {
    const applyValuesString = localStorage.getItem('applyValues');

    if (applyValuesString) {
      const applyValues = JSON.parse(applyValuesString);
      setLoanAmount(applyValues.amount);
    }
  };

  const ChangeLoanDetails = () => {
    setShowSlider(true);
    if (onEditing) {
      onEditing(true);
    }
  };

  const onChangeUpdateLoanValues = () => {
    setShowSlider(false);
    if (onEditing) {
      onEditing(false);
    }
  };

  useEffect(() => {
    getApplyNowValues();
  }, [showSlider]);

  return (
    <>
      <Box sx={{ marginTop: { md: '50px', xs: '20px' } }}>
        {showSlider ? (
          <>
            <Container maxWidth="lg">
              <Stack flexDirection={'row'} justifyContent={'center'} alignItems="center">
                <SliderWithFlap updateLoanValues={onChangeUpdateLoanValues} />
              </Stack>
              <Grid container justifyContent={'space-between'} sx={{ marginTop: { md: '1.8rem', xs: '1.2rem' } }}>
                <ErrorBlock>
                  <Typography variant="body1" fontSize={'1.4rem'} fontWeight={'300'}>
                    Please edit your loan details and click Apply now.
                  </Typography>
                </ErrorBlock>
              </Grid>
            </Container>
          </>
        ) : (
          <Container maxWidth="lg">
            <Grid container justifyContent={{ md: 'space-between', xs: 'center' }}>
              <Typography marginBottom={{ xs: 2 }} sx={{ fontSize: isMobile ? '20px' : '34px' }}>
                You&apos;re applying to borrow{' '}
                <Link
                  style={{
                    textDecoration: 'none',
                    color: theme.palette.primary.light,
                    cursor: 'pointer',
                    paddingRight: 40,
                  }}
                  onClick={ChangeLoanDetails}
                >
                  R{loanAmount}{' '}
                </Link>
              </Typography>
              <Grid
                item
                xs={FULL_COLUMN_SIZE}
                sm={THIRD_COLUMN_SIZE}
                display="flex"
                justifyContent={{
                  sm: 'flex-end',
                }}
              >
                <PrimaryButton type="submit" onClick={ChangeLoanDetails}>
                  Change
                </PrimaryButton>
              </Grid>
            </Grid>
          </Container>
        )}
      </Box>
    </>
  );
};

export default ChangeLoanValues;
