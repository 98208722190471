import TextField from '@mui/material/TextField';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CloseIcon from '@mui/icons-material/Close';
import classes from './RepaymentDate.module.scss';
import { set } from 'date-fns';

interface IRepaymentDateProps {
  displayRepaymentDate: boolean;
  onSalaryDayPick?: (day: number) => void;
  SetShowRepaymentDatePicker: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
}

const RepaymentDate: React.FunctionComponent<IRepaymentDateProps> = (props) => {
  if (!props.displayRepaymentDate) {
    return null;
  }

  function getLastDayOfMonthWith31Days(year: number, month: number) {
    return set(new Date(), { year: year, month: month - 1, date: 31 });
  }

  const fixedMonth = getLastDayOfMonthWith31Days(2023, 8);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className={classes.container}>
        <div className={classes.header}>
          <span className={classes.headerTitle}>Regular Salary Date</span>
          <CloseIcon onClick={() => props.SetShowRepaymentDatePicker(false)} className={classes.headerTitle} />
        </div>
        <StaticDatePicker
          label="Repay Date"
          views={['day']}
          disableHighlightToday={true}
          closeOnSelect
          openTo="day"
          value={fixedMonth}
          onChange={(newSalaryDate) => {
            if (newSalaryDate) {
              const day = newSalaryDate.getDate();
              if (props.onSalaryDayPick) props.onSalaryDayPick(day);
              props.SetShowRepaymentDatePicker(false);
            }
          }}
          renderInput={(params) => <TextField {...params} className={classes.datePickerInput} />}
        />
      </div>
    </LocalizationProvider>
  );
};

export default RepaymentDate;
