import React from "react";
import styles from "./FeesBreakdown.module.scss";
import { formatMoney } from "../../../../utils/Helpers/SliderHelperFunctions";
import { Avatar, Stack, Typography } from "@mui/material";
import CloseIcon from '../../../../assets/img/Close.png';
import { theme } from "../../../../theme/Theme";

interface FeesBreakdownProps {
  totalRepayable?: number;
  initiationFee?: number;
  serviceFee?: number;
  totalInterest?: number;
  insuranceFee?: number;
  deathBenefitContractValue?: number;
  feesBreakdownHandler: React.Dispatch<React.SetStateAction<boolean>>;
}

const FeesBreakdown: React.FunctionComponent<FeesBreakdownProps> = (props) => {
  const { initiationFee, serviceFee, totalInterest, insuranceFee, totalRepayable, deathBenefitContractValue, feesBreakdownHandler } = props;

  return (
    <div className={styles['feesBreakdown-container']}>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        flexGrow={1}
        sx={{
          padding: '0.4rem 1.3rem',
          backgroundColor: theme.palette.secondary.main,
          borderTopLeftRadius: '0.5rem',
          borderTopRightRadius: '0.5rem',
        }}
      >
        <Typography
          fontSize={'1.8rem'}
          fontWeight={500}
          lineHeight={'4rem'}
          color={theme.palette.common.white}
          fontStyle={'normal'}
        >
          Fees Breakdown
        </Typography>
        <Avatar
          src={CloseIcon}
          alt={'Close Icon'}
          sx={{ width: '2.5rem', height: '3.2rem', cursor: 'pointer' }}
          onClick={() => feesBreakdownHandler(false)}
        />
      </Stack>

      <Stack direction={'row'} padding={'0.5rem 1rem 0.5rem 1rem'} margin={'0.8rem 1.2rem 0 1.2rem'} borderBottom={'1px solid #F5F5F5'}>
        <Typography
          fontSize={'1.4rem'}
          lineHeight={'2.8rem'}
          fontWeight={700}
          color={'#008FD5'}>
          Loan fees & interest
        </Typography>
      </Stack>

      <Stack direction={'row'} justifyContent={'space-between'} padding={'0.5rem 1rem 0.5rem 1rem'} margin={'0 1.2rem'} borderBottom={'1px solid #F5F5F5'}>
        <Typography
          fontSize={'1.4rem'}
          lineHeight={'2.8rem'}
          fontWeight={400}>
          Initiation fee
        </Typography>
        <Typography
          fontSize={'1.4rem'}
          lineHeight={'2.8rem'}
          fontWeight={700}>
          {`R ${formatMoney(initiationFee ?? 0)}`}
        </Typography>
      </Stack>

      <Stack direction={'row'} justifyContent={'space-between'} padding={'0.5rem 1rem 0.5rem 1rem'} margin={'0 1.2rem'} borderBottom={'1px solid #F5F5F5'}>
        <Typography
          fontSize={'1.4rem'}
          lineHeight={'2.8rem'}
          fontWeight={400}>
          Service fees
        </Typography>
        <Typography
          fontSize={'1.4rem'}
          lineHeight={'2.8rem'}
          fontWeight={700}>
          {`R ${formatMoney(serviceFee ?? 0)}`}
        </Typography>
      </Stack>

      <Stack direction={'row'} justifyContent={'space-between'} padding={'0.5rem 1rem 0.5rem 1rem'} margin={'0 1.2rem'} borderBottom={'1px solid #F5F5F5'}>
        <Typography
          fontSize={'1.4rem'}
          lineHeight={'2.8rem'}
          fontWeight={400}>
          Total interest
        </Typography>
        <Typography
          fontSize={'1.4rem'}
          lineHeight={'2.8rem'}
          fontWeight={700}>
          {`R ${formatMoney(totalInterest ?? 0)}`}
        </Typography>
      </Stack>

      <Stack direction={'row'} padding={'0.5rem 1rem 0.5rem 1rem'} margin={'0.8rem 1.2rem 0 1.2rem'} borderBottom={'1px solid #F5F5F5'}>
        <Typography
          fontSize={'1.4rem'}
          lineHeight={'2.8rem'}
          fontWeight={700}
          color={'#008FD5'}>
          Insurance
        </Typography>
      </Stack>

      {!!deathBenefitContractValue &&
        <Stack direction={'row'} justifyContent={'space-between'} padding={'0.5rem 1rem 0.5rem 1rem'} margin={'0 1.2rem'} borderBottom={'1px solid #F5F5F5'}>
          <Typography
            fontSize={'1.4rem'}
            lineHeight={'2.8rem'}
            fontWeight={400}>
            Death Benefit
          </Typography>
          <Typography
            fontSize={'1.4rem'}
            lineHeight={'2.8rem'}
            fontWeight={700}>
            {`R ${formatMoney(deathBenefitContractValue ?? 0)}`}
          </Typography>
        </Stack>
      }

      <Stack direction={'row'} justifyContent={'space-between'} padding={'0.5rem 1rem 0.5rem 1rem'} margin={'0 1.2rem'} borderBottom={'1px solid #F5F5F5'}>
        <Typography
          fontSize={'1.4rem'}
          lineHeight={'2.8rem'}
          fontWeight={400}>
          Credit Life
        </Typography>
        <Typography
          fontSize={'1.4rem'}
          lineHeight={'2.8rem'}
          fontWeight={700}>
          {!deathBenefitContractValue ? `R ${formatMoney(insuranceFee ?? 0)}` : 'No additional cost'}
        </Typography>
      </Stack>

      <Stack direction={'row'} justifyContent={'space-between'} padding={'0.5rem 1rem 0.5rem 1rem'} margin={'0.8rem 1.2rem 0.8rem 1.2rem'}>
        <Typography
          fontSize={'1.4rem'}
          lineHeight={'2.8rem'}
          fontWeight={700}
          color={'#008FD5'}>
          Total to repay
        </Typography>
        <Typography
          fontSize={'1.4rem'}
          lineHeight={'2.8rem'}
          fontWeight={700}
          color={'#008FD5'}>
          {`R ${formatMoney(totalRepayable ?? 0)}`}
        </Typography>
      </Stack>

    </div>
  );
};

export default FeesBreakdown;
