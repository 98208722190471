import { Box, Container, Grid, Typography } from '@mui/material';
import { theme } from '../../../../../theme/Theme';
import { Form, useForm } from '../../../../../components/Form/Form';
import { creditLifePlusBeneficiarySchema } from '../../../../../schemas/Schemas';
import FormInputLabel from '../../../../../components/Form/FormInputLabel';
import FormInputWrapper from '../../../../../components/Form/FormInputWrapper';
import InputFormController from '../../../../../components/MuiInput/FormControllers/InputFormController';
import { beneficiaryRelationshipMappings } from '../../../../../models/SelectOptions';
import SelectFormController from '../../../../../components/MuiInput/FormControllers/SelectFormController';
import TextButton from '../../../../../components/Buttons/TextButton';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import { FULL_COLUMN_SIZE } from '../../../../../utils/GridColumnSizeDefinitions';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWizardOutletContext } from '../../../Wizard';
import { BeneficiaryRelationshipEnum } from '../../../../../models/enums/BeneficiaryRelationshipEnum';
import { useEffect } from 'react';
import { formatMoney } from '../../../../../utils/Helpers/SliderHelperFunctions';

export const CreditLifePlusBeneficiaryDetailsKey = 'creditLifePlusBeneficiaryDetails';

interface CreditLifePlusBeneficiaryFormData {
  firstname: string;
  lastname: string;
  beneficiaryRelationship: BeneficiaryRelationshipEnum;
  cellphoneNumber: string;
}

const CreditLifePlusBeneficiary: React.FunctionComponent = () => {
  const { goToProcessing } = useWizardOutletContext();
  const navigate = useNavigate();
  const location = useLocation();
  //premium gets passed through the state on navigate
  const deathBenefitTotalCover = location.state['deathBenefitTotalCover'] ?? 0;

  const onSubmit = (data: CreditLifePlusBeneficiaryFormData) => {
    localStorage.setItem(CreditLifePlusBeneficiaryDetailsKey, JSON.stringify(data));
    goToProcessing();
  };

  const creditLifePlusBeneficiaryForm = useForm({
    criteriaMode: 'all',
    mode: 'onBlur',
    schema: creditLifePlusBeneficiarySchema,
  });

  useEffect(() => {
    const beneficiaryDetailsJson = localStorage.getItem(CreditLifePlusBeneficiaryDetailsKey);

    if (beneficiaryDetailsJson === null) {
      creditLifePlusBeneficiaryForm.setValue('beneficiaryRelationship', BeneficiaryRelationshipEnum.Unspecified);
      return;
    }

    const beneficiaryDetails = JSON.parse(beneficiaryDetailsJson);
    creditLifePlusBeneficiaryForm.setValue('firstname', beneficiaryDetails.firstname);
    creditLifePlusBeneficiaryForm.setValue('lastname', beneficiaryDetails.lastname);
    creditLifePlusBeneficiaryForm.setValue('cellphoneNumber', beneficiaryDetails.cellphoneNumber);
    creditLifePlusBeneficiaryForm.setValue('beneficiaryRelationship', beneficiaryDetails.beneficiaryRelationship);
  }, [creditLifePlusBeneficiaryForm]);

  return (
    <Container disableGutters sx={{ marginTop: { xs: theme.spacing(4), md: theme.spacing(7) }, padding: '0 1.6rem' }}>
      <Typography
        variant="h1"
        fontSize={{ xs: '2.4rem', md: '3.2rem' }}
        fontWeight={400}
        lineHeight={{ xs: '3.2rem', md: '3.5rem' }}
        letterSpacing={{ xs: 'initial', md: '-0.1rem' }}
      >
        Add your beneficiary
      </Typography>

      <Box marginTop={{ xs: '2rem', md: '2.4rem' }} marginBottom={{ xs: '2rem', md: '3rem' }}>
        <Typography fontSize={'1.5rem'} fontWeight={300} lineHeight={'2rem'}>
          Please nominate one beneficiary for your Death Benefit value of{' '}
          <Typography component={'span'} fontSize={'1.5rem'} fontWeight={600} lineHeight={'2rem'}>
            {`R ${formatMoney(deathBenefitTotalCover)}.`}
          </Typography>{' '}
        </Typography>
      </Box>

      <Form form={creditLifePlusBeneficiaryForm} onSubmit={(data: CreditLifePlusBeneficiaryFormData) => onSubmit(data)}>
        <Grid container>
          <FormInputLabel>
            <Typography variant="body1" fontSize="1.5rem" fontWeight={300} lineHeight={'2.8rem'}>
              Beneficiary name
            </Typography>
          </FormInputLabel>
          <FormInputWrapper>
            <InputFormController
              name="firstname"
              label="First name"
              control={creditLifePlusBeneficiaryForm.control}
              placeholder="First name"
              register={creditLifePlusBeneficiaryForm.register}
            />
          </FormInputWrapper>

          <FormInputLabel>
            <Typography variant="body1" fontSize="1.5rem" fontWeight={300} lineHeight={'2.8rem'}>
              Beneficiary last name
            </Typography>
          </FormInputLabel>
          <FormInputWrapper>
            <InputFormController
              name="lastname"
              label="Last name"
              placeholder="Last name"
              register={creditLifePlusBeneficiaryForm.register}
              control={creditLifePlusBeneficiaryForm.control}
            />
          </FormInputWrapper>

          <FormInputLabel>
            <Typography variant="body1" fontSize="1.5rem" fontWeight={300} lineHeight={'2.8rem'}>
              Beneficiary relationship
            </Typography>
          </FormInputLabel>
          <FormInputWrapper>
            <SelectFormController
              name={'beneficiaryRelationship'}
              label="Beneficiary Relationship"
              menuItems={beneficiaryRelationshipMappings}
              register={creditLifePlusBeneficiaryForm.register}
              control={creditLifePlusBeneficiaryForm.control}
            />
          </FormInputWrapper>

          <FormInputLabel>
            <Typography variant="body1" fontSize="1.5rem" fontWeight={300} lineHeight={'2.8rem'}>
              Beneficiary cellphone number
            </Typography>
          </FormInputLabel>
          <FormInputWrapper>
            <InputFormController
              name="cellphoneNumber"
              label="Cellphone number"
              placeholder="Cellphone number"
              register={creditLifePlusBeneficiaryForm.register}
              control={creditLifePlusBeneficiaryForm.control}
            />
          </FormInputWrapper>
        </Grid>

        <Box sx={{ padding: { xs: '2.4rem 0', md: '7.2rem 0 5rem 0' } }}>
          <Grid container alignItems="center">
            <Grid
              item
              xs={FULL_COLUMN_SIZE}
              display={'flex'}
              flexDirection={{ xs: 'column-reverse', md: 'row' }}
              justifyContent={{
                xs: 'flex-end',
                md: 'space-between',
              }}
              gap={'2.8rem'}
            >
              <TextButton onClick={() => navigate(-1)} removePadding>
                Back
              </TextButton>
              <PrimaryButton type="submit">Next</PrimaryButton>
            </Grid>
          </Grid>
        </Box>
      </Form>
    </Container>
  );
};

export default CreditLifePlusBeneficiary;
