/* eslint  @typescript-eslint/no-non-null-assertion: 0 */
import React, { useEffect, useState } from 'react';
import { Avatar, Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import styles from './Card.module.scss';
import lightbulb from '../../../../../assets/img/svg/lightbulb-big.svg';
import { IOpenLoanDetailsResponse, PaymentMethodEnums } from '../../../../../clients/LoanClient';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import InputFormController from '../../../../../components/MuiInput/FormControllers/InputFormController';
import { Form, useForm } from '../../../../../components/Form/Form';
import { repayInstantEftSchema } from '../../../../../schemas/Schemas';
import useLoanClient from '../../../../../hooks/loan/Client';
import { useSnackBar } from '../../../../../contexts/SnackBarContext';
import Loading from '../../../../../components/Loading/Loading';
import { formatMoney } from '../../../../../utils/Helpers/SliderHelperFunctions';
import { formatDateWithDefault } from '../../../../../utils/Helpers/DateHelpers';
import chevronLeft from '../../../../../assets/img/svg/Chevron.svg';

interface CardProps {
    openLoanResponse: IOpenLoanDetailsResponse;
}

const Card: React.FunctionComponent<CardProps> = ({ openLoanResponse }: CardProps) => {
    const { currentBalance, collectionDate, collectionInstalment } = openLoanResponse.loanData || {};
    const { loanId } = openLoanResponse.loanData || {};
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const client = useLoanClient();
    const { displaySnackBar } = useSnackBar();
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();

    const form = useForm({
        criteriaMode: 'all',
        mode: 'onBlur',
        schema: repayInstantEftSchema(currentBalance ?? 0),
    });

    useEffect(() => {
        form.setValue('repayAmount', currentBalance?.toString() ?? '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* eslint-disable  @typescript-eslint/no-explicit-any*/
    const handlePaymentSession = async (data: any) => {
        try {
            setLoading(true);
            const response = await client.getPaymentRedirectUrl(loanId ?? '', data.repayAmount, PaymentMethodEnums.Card);
            if (response?.sessionUrl) {
                window.location.replace(response.sessionUrl);
            } else {
                displaySnackBar('We are currently experiencing technical difficulties. Please try again later', 'error');
            }
        } catch (error) {
            displaySnackBar('We are currently experiencing technical difficulties. Please try again later', 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles['card-container']}>
            <Box onClick={() => navigate(-1)} style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>
                <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} marginBottom={{ xs: '2rem', md: '2.4rem' }}>
                    <Avatar
                        src={chevronLeft}
                        alt="Back button icon"
                        sx={{
                            width: '1.4rem',
                            height: '1.4rem',
                            marginRight: '0.4rem',
                        }}
                    />
                    <Typography
                        display={'inline'}
                        fontSize={'1.5rem'}
                        fontWeight={'300'}
                        lineHeight={{ xs: '2.2rem' }}
                        letterSpacing={{ xs: '-.0.04rem' }}
                    >
                        Back
                    </Typography>
                </Stack>
            </Box>
            <Typography
                variant="h1"
                fontSize={{ xs: '2.4rem', sm: '3.2rem' }}
                fontWeight={400}
                lineHeight={'3.2rem'}
                letterSpacing={{ xs: '-0.072rem', sm: '-0.05rem' }}
            >
                Repay using card
            </Typography>
            <div className={styles['card-hint-container']}>
                <img src={lightbulb} aria-label="Lightbulb Icon" className={styles['card-hint-icon']} />
                <Typography fontWeight={300} fontSize={'1.5rem'} component={'span'}>
                    Payments made within
                    <Typography fontWeight={500} component={'span'}>
                        {" "}5 working days{" "}
                    </Typography>
                    of your next debit order collection may not be processed in time. Once payment is made, this can take
                    <Typography fontWeight={500} component={'span'}>
                        {" "}2-3 days to reflect{" "}
                    </Typography>
                    on your loan account.
                </Typography>
            </div>

            <Typography
                variant="body1"
                fontSize={'3.2rem'}
                fontWeight={600}
                lineHeight={'2.2rem'}
                letterSpacing={'-0.04em'}
                textAlign={'center'}
                marginTop={isMobile ? '2.4rem' : '2.8rem'}
                color={theme.palette.primary.dark}
            >
                R {formatMoney(currentBalance ?? 0)}
            </Typography>
            <Stack
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                marginTop={'1.1rem'}
                marginBottom={'2rem'}
            >
                <Typography variant="body1" fontSize={'1.4rem'} fontWeight={400} lineHeight={'1.7rem'}>
                    Settlement balance
                </Typography>
                <Typography variant="body1" fontSize={'1.4rem'} fontWeight={300} lineHeight={'1.7rem'}>
                    {`${formatDateWithDefault(undefined, 'dd MMM yyyy')}`}
                </Typography>
            </Stack>


            <div className={styles['card-table-first-row']}>
                <div>New debit collection date</div>
                <div>{formatDateWithDefault(collectionDate, 'do MMM yyyy')}</div>
            </div>
            <div className={styles['card-table']}>
                <div>Your next debit amount</div>
                <div>R {formatMoney(collectionInstalment ?? 0)}</div>
            </div>

            <Form form={form} onSubmit={(data) => handlePaymentSession(data)}>
                <Stack direction={{ xs: 'column', sm: 'row' }} paddingTop={'3.6rem'} spacing={{ xs: '1.2rem', sm: '6.6rem' }} alignItems={'flex-start'} >
                    <Typography fontWeight={300} whiteSpace={'nowrap'} paddingTop={{ xs: '0', sm: '1.5rem' }}>
                        How much do you want to repay?
                    </Typography>
                    <InputFormController
                        currencyField
                        name="repayAmount"
                        label="repayAmount"
                        placeholder="Amount"
                        register={form.register}
                        control={form.control}
                    />
                </Stack>
                {isLoading ? (
                    <Loading />
                ) : (
                    <Stack
                        direction={isMobile ? 'column' : 'row-reverse'}
                        justify-content={isMobile ? 'center' : 'right'}
                        padding="3.4rem 0rem 2rem 0rem"
                    >
                        <PrimaryButton type="submit">Confirm</PrimaryButton>
                    </Stack>
                )}
            </Form>
        </div>
    );
};

export default Card;
