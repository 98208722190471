import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import MaintenanceIcon from '../../../../assets/img/maintenance-icon.png';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';

interface AdBlockPopupProps {
  open: boolean;
  handleDialogClose: () => void;
  navigateToDocUploadPage: () => void;
}

export const OpenBankingRedirectUrlRequestErrorDialog = ({open, handleDialogClose, navigateToDocUploadPage} : AdBlockPopupProps) => {

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      sx={{
        top: {xs: -70, md: -500},
      }}
      open={open}
      onClose={handleDialogClose}
      aria-describedby="alert-dialog-description"
    >
      <IconButton
        aria-label="close"
        onClick={handleDialogClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>

        <Box textAlign={"center"}>
          <img src={MaintenanceIcon} alt="Maintenance Icon" />
        </Box>

        <Box width="100%" textAlign="center">
          <Typography variant="h2">Oops!</Typography>
        </Box>

        <Typography variant="body1" marginTop={2}>
          We couldn’t successfully connect to TruID. Please upload your documents.
        </Typography>

        <Box textAlign={"center"} paddingTop={2}>
          <PrimaryButton fullWidth={true} onClick={navigateToDocUploadPage}>
            <Typography variant="body1" fontSize="1.5rem" fontWeight={300}>
              Upload documents
            </Typography>
          </PrimaryButton>
        </Box>

      </DialogContent>
    </Dialog>
  );
}
