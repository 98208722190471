import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface ScrollToTopProps {
  children?: React.ReactNode;
}
/* 
This is just a quick fix we should actually use react router dom built in methods but it requires a refactor of the way we defined the routes in app.tsx

We should be using ScrollRestoration https://reactrouter.com/en/main/components/scroll-restoration 
But it only works with data routing which would require refactor see https://reactrouter.com/en/6.24.1/routers/create-browser-router 

*/
const ScrollToTop: React.FC<ScrollToTopProps> = ({ children }) => {
  const { pathname } = useLocation();

  // To handle navigation from href and navigate method
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // To handle back/forward navigation on browser button
  useEffect(() => {
    const handlePopState = () => {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return <>{children}</>;
};

export default ScrollToTop;
