import { Box, Container, Typography } from '@mui/material';
import VerifiedIncomeImage from '../../../../assets/img/verified-income-image.png';
import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/system';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import { navigateToDocumentUpload } from '../../../../utils/Helpers/NavigationHelper';
import { useTracking } from '../../../../Tracking/TrackingContext';
import { useLocation, useNavigate } from 'react-router-dom';
import SecondaryButton from '../../../../components/Buttons/SecondaryButton';
import QuickestIvOptionIcon from '../../../../assets/img/iv-quickest-option-icon.png';
import styles from './IvOptions.module.scss';
import { OpenBankingRedirectUrlRequestErrorDialog } from './OpenBankingRedirectUrlRequestErrorDialog';
import useLoanClient from '../../../../hooks/loan/Client';
import { RedirectToTruId } from './Utilities/OpenBankingRedirectUrlUtils';
import Loading from '../../../../components/Loading/Loading';

export const IvOptions = () => {
  const loanClient = useLoanClient();
  const navigate = useNavigate();
  const location = useLocation();
  const loanId = location && location.state ? location.state["loanId"] ?? "" : "";
  const { isExistingCustomer } = useTracking();
  const errorDialogOpened = location && location.state ? location.state["errorDialogOpened"] ?? false : false;
  const [openErrorDialogPopup, setOpenErrorDialogPopup] = useState(errorDialogOpened);
  const [isLoading, setIsLoading] = useState(false);

  const navigateToDocUploadPage = () => {
    navigateToDocumentUpload(navigate, loanId, isExistingCustomer);
  };

  const handleRedirect = async () => {
    await RedirectToTruId(loanClient, loanId, setOpenErrorDialogPopup, setIsLoading);
  };

  const handleErrorDialogPopupClose = () => {
    setOpenErrorDialogPopup(false);
  };

  // When this component is cached, we need to set isLoading to false when we go back to it
  // otherwise, it could be set to true and cause an infinite loading indicator.
  // This is because we keep isLoading to true when redirecting to truID to keep the
  // 'connect to bank' button disabled while redirecting.
  useEffect(() => {
    const handlePageShow = () => {
      setIsLoading(false);
    };

    window.addEventListener('pageshow', handlePageShow);

    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, [setIsLoading]);

  return (
    <>
      <OpenBankingRedirectUrlRequestErrorDialog
        handleDialogClose={handleErrorDialogPopupClose}
        navigateToDocUploadPage={navigateToDocUploadPage}
        open={openErrorDialogPopup} />

      <Container className={styles['container']}>
        <Box className={styles['header-wrapper']}>
          <Box textAlign={"center"}>
            <img className={styles["verified-income-image"]} src={VerifiedIncomeImage} alt="Verfied Income Image" />
          </Box>

          <Stack flexGrow={1} alignItems="left" padding={"1rem 0 0 1rem"}>
            <Typography variant="h2">Connect to your bank</Typography>

            <Typography variant="body1" marginTop={2}>Get your cash <b>quicker!</b></Typography>

            <Typography variant="body1" marginTop={2}>
              To complete your loan application, we need to verify your income. With our secure bank connection, this is
              easier and faster than ever, with no need to upload documents.
              Simply connect to your bank account, and we will do the rest.
            </Typography>
          </Stack>
        </Box>

        {isLoading && <Loading />}

        <Stack
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: { xs: 'column', xmd: 'row-reverse', md: 'row-reverse' },
            justifyContent: { xs: 'initial', md: 'space-between' },
            marginTop: { xs: '50px', md: '100px' },
            marginBottom: {xs: '30px'},
          }}
        >
          <Box className={styles['connect-to-bank-div']}>
            <img src={QuickestIvOptionIcon} className={styles['iv-quickest-option-icon']} alt="Quickest" />

            <PrimaryButton onClick={handleRedirect} disabled={isLoading}>
              <Typography variant="body1" fontSize="1.5rem" fontWeight={300}>
                Connect to bank
              </Typography>
            </PrimaryButton>
          </Box>

          <SecondaryButton onClick={navigateToDocUploadPage} disabled={isLoading}>
            <Typography variant="body1" fontSize="1.5rem" fontWeight={300}>
              Upload documents
            </Typography>
          </SecondaryButton>

        </Stack>
      </Container>
    </>
  )
}
