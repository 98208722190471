import { Container, Stack } from '@mui/material';
import React, { ForwardedRef, forwardRef } from 'react';

type Props = {
  children?: React.ReactNode;
};

const HomeContainer = forwardRef(function HomeContainer(props: Props, ref: ForwardedRef<HTMLDivElement>) {
  const { children } = props;
  return (
    <Container
      ref={ref}
      sx={{
        marginTop: { xs: 5, md: 5 },
        marginBottom: { xs: 5, md: 10 },
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'center',
      }}
    >
      <Stack
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          width: { md: '1000px', xs: '100%' },
          justifyContent: 'space-between',
        }}
      >
        {children}
      </Stack>
    </Container>
  );
});

export default HomeContainer;
