import { Grid, Stack } from '@mui/material';
import React, { useState } from 'react';
import { ConfirmMobileVerificationData } from '../../../pages/Wizard/JourneyPages/Otp';
import { FULL_COLUMN_SIZE, THIRD_COLUMN_SIZE } from '../../../utils/GridColumnSizeDefinitions';
import { Form } from '../Form';
import FormInputLabel from '../FormInputLabel';
import FormInputWrapper from '../FormInputWrapper';
import InputFormController from '../../MuiInput/FormControllers/InputFormController';
import PrimaryButton from '../../Buttons/PrimaryButton';
import { useAppSettings } from '../../../contexts/AppSettingsContext';
import OtpTimer from '../../../components/OtpTimer/OtpTimer';
import styles from '../Form.module.scss';
import ButtonLoadingIndicator from '../../Loading/ButtonLoadingIndicator';
import { TurnstileInstance } from '@marsidev/react-turnstile';
import TurnstileSize from '../../../utils/Turnstile';
import { CFTurnstile } from '../../CloudflareTurnstile/CFTurnstile';

interface VerifyOtpProps {
  isMobile: boolean;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  mobileNumberForm: any;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  otpForm: any;
  verifyOtp: (data: ConfirmMobileVerificationData) => {};
  resendOtp: () => {};
  submitLoading: boolean;
  turnstileWidgetRef: React.MutableRefObject<TurnstileInstance | null>;
  cfTurnstileToken: string | null;
  turnstileSize: TurnstileSize;
  setTurnstileSize: React.Dispatch<React.SetStateAction<TurnstileSize>>;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
}

const VerifyOtp: React.FC<VerifyOtpProps> = (props) => {
  const { isMobile, turnstileWidgetRef, turnstileSize, cfTurnstileToken, setTurnstileSize, setToken } = props;
  const appSettings = useAppSettings();
  const cfTurnstileDisabled = appSettings.cfTurnstileConfig.cfTurnstileDisabled;
  const [countDown, setCountDown] = useState<{ minute: number; seconds: number }>({
    minute: 1,
    seconds: 0,
  });

  const handleOtpResend = () => {
    setCountDown((prev) => {
      return {
        ...prev,
        seconds: 59,
      };
    });

    props.resendOtp();
  };

  const submitOtpFormContent = (
    <Form
      className={styles['form-layout']}
      form={props.otpForm}
      onSubmit={(data: ConfirmMobileVerificationData) => props.verifyOtp(data)}
    >
      <Grid container>
        <FormInputLabel>Cellphone number</FormInputLabel>
        <FormInputWrapper>
          <InputFormController
            name="cellphoneNumber"
            label="Cell phone number"
            placeholder="Cell phone"
            register={props.otpForm.register}
            control={props.otpForm.control}
          />
        </FormInputWrapper>
      </Grid>
      <Grid container>
        <FormInputLabel>Please enter the 4 digit OTP</FormInputLabel>
        <FormInputWrapper>
          <InputFormController
            name="otp"
            label="OTP"
            register={props.otpForm.register}
            control={props.otpForm.control}
          />
        </FormInputWrapper>
      </Grid>

      {!cfTurnstileDisabled && !isMobile && (
        <Stack alignItems={'flex-end'} gap={1} marginBottom={'0.6rem'}>
          <CFTurnstile
            ref={turnstileWidgetRef}
            siteKey={appSettings.cfTurnstileConfig.cfTurnstileOtpWidgetSiteKey}
            component="VerifyOtp"
            action="Otp"
            turnstileSize={turnstileSize}
            setTurnstileSize={setTurnstileSize}
            setToken={setToken}
          />
        </Stack>
      )}

      <Grid container paddingTop={{ xs: 0, md: '1rem' }} marginBottom={'5rem'}>
        <Grid item sm={THIRD_COLUMN_SIZE}></Grid>
        <Grid item xs={FULL_COLUMN_SIZE} sm={THIRD_COLUMN_SIZE} display="flex" justifyContent={'start'}>
          {countDown.seconds > 0 || countDown.minute > 0 ? (
            <OtpTimer countDown={countDown} setCountDown={setCountDown} />
          ) : (
            <button
              type="button"
              className={`text-button blue`}
              disabled={props.otpForm.getFieldState('cellphoneNumber').invalid}
              onClick={handleOtpResend}
            >
              Resend OTP
            </button>
          )}
        </Grid>

        <Grid
          item
          xs={FULL_COLUMN_SIZE}
          sm={THIRD_COLUMN_SIZE}
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          alignItems={{
            xs: 'center',
            sm: 'initial',
          }}
          justifyContent={{
            xs: 'center',
            sm: 'flex-end',
          }}
        >
          {!cfTurnstileDisabled && isMobile && (
            <Stack alignItems={'center'} gap={1} marginBottom={'2rem'}>
              <CFTurnstile
                ref={turnstileWidgetRef}
                siteKey={appSettings.cfTurnstileConfig.cfTurnstileOtpWidgetSiteKey}
                component="VerifyOtp"
                action="Otp"
                turnstileSize={turnstileSize}
                setTurnstileSize={setTurnstileSize}
                setToken={setToken}
              />
            </Stack>
          )}
          {props.submitLoading ? (
            <ButtonLoadingIndicator />
          ) : (
            <PrimaryButton
              type="submit"
              disabled={!props.otpForm.formState.isValid || (!cfTurnstileDisabled && cfTurnstileToken === null)}
            >
              Confirm OTP
            </PrimaryButton>
          )}
        </Grid>
      </Grid>
    </Form>
  );

  return submitOtpFormContent;
};

export default VerifyOtp;
