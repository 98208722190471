import { Box, Grid, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import referred from '../../../../assets/img/svg/referred-blue.svg';
import { ITransaction } from '../../../../clients/LoanClient';
import Loading from '../../../../components/Loading/Loading';
import useLoanClient from '../../../../hooks/loan/Client';
import { formatMoney } from '../../../../utils/Helpers/SliderHelperFunctions';

interface TransactionHistoryProps {
  loanId: string | undefined;
}

const Transactions: React.FunctionComponent<TransactionHistoryProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const loanClient = useLoanClient();
  const [transactions, setTransactions] = useState<ITransaction[]>([]);

  const formatDate = (dateString: Date): string => {
    const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const groupByYear = (transactions: ITransaction[]): Record<string, ITransaction[]> => {
    return transactions.reduce((acc, transaction) => {
      const date = new Date(transaction.transactionDateTime ?? '');
      const year = date.getFullYear().toString();
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(transaction);
      return acc;
    }, {} as Record<string, ITransaction[]>);
  };

  const formatCurrency = (amount: number) => {
    const isNegative = amount < 0;
    const absoluteAmount = formatMoney(Math.abs(amount));
    return `${isNegative ? '-' : ''}R ${absoluteAmount}`;
  };

  const fetchTransactions = useCallback(async () => {
    if (!props.loanId) {
      console.error('loanId is undefined');
      return;
    }
    try {
      setIsLoading(true);
      const response = await loanClient.getTransactionById(props.loanId);
      const transactionList: ITransaction[] =
        response.transactionList?.map((transaction: ITransaction) => ({
          transactionAmount: transaction.transactionAmount ?? 0,
          transactionDescription: transaction.transactionDescription ?? '',
          transactionDateTime: transaction.transactionDateTime ?? new Date(),
        })) ?? [];
      setTransactions(transactionList);
    } catch (error) {
      console.error('Failed to fetch transactions', error);
    } finally {
      setIsLoading(false);
    }
  }, [props.loanId, loanClient]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  const groupedTransactions = groupByYear(transactions);
  const noTransactionHistory = transactions.length == 0;

  const transactionHistory = (
    <>
      {Object.keys(groupedTransactions).map((year) => (
        <Grid container key={year}>
          <Grid item xs={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Typography color={theme.palette.primary.dark} marginTop={'2rem'} fontSize={'1.4rem'} fontWeight={400}>
              {year}
            </Typography>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={3}></Grid>
          <Box sx={{ borderBottom: '1px solid #E4E4E4', width: '100%' }}></Box>
          {groupedTransactions[year].map((transaction, index) => (
            <Grid
              container
              key={index}
              sx={{
                display: 'flex',
                backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F5F5F5',
                height: '70px',
              }}
            >
              <Grid item xs={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                {transaction.transactionDateTime &&
                  formatDate(transaction.transactionDateTime)
                    .split(' ')
                    .reverse()
                    .map((part, index) => {
                      if (index % 2 === 0)
                        return (
                          <Typography key={index} fontSize="1.6rem" fontWeight="600" textAlign={'center'}>
                            {part}
                          </Typography>
                        );
                      else
                        return (
                          <Typography key={index} fontSize="1.4rem" fontWeight="400" textAlign={'center'}>
                            {part}
                          </Typography>
                        );
                    })}
              </Grid>

              <Grid item xs={6} display="flex" alignItems="center">
                <Typography
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                  fontSize="1.4rem"
                  fontWeight="400"
                  textAlign="left"
                >
                  {transaction.transactionDescription}
                </Typography>
              </Grid>
              <Grid item xs={3} display="flex" alignItems="center" justifyContent="flex-end">
                <Typography fontSize="1.4rem" fontWeight="700" letterSpacing="-0.1rem">
                  {formatCurrency(transaction.transactionAmount ?? 0)}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      ))}
    </>
  );

  const transactionHistoryEmpty = (
    <>
      <Box
        height={'30vh'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        gap={2}
      >
        <img width={'60px'} src={referred} />
        <Typography fontSize={'20px'}>No Transactions available</Typography>
      </Box>
    </>
  );

  return (
    <>
      {isLoading ? (
        <Box alignItems={'center'} justifyContent={'center'} width={'100%'}>
          <Loading />
        </Box>
      ) : (
        <>
          {!noTransactionHistory && (
            <Typography
              variant="h1"
              fontSize={{ xs: '2.4rem', sm: '3.2rem' }}
              fontWeight={400}
              lineHeight={'3.2rem'}
              letterSpacing={'-0.03rem'}
              marginLeft={2}
            >
              Transaction history
            </Typography>
          )}
          <Box
            sx={{
              marginTop: '10px',
              height: '400px',
              overflow: 'auto',
              borderColor: theme.palette.grey['200'],
            }}
          >
            {noTransactionHistory ? transactionHistoryEmpty : transactionHistory}
          </Box>
        </>
      )}
    </>
  );
};

export default Transactions;
