import { Avatar, Box, Link, Stack, Typography, styled } from '@mui/material';
import { formatMoney, formatMoneyWithoutRounding } from '../../../../../../src/utils/Helpers/SliderHelperFunctions';
import CloseIcon from '../../../../../assets/img/Close.png';
import { GetInstallmentLoanQuotationResponse, GetShortTermLoanQuotationResponse } from '../../../../../clients/LoanClient';
import { theme } from '../../../../../theme/Theme';

import { useState } from 'react';
import InfoIcon from '../../../../../assets/img/info-icon.png';
import { useAppSettings } from '../../../../../contexts/AppSettingsContext';

const FeeRowItem = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0.2rem 0 0.7rem 0',
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
}));

interface CreditLifePlusFeeBreakdownPopupProps {
  open: boolean;
  handleClose: () => void;
  deathBenefitOptedIn: boolean;
  creditLifeSaving?: number;
  getQuotationResponse: GetInstallmentLoanQuotationResponse | GetShortTermLoanQuotationResponse | undefined;

  setShowQuotationBreakdownPopup: (open: boolean) => void;
}

const CreditLifePlusFeeBreakdownPopup: React.FunctionComponent<CreditLifePlusFeeBreakdownPopupProps> = ({
  open,
  handleClose,
  deathBenefitOptedIn,
  getQuotationResponse,
  creditLifeSaving,
  setShowQuotationBreakdownPopup
}: CreditLifePlusFeeBreakdownPopupProps) => {
  const [showCreditLife, setShowCreditLife] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false);
  const { insurance } = useAppSettings();

  return (
    <Box
      display={open ? 'block' : 'none'}
      position={'absolute'}
      sx={{
        top: { xs: '36rem', md: showMore ? '9rem' : '23rem' },
        left: { xs: '1.2rem', md: 0 },
        right: { xs: 0, md: 0 },
        width: { xs: 'calc(100vw - 2.4rem)', sm: '35.7rem' },
        borderRadius: '0.5rem',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        transition: 'all 0.25s ease-in-out',
        zIndex: '1',
        marginLeft: { xs: 'unset', md: 'auto' },
        marginRight: { xs: 'unset', md: 'auto' },
      }}
    >
      {
        showCreditLife ?
          <>
            <Stack
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              flexGrow={1}
              sx={{
                padding: '0.4rem 1.3rem',
                backgroundColor: theme.palette.secondary.main,
                borderTopLeftRadius: '0.5rem',
                borderTopRightRadius: '0.5rem',
              }}
            >
              <Typography
                fontSize={'1.8rem'}
                fontWeight={500}
                lineHeight={'4rem'}
                color={theme.palette.common.white}
                fontStyle={'normal'}
              >
                Loan repayment details
              </Typography>
              <Avatar
                src={CloseIcon}
                alt={'Close Icon'}
                sx={{ width: '2.5rem', height: '3.2rem', cursor: 'pointer' }}
                onClick={() => {
                  handleClose();
                  setShowCreditLife(false);
                  setShowMore(false);
                }}
              />
            </Stack>

            <Box sx={{ backgroundColor: theme.palette.common.white, padding: '1.6rem', borderRadius: '0.5rem' }}>
              <Typography fontSize={'1.3rem'} fontWeight={300} lineHeight={'1.5rem'} fontStyle={'normal'}>
                In terms of the National Credit Act, we may mandate that a credit life policy which covers the outstanding debt in the event of your death, retrenchment and permanent or temporary disability will be in place with this loan.
                As a service to all of our valued clients, we offer you a Credit Life policy, payable by yourself.
                Please note you also have this freedom of choice to make available an existing policy that you already have in place, or to enter into the new Credit Life policy offered to you.
                If you have an existing policy in place, please contact us.
              </Typography>
              <br />
              <Typography fontSize={'1.3rem'} fontWeight={300} lineHeight={'1.5rem'} fontStyle={'normal'}>
                If you already have or would like to use a different Credit Life provider, please contact our call centre at{' '}
                <Link
                  href={`tel:${insurance.insuranceContactNumber.replaceAll(' ', '')}`}
                  sx={{ textDecoration: 'none' }}
                >
                  {insurance.insuranceContactNumber}
                </Link>
                {' '}or send an email to{' '}
                <Link href={`mailto:${insurance.insuranceContactEmail}`} sx={{ textDecoration: 'underline' }}>
                  {insurance.insuranceContactEmail}
                </Link>
                {' '}and kindly provide us with the relevant details.
              </Typography>
              <br />
              {!showMore ? (
                <Typography
                  fontSize={'1.3rem'}
                  fontWeight={400}
                  lineHeight={'1.5rem'}
                  fontStyle={'normal'}
                  color={theme.palette.primary.main}
                  onClick={() => setShowMore(true)}
                  sx={{ cursor: 'pointer' }}
                >
                  Read more
                </Typography>
              ) : (
                <>
                  <Typography fontSize={'1.3rem'} fontWeight={300} lineHeight={'1.5rem'} fontStyle={'normal'}>
                    Wonga is a registered Juristic Representative of Groups Are Us Pty (Ltd) (An Authorized Financial Services Provider, Registration Number: 2020/553498/07, FSP No. 45735).
                    The Death Benefit and Credit Life is underwritten by Sanlam Developing Markets Limited, an authorised financial services provider and licensed Insurer (Registration number 1911/003818/06, (FSP11230).
                    <br />
                    <br />
                    <Typography
                      component={'span'}
                      fontSize={'1.3rem'}
                      fontWeight={300}
                      lineHeight={'1.5rem'}
                      fontStyle={'normal'}
                      color={theme.palette.primary.main}
                      onClick={() => setShowMore(false)}
                      sx={{ cursor: 'pointer' }}
                    >
                      Read less
                    </Typography>
                  </Typography>
                </>
              )}
            </Box>
          </>
          :
          <>
            <Stack
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              flexGrow={1}
              sx={{
                padding: '0.4rem 1.3rem',
                backgroundColor: theme.palette.secondary.main,
                borderTopLeftRadius: '0.5rem',
                borderTopRightRadius: '0.5rem',
              }}
            >
              <Typography
                fontSize={'1.8rem'}
                fontWeight={500}
                lineHeight={'4rem'}
                color={theme.palette.common.white}
                fontStyle={'normal'}
              >
                Loan repayment details
              </Typography>
              <Avatar
                src={CloseIcon}
                alt={'Close Icon'}
                sx={{ width: '2.5rem', height: '3.2rem', cursor: 'pointer' }}
                onClick={handleClose}
              />
            </Stack>

            <Box
              sx={{
                backgroundColor: theme.palette.common.white,
                padding: '0 1.5rem',
                borderBottomLeftRadius: '0.5rem',
                borderBottomRightRadius: '0.5rem',
              }}
            >
              <FeeRowItem>
                <Typography fontSize={'1.4rem'} fontWeight={400} lineHeight={'2.8rem'}>
                  Death Benefit cover premium
                </Typography>
                <Typography fontSize={'1.4rem'} fontWeight={700} lineHeight={'2.8rem'}>
                  {`R ${formatMoney(getQuotationResponse?.deathBenefitPremium ?? 0)}`}
                </Typography>
              </FeeRowItem>
              <FeeRowItem>

                <Typography fontSize={'1.4rem'} fontWeight={400} lineHeight={'2.8rem'}>
                  Credit Life cover
                </Typography>
                <Typography fontSize={'1.4rem'} fontWeight={700} lineHeight={'2.8rem'} display={"flex"} flexDirection={'column'} alignItems={"end"}>
                  {deathBenefitOptedIn ? 'No charge' : `R ${formatMoney(getQuotationResponse?.insuranceFee ?? 0)}`}
                  {!!creditLifeSaving &&
                    <Typography fontSize={'1.2rem'} fontWeight={300} lineHeight={'2.2rem'} fontStyle={'italic'} color={'#7AC206'}>
                      {`You save R ${formatMoneyWithoutRounding(creditLifeSaving ?? 0, 0)}!`}
                    </Typography>
                  }
                </Typography>
              </FeeRowItem>
              <FeeRowItem>
                <Typography fontSize={'1.4rem'} fontWeight={400} lineHeight={'2.8rem'}>
                  Start date
                </Typography>
                <Typography fontSize={'1.4rem'} fontWeight={700} lineHeight={'2.8rem'}>
                  Upon loan cash payout
                </Typography>
              </FeeRowItem>
              <FeeRowItem sx={{ borderBottom: 'none', paddingTop: '1rem' }}>
                <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                  <Typography fontSize={'1.5rem'} fontWeight={300} lineHeight={'2.2rem'}>
                    Instalment amount
                  </Typography>

                  <Box position={'relative'} display={'inline-block'}>
                    <Avatar
                      src={InfoIcon}
                      alt="Information icon"
                      sx={{
                        width: '1.6rem',
                        height: '1.6rem',
                        marginLeft: '0.8rem',
                        cursor: 'pointer',
                      }}
                      onClick={() => { setShowQuotationBreakdownPopup(true) }}
                    ></Avatar>
                  </Box>
                </Stack>
                <Typography fontSize={'1.4rem'} fontWeight={700} lineHeight={'2.8rem'}>
                  R{' '}
                  {formatMoney(
                    (getQuotationResponse?.totalRepayable ?? 0) + (getQuotationResponse?.deathBenefitContractValue ?? 0)
                  )}
                </Typography>
              </FeeRowItem>
            </Box>
          </>
      }
    </Box >
  );
};

export default CreditLifePlusFeeBreakdownPopup;
