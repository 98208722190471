import { NavigateFunction } from "react-router-dom";
import { PostProcessingRoutes, RouterRoutes } from "../RouterRoutes";

export const enum JourneyType {
    l0Journey = 'l0Journey',
    lnJourney = 'lnJourney',
    lnJourneyShortened = 'lnJourneyShortened',
}

export const navigateToTermsAndConditions = (navigate: NavigateFunction, loanId: string, isExistingCustomer: boolean, replaceRoute?: boolean) => {
    navigate(`/${isExistingCustomer ? RouterRoutes.existingJourney : RouterRoutes.newJourney}/${PostProcessingRoutes.agreement}`, { state: { loanId }, replace: replaceRoute });
}

export const navigateToDocumentUpload = (navigate: NavigateFunction, loanId: string, isExistingCustomer: boolean, replaceRoute?: boolean) => {
    navigate(`/${isExistingCustomer ? RouterRoutes.existingJourney : RouterRoutes.newJourney}/${PostProcessingRoutes.documentUpload}`, { state: { loanId }, replace: replaceRoute });
}

export const navigateToExternalPage = (url: string) => {
    window.location.assign(url);
}

export const navigateToCounterOfferProcessing = (navigate: NavigateFunction, loanId: string, isExistingCustomer: boolean, replaceRoute?: boolean) => {
    navigate(`/${isExistingCustomer ? RouterRoutes.existingJourney : RouterRoutes.newJourney}/${PostProcessingRoutes.counterOfferProcessing}`, { state: { loanId }, replace: replaceRoute });
}

export const navigateToCounterOfferDecline = (navigate: NavigateFunction, loanId: string, isExistingCustomer: boolean, replaceRoute?: boolean) => {
    navigate(`/${isExistingCustomer ? RouterRoutes.existingJourney : RouterRoutes.newJourney}/${PostProcessingRoutes.counterOfferDecline}`, { state: { loanId }, replace: replaceRoute });
}

export const navigateToIvOptionsPage = (navigate: NavigateFunction, loanId: string, isExistingCustomer: boolean, errorDialogOpened: boolean) => {
  navigate(`/${isExistingCustomer ? RouterRoutes.existingJourney : RouterRoutes.newJourney}/${PostProcessingRoutes.IvOptions}`, { state: { loanId, errorDialogOpened }, replace: true });
}

export const navigateToOpenBankingProcessing = (navigate: NavigateFunction, loanId: string, isExistingCustomer: boolean) => {
  navigate(`/${isExistingCustomer ? RouterRoutes.existingJourney : RouterRoutes.newJourney}/${PostProcessingRoutes.OpenBankingProcessing}`, { state: { loanId }, replace: true});
}

export const navigateToFailedOpenBanking = (navigate: NavigateFunction, loanId: string, isExistingCustomer: boolean, loanAmount: number, title: string) => {
  navigate(`/${isExistingCustomer ? RouterRoutes.existingJourney : RouterRoutes.newJourney}/${PostProcessingRoutes.OpenBankingFailure}`, { state: { loanId, loanAmount, title }, replace: true});
}

export const determineJourneyType = (isExistingCustomer: boolean, hasAllPersonalDetails: boolean): JourneyType => {
    if (isExistingCustomer && hasAllPersonalDetails) return JourneyType.lnJourneyShortened;
    else if (isExistingCustomer && !hasAllPersonalDetails) return JourneyType.lnJourney;
    return JourneyType.l0Journey;
}
