import React from 'react'
import Typography from "@mui/material/Typography";
import styles from "./CounterOfferDeclined.module.scss";
import { IOpenLoanDetailsResponse } from '../../../../../clients/LoanClient';
import { RouterRoutes } from '../../../../../utils/RouterRoutes';
import { useNavigate } from 'react-router-dom';
import OptimonkContainer from '../../../../../components/OptimonkContainer/OptimonkContainer';

interface CounterOfferDeclinedProps {
    setOpenLoanDetailsResponse?: React.Dispatch<React.SetStateAction<IOpenLoanDetailsResponse | null>>;
}

const CounterOfferDeclined = (props: CounterOfferDeclinedProps) => {
    const { setOpenLoanDetailsResponse } = props;
    const navigate = useNavigate();

    const onClickReapply = () => {
        if (setOpenLoanDetailsResponse) {
            //shows sliders
            setOpenLoanDetailsResponse((prevState) => ({
                ...prevState,
                hasOpenLoan: false,
                openLoanStatus: undefined,
            }));
        } else {
            navigate(`/${RouterRoutes.myLoan}`);
        }
    };

    return (
        <div className={styles["counter-offer-declined-container"]}>
            <Typography variant="h2" paddingTop={"3rem"} marginBottom={"1.6rem"} fontSize={{ xs: "2.4rem", sm: "3.2rem" }} fontWeight={300}>
                You can <b className={styles["text-bold"]} onClick={onClickReapply}>reapply</b> for a loan anytime - we&apos;re here to help!
            </Typography>
            <Typography variant="body1" fontWeight={300} marginBottom={"3.4rem"}>
                Below are some possible ways to <b>save</b> on your monthly expenses, why not give it a try?
            </Typography>
            <OptimonkContainer showContainer={true} />
        </div>
    )
}

export default CounterOfferDeclined