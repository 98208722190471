import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from '@mui/material';
import styles from './EasyPay.module.scss';
import { IOpenLoanDetailsResponse } from '../../../../../clients/LoanClient';
import lightbulb from '../../../../../assets/img/svg/lightbulb-big.svg';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { IGetPersonalDetailsResponse } from '../../../../../clients/AccountClient';
import { formatMoney } from '../../../../../utils/Helpers/SliderHelperFunctions';
import { formatDateWithDefault } from '../../../../../utils/Helpers/DateHelpers';
import { useAppSettings } from '../../../../../contexts/AppSettingsContext';

interface EasyPayProps {
  openLoanResponse: IOpenLoanDetailsResponse;
  personalDetailsResponse: IGetPersonalDetailsResponse;
}

const EasyPay: React.FunctionComponent<EasyPayProps> = ({
  openLoanResponse,
  personalDetailsResponse,
}: EasyPayProps) => {
  const { collectionDate, currentBalance } = openLoanResponse.loanData || {};
  const navigate = useNavigate();
  const { companyDetails } = useAppSettings();

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles['easypay-container']}>
      <Typography
        variant="h1"
        fontSize={{ xs: '2.4rem', sm: '3.2rem' }}
        fontWeight={400}
        lineHeight={'3.2rem'}
        letterSpacing={'-0.072rem'}
      >
        Repay using EasyPay
      </Typography>
      <div className={styles['easypay-hint-container']}>
        <img src={lightbulb} aria-label="Lightbulb Icon" className={styles['easypay-hint-icon']} />
        <div className={styles['easypay-hint-text']}>
          Payments made within 5 days of your next instalment may not be processed in time. Once payment is made, this
          can take 48 working hours to reflect on your loan account.
        </div>
      </div>
      <div className={styles['easypay-table']}>
        <div>EasyPay Number</div>
        <div>{personalDetailsResponse?.personalDetails?.customer?.easyPayNumber}</div>
      </div>
      <div className={styles['easypay-table']}>
        <div>Current Balance Date</div>
        <div>{formatDateWithDefault(collectionDate, 'do MMM yyyy')}</div>
      </div>
      <div className={styles['easypay-table-bold']}>
        <div>Current Balance</div>
        <div>R {formatMoney(currentBalance ?? 0)}</div>
      </div>
      <Accordion elevation={0} sx={{ backgroundColor: '#f5f5f5', margin: '1.6rem 0rem' }}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon fontSize="large" />}
          aria-controls="easypay-info-content"
          id="easypay-info-header"
        >
          <Box width={'100%'} textAlign={'center'}>
            <Typography>How EasyPay Works</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={'1.6rem'}>
            <Typography variant="h4">
              EasyPay is a free, independent service that allows for the payment of bills, accounts and more.
            </Typography>
            <Typography fontWeight={300}>
              EasyPay enables you to pay a number of bills and your Wonga account at a large number of pay points across
              South Africa at retailers such as Pick n&apos; Pay, Shoprite, Checkers, Lewis, Spar and Best Electric.
            </Typography>

            <Typography fontSize={18} fontWeight={600} paddingLeft={'1.2rem'}>
              All you need to do:
            </Typography>
            <Typography fontWeight={300}>
              1. <b>Print out the EasyPay code</b> and take it with you to pay at one of the registered outlets or pay
              online.
            </Typography>
            <Typography fontWeight={300}>
              2. To receive your EasyPay code <b>{`via SMS please call us on ${companyDetails.CompanyContactNumber}`}</b>
            </Typography>

            <Typography fontSize={18} fontWeight={600} paddingLeft={'1.2rem'}>
              Special Instructions
            </Typography>
            <Typography fontWeight={300}>
              Remember you cannot use EasyPay within 4 days of your original chosen due date (this is the date you
              promised to repay).
            </Typography>
            <Typography fontWeight={300}>
              This is because we won&apos;t be able to cancel the debit order collection from your account in time.
            </Typography>
            <Typography fontWeight={300}>
              As soon as we receive confirmation of your repayment from EasyPay we will be able to update your Wonga
              Account to reflect your payment.
            </Typography>
          </Stack>
        </AccordionDetails>
      </Accordion>

      <div className={styles['easypay-back-button-container']}>
        <PrimaryButton onClick={navigateBack}>Back</PrimaryButton>
      </div>
    </div>
  );
};

export default EasyPay;
