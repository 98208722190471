import React from 'react';
import styles from './Disbursing.module.scss';
import Typography from '@mui/material/Typography';
import { IOpenLoanDetailsResponse } from '../../../../clients/LoanClient';
import ClockIcon from '../../../../assets/img/svg/clock_icon.svg';

interface OpenLoan {
  openLoanResponse: IOpenLoanDetailsResponse;
}

const Disbursing: React.FunctionComponent<OpenLoan> = ({ openLoanResponse }: OpenLoan) => {
  /* eslint-disable  @typescript-eslint/no-non-null-assertion*/
  const loanAmount = openLoanResponse.quotationData?.loanAmount!;

  return (
    <>
      <div className={styles['disbursing-container']}>
        <img className={styles['disbursing-img-container']} src={ClockIcon} alt="Clock Icon" />
        <div className={styles['disbursing-content']}>
          <Typography
            variant="h1"
            fontSize={{ xs: '2.4rem', sm: '3.2rem' }}
            fontWeight={400}
            lineHeight={'3.2rem'}
            letterSpacing={{ xs: '-0.072rem', sm: '-0.05rem' }}
            paddingBottom={'2rem'}
          >
            Your Cash is on the Way!
          </Typography>
          <Typography variant="body1" fontWeight={300}>
            <b>R {loanAmount} is on its way</b> to your bank account. This could take <b>up to 48 hours to reflect</b>,
            depending on the bank you use and the time of day your loan was approved.
          </Typography>
          <br />
        </div>
      </div>
    </>
  );
};

export default Disbursing;
