import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

interface IIconCard {
  heading: string;
  body: string;
  img: string;
  alt: string;
  imgWidth?: string;
  imgHeight?: string;
}

const IconCard: React.FC<IIconCard> = ({ heading, body, img, alt, imgWidth, imgHeight }: IIconCard) => {
  return (
    <>
      <Box sx={{ width: '300px', height: '300px', padding: 2 }}>
        <Stack sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={img} alt={alt} width={imgWidth || '111px'} height={imgHeight || '92px'} />
          <Typography variant="h3" sx={{ textAlign: 'center', marginTop: '2.6rem' }}>
            {heading}
          </Typography>
          <Typography variant="body1" textAlign={'center'} marginTop={1}>
            {body}
          </Typography>
        </Stack>
      </Box>
    </>
  );
};

export default IconCard;
