import React from 'react'
import styles from "./BackgroundBubbles.module.scss";

type Props = {
    children?: React.ReactNode
};

export const BackgroundBubbles: React.FC<Props> = ({ children }) => {
    return (
        <>
            <div className={styles.app}>
                <div className={styles.content}>
                    <div className={styles["particle-container"]}>
                        <div className={styles["particle"]}></div>
                        <div className={styles["particle"]}></div>
                        <div className={styles["particle"]}></div>
                        <div className={styles["particle"]}></div>
                        <div className={styles["particle"]}></div>
                        <div className={styles["particle"]}></div>
                        <div className={styles["particle"]}></div>
                        <div className={styles["particle"]}></div>
                        <div className={styles["particle"]}></div>
                        <div className={styles["particle"]}></div>
                        <div className={styles["particle"]}></div>
                        <div className={styles["particle"]}></div>
                    </div>
                    {children}
                </div>
            </div >
        </>
    )
}
