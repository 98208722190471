import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { User } from 'configcat-react';
import React, { useContext, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { OpenLoanStatus } from '../clients/LoanClient';
import { AppSettingsContext } from '../contexts/AppSettingsContext';
import { InitDatadogLogs, InitDatadogRum } from './Datadog/InitDatadog';
import { ApplicationDecision } from './Enums/ApplicationDecision';
import { ApplicationStatus } from './Enums/ApplicationStatus';
import { appendMember, getPage, getPageKeyBasedOnOpenLoanStatus } from './Helpers/ApplicationStatusHelpers';
import { getFriendlyOpenLoanStatus } from '../utils/Helpers/OpenLoanStatusEnum';

const existingCustomerKey = 'ec';
const isPersonalDetailsCompleteKey = 'isPersonalDetailsComplete';
const customerDetailsKey = 'customerDetails';

export interface TrackingProps {
  children: React.ReactNode;
}

export interface FeatureFlagEvent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  featureFlag: any;
  value: boolean | string | number | unknown;
}

export interface CustomerTrackingDetails {
  accountId?: string,
  email?: string,
  name?: string,
  existingCustomer?: boolean
}

export interface CustomConfigCatAttributes {
  isMobile: string,
  browser: string,
  device: string
}


const defaultCustomerTrackingDetails: CustomerTrackingDetails = {
  accountId: '',
  email: '',
  name: '',
  existingCustomer: false,
};

const defaultConfigCatUser: User = {
  identifier: '',
  country: '',
  email: '',
  custom: {

  }
}

export const TrackingContext = React.createContext({
  /* eslint-disable  @typescript-eslint/no-unused-vars*/
  TrackUserId: (accountId: string) => {
    /* empty */
  },
  PageTrack: (name: string) => {
    /* empty */
  },
  /* eslint-disable  @typescript-eslint/no-explicit-any*/

  EventTrack: (eventName: string, properties?: any) => {
    /* empty */
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  TrackFeatureFlagEvent: ({ featureFlag, value }: FeatureFlagEvent) => { },
  TrackCustomerDetails: (customer: CustomerTrackingDetails) => {
    /* empty */
  },
  TrackQuotation: (loanDetails: any, loanId: string) => {
    /* empty */
  },
  ApplicationDecisionTrack: (applicationDecision: ApplicationDecision) => {
    /* empty */
  },
  ApplicationStatusTrack: (applicationStatus: ApplicationStatus) => {
    /* empty */
  },
  isExistingCustomer: false,
  SetExistingCustomer: (isExistingCustomer: boolean) => {
    /* do nothing */
  },
  isPersonalDetailsComplete: false,
  SetIsPersonalDetailsComplete: (isPersonalDetailsComplete: boolean) => {
    /* do nothing */
  },
  TrackError: (error: unknown, errorMessage?: string) => {
    /* empty */
  },
  TrackInfo: (message: string, error?: unknown) => {
    /* empty */
  },
  TrackWarning: (message: string, error?: unknown) => {
    /* empty */
  },
  GetAccountSummaryPageKey: (openLoanStatus?: OpenLoanStatus): string => {
    return '';
  },
  customerTrackingDetails: defaultCustomerTrackingDetails,
  configCatUserDetails: defaultConfigCatUser
});

export const useTracking = () => {
  return useContext(TrackingContext);
};

const TrackingContextProvider: React.FC<TrackingProps> = ({ children }) => {
  const appSettings = useContext(AppSettingsContext);

  const [isExistingCustomer, setIsExistingCustomer] = useState(() => {
    const storedValue = localStorage.getItem(existingCustomerKey);
    return storedValue ? JSON.parse(storedValue) : false;
  });

  const [isPersonalDetailsComplete, setIsPersonalDetailsComplete] = useState<boolean>(() => {
    const storedValue = localStorage.getItem(isPersonalDetailsCompleteKey.toString());
    return storedValue ? JSON.parse(storedValue) : false;
  });

  const [customerTrackingDetails, setCustomerTrackingDetails] = useState<CustomerTrackingDetails>(() => {
    const storedValue = localStorage.getItem(customerDetailsKey.toString());
    return storedValue ? JSON.parse(storedValue) : defaultCustomerTrackingDetails;
  });

  const [configCatUserDetails, setConfigCatUserDetails] = useState<User>(defaultConfigCatUser);

  useEffect(() => {
    mapConfigCatUser(customerTrackingDetails);
  }, [customerTrackingDetails]);

  const mapConfigCatUser = (customerTrackingDetails: CustomerTrackingDetails) => {

    setConfigCatUserDetails({
      identifier: customerTrackingDetails.accountId || '',
      country: '',
      email: customerTrackingDetails.email,
      custom: {
        ec: String(customerTrackingDetails.existingCustomer)
      }
    })
  }

  useEffect(() => {
    InitDatadogRum(appSettings);
    InitDatadogLogs(appSettings);

    return () => {
      datadogRum.stopSession();
    };
  }, [appSettings]);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: appSettings.googleTagManager.gtmId,
    };

    TagManager.initialize(tagManagerArgs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const googleTagManager = (name: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'content-view',
        pagePath: name,
        pageTitle: name,
      },
    });
  };

  const datadogRumTracking = (name: string) => {
    if (appSettings.datadogConfig.datadogEnabled) {
      datadogRum.startView(name.replace(/^\/+/, ''));
    }
  };

  const TrackPageVisit = (name: string) => {
    //Marketing notice /undefined being tracked not sure where this came from so adding an additional check
    if (name && name != '/undefined') {
      googleTagManager(name);
      datadogRumTracking(name);
    }
  };

  const TrackEvent = (eventName: string, properties?: any) => {
    if (appSettings.datadogConfig.datadogEnabled) {
      datadogRum.addAction(eventName, { ...properties });
    }
  };

  const trackFeatureFlagEvent = ({ featureFlag, value }: FeatureFlagEvent) => {
    if (appSettings.datadogConfig.datadogEnabled) {

      datadogRum.addFeatureFlagEvaluation(featureFlag, value);
    }
  };

  const TrackUserId = (accountId: string) => {
    if (appSettings.datadogConfig.datadogEnabled) {
      datadogRum.setGlobalContextProperty('AccountId', accountId);
      datadogLogs.setGlobalContextProperty('AccountId', accountId);

      datadogRum.setUser({
        id: accountId,
      });

      datadogLogs.setUser({
        id: accountId,
      });
    }

    TagManager.dataLayer({
      dataLayer: {
        event: 'interaction',
        AccId: accountId,
      },
    });
  };

  const TrackCustomerDetails = (customertrackingDetails: CustomerTrackingDetails) => {
    const currentDetails = JSON.parse(localStorage.getItem(customerDetailsKey) || '{}');
    const updatedUserDetails = {
      ...currentDetails,
      ...Object.fromEntries(
        Object.entries(customertrackingDetails).filter(([_, value]) => value !== undefined && value !== "")
      )
    };

    localStorage.setItem(customerDetailsKey, JSON.stringify(updatedUserDetails));
    setCustomerTrackingDetails(updatedUserDetails);
  };

  const setQuotationValues = (quotationResponse: any, loanId: string) => {
    if (appSettings.datadogConfig.datadogEnabled) {
      datadogRum.setGlobalContextProperty('loanDetails', quotationResponse);
      datadogRum.setGlobalContextProperty('LoanId', loanId);

      datadogLogs.setGlobalContextProperty('loanDetails', quotationResponse);
      datadogLogs.setGlobalContextProperty('LoanId', loanId);
    }

    TagManager.dataLayer({
      dataLayer: {
        event: 'interaction',
        ...quotationResponse,
        LoanId: loanId,
      },
    });
  };

  const trackError = (error: unknown, errorMessage?: string) => {
    datadogRum.addError(error);

    if (error instanceof Error) {
      datadogLogs.logger.error(errorMessage ?? 'Error occurred', {}, error);
    } else if (typeof error === 'string') {
      datadogLogs.logger.error(errorMessage ?? 'Error occurred', {}, new Error(error));
    }
  };

  const trackInfo = (message: string, error?: unknown) => {
    if (!error) {
      datadogLogs.logger.info(message, {});
    } else if (error instanceof Error) {
      datadogLogs.logger.info(message, {}, error);
    } else if (typeof error === 'string') {
      datadogLogs.logger.error(message, {}, new Error(error));
    }
  };

  const trackWarning = (message: string, error?: unknown) => {
    if (!error) {
      datadogLogs.logger.warn(message, {});
    } else if (error instanceof Error) {
      datadogLogs.logger.warn(message, {}, error);
    } else if (typeof error === 'string') {
      datadogLogs.logger.warn(message, {}, new Error(error));
    }
  };

  const TrackApplicationDecision = (applicationDecision: ApplicationDecision) => {
    switch (applicationDecision) {
      case ApplicationDecision.ReadyToSign:
        TrackApplicationStatus(ApplicationStatus.readyToSign);
        break;
      case ApplicationDecision.Accepted:
        TrackApplicationStatus(ApplicationStatus.thanks);
        break;
      case ApplicationDecision.Declined:
        TrackApplicationStatus(ApplicationStatus.declined);
        break;
      case ApplicationDecision.CounterOffered:
        TrackApplicationStatus(ApplicationStatus.counterOffer);
        break;
      default:
        TrackApplicationStatus(ApplicationStatus.timeout);
    }
  };

  const TrackApplicationStatus = (applicationStatus: ApplicationStatus): void => {
    const pageMember = appendMember(getPage(applicationStatus), isExistingCustomer);
    TrackPageVisit(pageMember);
  };

  const getAccountSummaryPageKey = (openLoanStatus?: OpenLoanStatus) => {
    if (openLoanStatus === undefined || openLoanStatus === null) return '';

    const friendlyOpenLoanStatus = getFriendlyOpenLoanStatus(openLoanStatus);
    const pageKey = getPageKeyBasedOnOpenLoanStatus(friendlyOpenLoanStatus);
    if (!pageKey) return '';
    return appendMember(pageKey, isExistingCustomer);
  };

  /* We set whether the customer is an existing customer in auth context when they are logged in */
  function SetExistingCustomer(value: boolean) {
    TrackCustomerDetails({ existingCustomer: value })
    setIsExistingCustomer(value);
    localStorage.setItem(existingCustomerKey, JSON.stringify(value));
  }

  function SetIsPersonalDetailsComplete(value: boolean) {
    setIsPersonalDetailsComplete(value);
    localStorage.setItem(isPersonalDetailsCompleteKey, JSON.stringify(value));
  }

  const values = {
    TrackUserId,
    PageTrack: TrackPageVisit,
    EventTrack: TrackEvent,
    TrackCustomerDetails,
    TrackQuotation: setQuotationValues,
    ApplicationDecisionTrack: TrackApplicationDecision,
    ApplicationStatusTrack: TrackApplicationStatus,
    isExistingCustomer,
    SetExistingCustomer,
    isPersonalDetailsComplete,
    SetIsPersonalDetailsComplete,
    TrackError: trackError,
    GetAccountSummaryPageKey: getAccountSummaryPageKey,
    TrackFeatureFlagEvent: trackFeatureFlagEvent,
    TrackInfo: trackInfo,
    TrackWarning: trackWarning,
    customerTrackingDetails,
    configCatUserDetails
  };

  return (
    <>
      <TrackingContext.Provider value={values}>{children}</TrackingContext.Provider>
    </>
  );
};

export default TrackingContextProvider;
