import { Box, Stack, Typography, Avatar } from "@mui/material";
import { theme } from "../../../../theme/Theme";
import CloseIcon from '../../../../assets/img/Close.png';

type SettlementDisclaimerPopupProps = {
    showSettlementDisclaimer: boolean;
    setShowSettlementDisclaimer: React.Dispatch<React.SetStateAction<boolean>>;
}

const SettlementDisclaimerPopup: React.FC<SettlementDisclaimerPopupProps> = ({ showSettlementDisclaimer, setShowSettlementDisclaimer }) => {
    return <>
        <Box
            display={showSettlementDisclaimer ? 'block' : 'none'}
            position={'absolute'}
            sx={{
                top: { xs: '26.5rem', md: '24rem' },
                left: { xs: '1.2rem', md: '44rem' },
                right: { xs: 0, md: 0 },
                marginLeft: { xs: 'unset', md: 'auto' },
                marginRight: { xs: 'unset', md: 'auto' },
                width: { xs: 'calc(100vw - 2.3rem)', sm: '35.7rem' },
                borderRadius: '0.5rem',
                boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                transition: 'all 0.25s ease-in-out',
                zIndex: '1',
            }}
        >
            <Stack
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                flexGrow={1}
                sx={{
                    padding: '0.4rem 1.3rem',
                    backgroundColor: theme.palette.secondary.main,
                    borderTopLeftRadius: '0.5rem',
                    borderTopRightRadius: '0.5rem',
                }}
            >
                <Typography
                    fontSize={'1.8rem'}
                    fontWeight={500}
                    lineHeight={'4rem'}
                    color={theme.palette.common.white}
                    fontStyle={'normal'}
                >
                    Settlement disclaimer
                </Typography>
                <Avatar
                    src={CloseIcon}
                    alt={'Close Icon'}
                    sx={{ width: '2.5rem', height: '3.2rem', cursor: 'pointer' }}
                    onClick={() => setShowSettlementDisclaimer(false)}
                />
            </Stack>

            <Box sx={{ backgroundColor: theme.palette.common.white, padding: '1.5rem', borderRadius: '0.5rem' }}>
                <Typography fontSize={'1.3rem'} fontStyle={'normal'} fontWeight={300} lineHeight={'15px'}>This balance could exclude an additional fee, if you have been handed over to external Debt Collection Agency.</Typography>
            </Box>
        </Box>
    </>
}

export default SettlementDisclaimerPopup;