import React from "react";
import { Button, Dialog } from "@mui/material";
import styles from "./PaymentStatusDialog.module.scss";
import PaymentSuccessIcon from "../../assets/img/svg/PaymentSuccess.svg";
import PaymentFailedIcon from "../../assets/img/svg/PaymentFailed.svg";
import WongaLogoWhite from "../../assets/img/svg/WongaLogoSolidWhite.svg";
import { PaymentStatus } from "../../utils/Helpers/PaymentStatusMappings";

interface PaymentStatusDialogProps {
    onButtonClick: () => void;
    status: PaymentStatus;
}

const PaymentStatusDialog = ({ onButtonClick, status }: PaymentStatusDialogProps) => {
    const isPaymentSuccessful = status === PaymentStatus.Successful;
    const isPaymentCancelled = status === PaymentStatus.Cancelled;

    const title = () => {
        if (isPaymentSuccessful) return <p className={styles["payment-status-title-success"]}>Payment successful</p>;
        if (isPaymentCancelled) return <p className={styles["payment-status-title-cancelled"]}>Payment cancelled</p>;
        return <p className={styles["payment-status-title-failed"]}>Payment failed</p>;
    };

    const iconSrc = () => {
        if (isPaymentSuccessful) return PaymentSuccessIcon
        return PaymentFailedIcon;
    };

    const buttonText = () => {
        if (isPaymentSuccessful) return "Okay";
        return "Try Again";
    };

    return (
        <Dialog open PaperProps={{ sx: { borderRadius: "0.8rem" } }}>
            <div className={styles["payment-status-header"]}>
                <img
                    src={WongaLogoWhite}
                    alt="Wonga Logo"
                    aria-label="Wonga Logo"
                />
            </div>
            <div className={styles["payment-status-body"]}>
                {title()}
                <img
                    src={iconSrc()}
                    aria-label="Payment Icon"
                    className={styles["payment-status-icon"]}
                />
                <div className={styles["payment-status-button-container"]}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={onButtonClick}
                        sx={{ borderRadius: "0.8rem" }}
                        fullWidth>
                        <p className={styles["payment-status-button-text"]}>
                            {buttonText()}
                        </p>
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default PaymentStatusDialog;