import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Countdown from "react-countdown";
import React from "react";

interface SessionDialogProps {
  openDialog: boolean;
  handleDialogClose: (event: {}, reason: string) => void;
  restartIdleTimer: () => void;
  logout: () => void;
}

const SessionDialog = ({openDialog, handleDialogClose, restartIdleTimer, logout}: SessionDialogProps) => {

  // In case the user just closes the model, we could still consider them as active.
  return (
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" variant={"h3"}>
          {"Would you like to say logged in?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

            <Countdown
              date={Date.now() + 60000}
              intervalDelay={1000}
              precision={3}
              onComplete={logout}
              renderer={(props) =>
                <label style={{fontSize: "medium"}}>Your session will expire in <span style={{color: "blue"}}>{props.seconds}</span> seconds</label>}
            />

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={restartIdleTimer}>Yes</Button>
          <Button onClick={logout} autoFocus>No, log out</Button>
        </DialogActions>
      </Dialog>
  );
}


export default SessionDialog;
