import React, { useEffect, useState } from 'react';
import { bankAccountTypeMappings, bankCodeMappings, bankNameMappings } from '../../../../../models/SelectOptions';
import '../../../../../styles/_forms.scss';
import './../../../../../styles/_buttons.scss';
import { BankAccountType, BankDetails, ErrorResponse, IBankDetails, UpdateBankResponse } from '../../../../../clients/AccountClient';
import useAccountClient from '../../../../../hooks/account/Client';
import { Box, Grid, Stack, Typography } from '@mui/material';
import FormInputLabel from '../../../../../components/Form/FormInputLabel';
import FormInputWrapper from '../../../../../components/Form/FormInputWrapper';
import SelectFormController from '../../../../../components/MuiInput/FormControllers/SelectFormController';
import InputFormController from '../../../../../components/MuiInput/FormControllers/InputFormController';
import { Form, useForm } from '../../../../../components/Form/Form';
import { bankSchema } from '../../../../../schemas/Schemas';
import { useSnackBar } from '../../../../../contexts/SnackBarContext';
import Loading from '../../../../../components/Loading/Loading';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import { FULL_COLUMN_SIZE } from '../../../../../utils/GridColumnSizeDefinitions';
import TextButton from '../../../../../components/Buttons/TextButton';
import { useTracking } from '../../../../../Tracking/TrackingContext';

interface IChangeBankLNProps {
    bank?: IBankDetails;
    closeEditing: (isUpdated: boolean) => Promise<void>;
}

const ChangeBankLN: React.FunctionComponent<IChangeBankLNProps> = ({
    bank,
    closeEditing,
}: IChangeBankLNProps) => {
    const client = useAccountClient();
    const [submitLoading, setSubmitLoading] = useState(false);
    const { displaySnackBar } = useSnackBar();
    const { TrackError } = useTracking();

    const bankForm = useForm({
        criteriaMode: 'all',
        mode: 'onBlur',
        schema: bankSchema,
    });

    const accountTypeWatch = bankForm.watch('accountType');
    const bankNameWatch = bankForm.watch('bankName');

    useEffect(() => {
        bankForm.setValue('holderName', bank?.holderName ?? '');
        bankForm.setValue('accountNumber', bank?.accountNumber ?? '');
        bankForm.setValue('accountType', bank?.bankAccountType ?? BankAccountType.Unspecified);
        bankForm.setValue('bankName', bank?.bankName ?? '');
        bankForm.setValue('bankCode', bank?.bankCode ?? '');
        bankForm.setValue('openedOn', bank?.openedOn?.toString() ?? '');
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if ((accountTypeWatch || bankNameWatch) && accountTypeWatch !== BankAccountType.Unspecified)
            bankForm.trigger('accountNumber');
    }, [accountTypeWatch, bankNameWatch, bankForm]);

    /* eslint-disable  @typescript-eslint/no-explicit-any*/
    const updateBankAccount = async (data: any) => {
        setSubmitLoading(true);
        const bankCode = bankCodeMappings.find((bankCode) => bankCode.key === bankForm.getValues('bankName'))?.value;
        const bank: IBankDetails = {
            accountNumber: data.accountNumber,
            bankAccountType: data.accountType,
            bankCode: bankCode,
            bankName: data.bankName,
            holderName: data.holderName,
            /*
            openedOn is a required field on interface in the backend but
            it is not actually being used so we are just hard coding it in here
            or else it will fail.
            */
            openedOn: new Date('01/01/2001'),
        };

        try {
            const response: UpdateBankResponse = await client.updateBank(new BankDetails(bank));

            if (response.bankUpdated) {
                displaySnackBar('Bank Details saved', 'success');
                await closeEditing(true);
                setSubmitLoading(false);
            }
        } catch (error) {
            setSubmitLoading(false);
            if (error instanceof ErrorResponse && error.errorCode === 400) {
                TrackError(error, error.message);
                displaySnackBar(error.message ?? "An validation error has occurred", 'error');
            } else {
                displaySnackBar('Oops, an error has occurred please try again', 'error');
            }
        }
    };

    return (
        <div>
            <Typography variant="h2" fontSize={{ xs: "2.4rem" }} marginBottom={"2.4rem"} fontWeight={400}>
                Bank details
            </Typography>
            <Form form={bankForm} onSubmit={updateBankAccount}>
                <Grid container marginBottom={'2.2rem'}>
                    <FormInputLabel>Bank Name</FormInputLabel>
                    <FormInputWrapper>
                        <SelectFormController
                            name="bankName"
                            label="Bank Name"
                            menuItems={bankNameMappings}
                            register={bankForm.register}
                            control={bankForm.control}
                        />
                    </FormInputWrapper>

                    <FormInputLabel>Account Number</FormInputLabel>
                    <FormInputWrapper>
                        <InputFormController
                            name="accountNumber"
                            label="Account number"
                            placeholder="Account number"
                            register={bankForm.register}
                            control={bankForm.control}
                        />
                    </FormInputWrapper>

                    <FormInputLabel>Account Type</FormInputLabel>
                    <FormInputWrapper>
                        <SelectFormController
                            name="accountType"
                            label="Account Type"
                            menuItems={bankAccountTypeMappings}
                            register={bankForm.register}
                            control={bankForm.control}
                        />
                    </FormInputWrapper>

                    <FormInputLabel>Account Holder</FormInputLabel>
                    <FormInputWrapper>
                        <InputFormController
                            name="holderName"
                            label="Account Holder"
                            placeholder="Account holder"
                            register={bankForm.register}
                            control={bankForm.control}
                            disabled
                        />
                    </FormInputWrapper>

                    {submitLoading ? (
                        <Grid item xs={FULL_COLUMN_SIZE}>
                            <Stack
                                alignItems={{ xs: 'center', sm: 'flex-end' }}
                                marginTop={'1rem'}
                            >
                                <Box textAlign={"center"} width={{ xs: "100%", sm: "25rem" }}>
                                    <Loading />
                                </Box>
                            </Stack>
                        </Grid>
                    ) : (
                        <>
                            <Grid item xs={FULL_COLUMN_SIZE}>
                                <Stack
                                    flexGrow={1}
                                    flexDirection={{ xs: 'column', sm: 'row' }}
                                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                                    justifyContent={{ xs: 'center', sm: 'space-between' }}
                                    marginTop={'1rem'}
                                >
                                    <TextButton removePadding onClick={() => closeEditing(false)}>Cancel</TextButton>
                                    <PrimaryButton type="submit">Update</PrimaryButton>
                                </Stack>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Form>
        </div>
    );
};

export default ChangeBankLN;
