import React from 'react';
import styles from './DebitOrderSchedule.module.scss';
import { Stack, Step, Stepper, Typography, useMediaQuery } from '@mui/material';
import { utcFormattedDate } from '../../utils/Helpers/DateHelpers';
import DebitOrderLabel from './DebitOrderLabel';
import { IDebitOrderSchedule, TransactionTypeEnum } from '../../clients/LoanClient';
import ErrorIcon from '../../assets/img/InformationOrangeIcon.png';
import { theme } from '../../theme/Theme';

interface DebitOrderScheduleProps {
    debitOrders: IDebitOrderSchedule[];
    totalInstalments: number;
    completedDebitOrders: number;
}

const DebitOrderSchedule: React.FunctionComponent<DebitOrderScheduleProps> = (props: DebitOrderScheduleProps) => {
    const { debitOrders, totalInstalments, completedDebitOrders } = props;
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const renderDebitOrders = (debitOrder: IDebitOrderSchedule, index: number) => {
        const isCashOut = debitOrder.transactionType === TransactionTypeEnum.Cashout;
        const isNextInstalment = debitOrder.transactionType === TransactionTypeEnum.NextInstallment;
        const isFutureInstalment = debitOrder.transactionType === TransactionTypeEnum.FutureInstallment;
        const isFinalInstalment = (debitOrders.length - 1) === index;
        const hasOneInstalmentLeft = isFinalInstalment && debitOrders.filter(item => item.transactionType !== TransactionTypeEnum.Cashout).length === 1;

        if (isCashOut) {
            return (
                <Step key={index}>
                    <DebitOrderLabel
                        title={
                            <Typography fontSize={"1.5rem"} fontWeight={300} lineHeight={"2.2rem"} letterSpacing={"-0.4px"}>
                                {`Cash disbursement: `}
                                <span style={{ fontWeight: 500 }}>
                                    {utcFormattedDate(debitOrder.transactionDate ?? new Date())}
                                </span>
                            </Typography>
                        }
                        amount={debitOrder.transactionAmount ?? 0}
                    />
                </Step>
            );
        } else if (hasOneInstalmentLeft) {
            return (
                <Step key={index}>
                    <DebitOrderLabel
                        title={
                            <Typography fontSize={"1.5rem"} fontWeight={300} lineHeight={"2.2rem"} letterSpacing={"-0.4px"}>
                                <span style={{ fontWeight: 500 }}>
                                    Final
                                </span>
                                {` Instalment: `}
                                <span style={{ fontWeight: 500 }}>
                                    {utcFormattedDate(debitOrder.transactionDate ?? new Date())}
                                </span>
                            </Typography>
                        }
                        amount={debitOrder.transactionAmount ?? 0}
                    />
                </Step>
            );
        } else if (isFinalInstalment) {
            return (
                <Step key={index}>
                    <DebitOrderLabel
                        title={
                            <Stack maxHeight={"2.2rem"}>
                                <Typography fontSize={"1.5rem"} fontWeight={300} lineHeight={"2.2rem"} letterSpacing={"-0.4px"}>
                                    <span style={{ fontWeight: 500 }}>
                                        Final
                                    </span>
                                    {` Instalment: `}
                                    <span style={{ fontWeight: 500 }}>
                                        {utcFormattedDate(debitOrder.transactionDate ?? new Date())}
                                    </span>
                                </Typography>
                                <Typography fontSize={"1.2rem"} fontWeight={300} lineHeight={"2.2rem"} letterSpacing={"-0.4px"}>
                                    Subject to banking days
                                </Typography>
                            </Stack>
                        }
                        amount={debitOrder.transactionAmount ?? 0}
                    />
                </Step>
            );
        } else if (isNextInstalment) {
            return (
                <Step key={index}>
                    <DebitOrderLabel
                        title={
                            <Typography fontSize={"1.5rem"} fontWeight={300} lineHeight={"2.2rem"} letterSpacing={"-0.4px"}>
                                <span style={{ fontWeight: 500 }}>
                                    Next
                                </span>
                                {` Instalment: `}
                                <span style={{ fontWeight: 500 }}>
                                    {utcFormattedDate(debitOrder.transactionDate ?? new Date())}
                                </span>
                            </Typography>
                        }
                        amount={debitOrder.transactionAmount ?? 0}
                    />
                </Step>
            );
        } else if (isFutureInstalment) {
            return (
                <Step key={index}>
                    <DebitOrderLabel
                        title={
                            <Stack maxHeight={"2.2rem"}>
                                <Typography fontSize={"1.5rem"} fontWeight={300} lineHeight={"2.2rem"} letterSpacing={"-0.4px"}>
                                    {`Instalment: `}
                                    <span style={{ fontWeight: 500 }}>
                                        {utcFormattedDate(debitOrder.transactionDate ?? new Date())}
                                    </span>
                                </Typography>
                                <Typography fontSize={"1.2rem"} fontWeight={300} lineHeight={"2.2rem"} letterSpacing={"-0.4px"}>
                                    Subject to banking days
                                </Typography>
                            </Stack>
                        }
                        amount={debitOrder.transactionAmount ?? 0}
                    />
                </Step>
            );
        }
    };

    return (
        <Stack>
            {
                debitOrders.length > 0 ?
                    <>
                        <Typography variant="body1" fontSize={'1.4rem'} fontWeight={400} lineHeight={'1.7rem'} paddingBottom={'1.2rem'} borderBottom={"1px solid #E4E4E4"}>
                            {`Upcoming instalment (${completedDebitOrders}/${totalInstalments})`}
                        </Typography>
                        <div className={styles['debit-order-schedule']}>
                            <div className={`${styles['debit-order-schedule-stepper-container']} ${debitOrders.length > 2 ? styles['extra-space'] : ""}`}>
                                <Stepper orientation="vertical" activeStep={1}>
                                    {debitOrders.map(renderDebitOrders)}
                                </Stepper>
                            </div>
                        </div>
                    </>
                    :
                    <Stack bgcolor={'#F5F5F5'} borderRadius={'1rem'} padding={'1.2rem 1.6rem'} spacing={'1.3rem'} marginTop={'0.4rem'} marginBottom={'1.6rem'}>
                        <Stack direction={'row'} justifyContent={'center'} spacing={'1rem'} alignItems={'center'}>
                            <img src={ErrorIcon} alt='Error Icon' width={isMobile ? '16px' : '22px'} height={isMobile ? '16px' : '22px'} />
                            <Typography variant="body1" fontSize={{ xs: '1.8rem', sm: '2.4rem' }} fontWeight={600} lineHeight={'2rem'} color={'#F7902F'}>
                                Oops!
                            </Typography>
                        </Stack>
                        <Typography variant="body1" fontSize={'1.5rem'} fontWeight={300} lineHeight={'1.8rem'}>
                            We are having some trouble retrieving your loan details. Please try again in a few hours. Thank you for your patience.
                        </Typography>
                    </Stack>
            }
        </Stack>
    );
};

export default DebitOrderSchedule;