import React from "react";
import styles from "./Timeout.module.scss";
import { Box, Typography } from "@mui/material";
import { formatMoneyWithThousandSeperation } from "../../../../utils/Helpers/SliderHelperFunctions";

interface TimeoutProps {
    termInDays: number;
    term: number;
    loanAmount: number;
}

const Timeout: React.FunctionComponent<TimeoutProps> = (props: TimeoutProps) => {
    const { loanAmount, term, termInDays } = props;

    return (
        <div className={styles["timeout-container"]}>
            <Typography className={styles["timeout-heading"]}>Sorry, this seems to be taking much longer than normal...</Typography>
            <Typography className={styles["timeout-subtitle"]}>We&apos;re currently experiencing a delay in processing your loan. To ensure we offer you the best experience, we have paused your application and will email you with the outcome soon.</Typography>
            <Box className={styles["timeout-loan-amount-container"]}>
                <Typography className={styles["timeout-loan-amount-label"]}>loan amount:</Typography>
                <Typography className={styles["timeout-loan-amount-value"]}>R{formatMoneyWithThousandSeperation(loanAmount)}</Typography>
                <Typography className={styles["timeout-loan-amount-period"]}>
                    over <strong className={styles["timeout-text-bold"]}>{`${termInDays === 0 ? `${term} months` : `${termInDays} days`} to repay`}</strong>
                </Typography>
            </Box>
            <Typography className={styles["timeout-subtitle"]}>
                Thank you for your patience.
            </Typography>
            <br />
            <Typography className={styles["timeout-subtitle"]}>
                We&apos;ll be in touch soon.
            </Typography>
        </div>
    );
};

export default Timeout;