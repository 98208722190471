import { Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import styles from './HowItWorks.module.scss';
import { theme } from './../../../../theme/Theme';

const HowItWorks = () => {
  return (
    <Container
      disableGutters
      sx={{ backgroundColor: theme.palette.grey['200'], padding: '2rem 1.6rem', marginTop: '1.2rem' }}
    >
      <Typography
        variant="h3"
        variantMapping={{ h3: 'h2' }}
        fontSize={{ xs: '2.4rem', sm: '3.2rem' }}
        fontWeight={'400'}
        textAlign={'center'}
        lineHeight="2.7rem"
        marginBottom="2.3rem"
        marginTop={'1.2rem'}
      >
        Flexible, short term loans that give you back control.
      </Typography>
      <Typography
        variant="body1"
        fontSize={'1.6rem'}
        textAlign={'center'}
        lineHeight={'2.1rem'}
        letterSpacing={'-0.7px'}
      >
        Our{' '}
        <Link className={styles['home-link']} to="/short-term-loan">
          short term loans
        </Link>{' '}
        help people manage their cash flow. If you need a quick loan to tide you over for a short while, we&apos;re here
        for you. Our{' '}
        <Link className={styles['home-link']} to="/personal-loans">
          personal loan
        </Link>{' '}
        process is simple and easy to understand. To find out more, visit{' '}
        <Link className={styles['home-link']} to="/how-it-works">
          how it works.
        </Link>
      </Typography>
    </Container>
  );
};

export default HowItWorks;
