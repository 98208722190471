export enum ApplicationStatus {
    starting = "starting",
    resume = "resume",
    processing = "processing",
    readyToSign = "readyToSign",
    pendingIv = "pendingIv",
    thanks = "thanks",
    declined = "declined",
    counterOffer = "counterOffer",
    processingCounterOffer = "processingCounterOffer",
    error = "error",
    invalidBankDetails = "invalidBankDetails",
    invalidIncome = "invalidIncome",
    timeout = "timeout",
}