import React from "react";
import classes from "../Input.module.scss";

interface IInputDisplayOnlyProps {
  id?: string;
  type?: "text" | "password" | "number";
  value?: string | number;
}

const InputDisplayOnly: React.FunctionComponent<IInputDisplayOnlyProps> = (
  props
) => {
  return (
    <>
      <div className={classes["input-container"]}>
        <input
          type={props.type || "text"}
          id={props.id}
          value={props.value || ""}
          disabled={true}
          className={classes["disabled-background"]}
        />
      </div>
    </>
  );
};

export default InputDisplayOnly;
