import React, { useContext, useState } from 'react';
import classes from './AppBar.module.scss';
import { AppBar as AppBarBase, Box, Container, styled, Toolbar } from '@mui/material';
import wongaLogo from '../../assets/img/webp/logo.webp';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouterRoutes } from '../../utils/RouterRoutes';
import { useAuthentication } from '../../contexts/AuthContext';
import { navigateToExternalPage } from '../../utils/Helpers/NavigationHelper';
import { LimiterContext } from '../../contexts/LimiterContext';
import { useAppSettings } from '../../contexts/AppSettingsContext';
import MobileMenu from './MobileMenu';
import DesktopMenu from './DesktopMenu';

export interface MobileMenuItem {
  title: string;
  onClick: () => void;
  showItem: boolean;
  showBorderBottom: boolean;
  showActive: boolean;
}

export interface DesktopMenuItem {
  title: string;
  onClick: () => void;
  showItem: boolean;
  isPrimaryButton?: boolean;
  showActive: boolean;
  isDisabled?: boolean;
}

const MuiAppBar = styled(AppBarBase)(({ theme }) => ({
  background: theme.palette.common.white,
  boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.01),0px 1px 10px 0px rgba(0,0,0,0.012)',
  height: '91px',
  paddingTop: '0',
  justifyContent: 'center',
}));

const AppBar: React.FunctionComponent = () => {
  const appSettings = useAppSettings();
  const funeralCoverUrl = appSettings.insurance.funeralCoverUrl;
  const carInsuranceUrl = appSettings.insurance.carInsuranceUrl;
  const carInsuranceEnabled = appSettings.insurance.carInsuranceEnabled;
  const funeralCoverEnabled = appSettings.insurance.funeralCoverEnabled;
  const moneyAcademyUrl = 'https://www.moneyacademy.co.za/home';
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, logout } = useAuthentication();
  const [anchorNavElement, setAnchorNavElement] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorNavElement);
  const { showLimiter } = useContext(LimiterContext);

  const isInJourney =
    location.pathname.includes(RouterRoutes.newJourney) || location.pathname.includes(RouterRoutes.existingJourney);
  const counterofferProcessingPath = `/${RouterRoutes.counteroffer}`;
  const showNavigation = !isInJourney && location.pathname !== counterofferProcessingPath;
  const disableLoginButton = !isAuthenticated && showLimiter;

  const onMyLoanPage = location.pathname.includes(RouterRoutes.myLoan);
  const onMyDetailsPage = location.pathname.includes(RouterRoutes.myDetails);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorNavElement(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorNavElement(null);
  };

  const redirectTo = (url: string, isExternalLink?: boolean) => {
    handleCloseNavMenu();
    isExternalLink ? window.open(url, '_blank') : navigate(url);
  };

  const desktopMenuItems: DesktopMenuItem[] = [
    {
      title: 'Car Insurance',
      onClick: () => redirectTo(carInsuranceUrl, true),
      showItem: carInsuranceEnabled,
      showActive: false,
    },
    {
      title: 'Funeral Cover',
      onClick: () => redirectTo(funeralCoverUrl, true),
      showItem: funeralCoverEnabled,
      showActive: false,
    },
    {
      title: 'Money Academy',
      onClick: () => redirectTo(moneyAcademyUrl, true),
      showItem: true,
      showActive: false,
    },
    {
      title: 'My Loan',
      onClick: () => redirectTo(RouterRoutes.myLoan),
      showItem: isAuthenticated && !showLimiter,
      showActive: onMyLoanPage,
    },
    {
      title: 'My Details',
      onClick: () => redirectTo(RouterRoutes.myDetails),
      showItem: isAuthenticated && !showLimiter,
      showActive: onMyDetailsPage,
    },
    {
      title: 'Support',
      onClick: () => {
        handleCloseNavMenu();
        navigateToExternalPage('/support');
      },
      showItem: true,
      showActive: false,
    },
    {
      title: 'Log in',
      onClick: () => redirectTo(RouterRoutes.login),
      showItem: !isAuthenticated,
      isPrimaryButton: true,
      showActive: false,
      isDisabled: disableLoginButton,
    },
    {
      title: 'Log out',
      onClick: () => {
        handleCloseNavMenu();
        logout();
      },
      showItem: isAuthenticated,
      isPrimaryButton: true,
      showActive: false,
    },
  ];

  const mobileMenuItems: MobileMenuItem[] = [
    {
      title: 'Log in',
      onClick: () => redirectTo(RouterRoutes.login),
      showItem: !isAuthenticated,
      showBorderBottom: true,
      showActive: false,
    },
    {
      title: 'My Details',
      onClick: () => redirectTo(RouterRoutes.myDetails),
      showItem: isAuthenticated && !showLimiter,
      showBorderBottom: true,
      showActive: onMyDetailsPage,
    },
    {
      title: 'My Loan',
      onClick: () => redirectTo(RouterRoutes.myLoan),
      showItem: isAuthenticated && !showLimiter,
      showBorderBottom: false,
      showActive: onMyLoanPage,
    },
    {
      title: 'Car Insurance',
      onClick: () => redirectTo(carInsuranceUrl, true),
      showItem: carInsuranceEnabled,
      showBorderBottom: false,
      showActive: false,
    },
    {
      title: 'Funeral Cover',
      onClick: () => redirectTo(funeralCoverUrl, true),
      showItem: funeralCoverEnabled,
      showBorderBottom: false,
      showActive: false,
    },
    {
      title: 'Money Academy',
      onClick: () => redirectTo(moneyAcademyUrl, true),
      showItem: true,
      showBorderBottom: true,
      showActive: false,
    },
    {
      title: 'Support',
      onClick: () => {
        handleCloseNavMenu();
        navigateToExternalPage('/support');
      },
      showItem: true,
      showBorderBottom: isAuthenticated,
      showActive: false,
    },
    {
      title: 'Log out',
      onClick: () => {
        handleCloseNavMenu();
        logout();
      },
      showItem: isAuthenticated,
      showBorderBottom: false,
      showActive: false,
    },
  ];

  return (
    <MuiAppBar className={classes['container']} role="banner" position="static">
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          {/* Desktop */}
          <Box sx={{ display: { xs: 'none', md: 'flex' } }} className={classes.logo}>
            <WongaLogoIcon />
          </Box>
          {showNavigation && <DesktopMenu desktopMenuItems={desktopMenuItems} />}

          {/* Mobile */}
          {showNavigation ? (
            <MobileMenu
              anchorNavElement={anchorNavElement}
              isOpen={open}
              handleCloseNavMenu={handleCloseNavMenu}
              handleOpenNavMenu={handleOpenNavMenu}
              mobileMenuItems={mobileMenuItems}
            />
          ) : (
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', md: 'none' },
                alignSelf: 'center',
                justifyContent: 'center',
              }}
              className={classes.logo}
            >
              <WongaLogoIcon />
            </Box>
          )}
        </Toolbar>
      </Container>
    </MuiAppBar>
  );
};

export const WongaLogoIcon = () => {
  const navigate = useNavigate();
  return (
    <img
      src={wongaLogo}
      aria-label="Navigate to home"
      width="117px"
      height="39px"
      onClick={() => {
        navigate(RouterRoutes.home);
      }}
    />
  );
};

export default AppBar;
