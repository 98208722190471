import { Box, styled, Stack, Button } from '@mui/material';
import React from 'react';
import classes from './AppBar.module.scss';
import { DesktopMenuItem } from './AppBar';

interface DesktopMenuProps {
  desktopMenuItems: DesktopMenuItem[];
}

const NavButton = styled(Button)(({ theme }) => ({
  paddingTop: '1rem',
  paddingBottom: '1rem',
  position: 'relative',
  color: '#777777',
  backgroundColor: 'transparent',
  fontWeight: '300',
  fontSize: '1.4rem !important',
  margin: '.3rem',
  marginRight: '2.2rem',
  letterSpacing: '-0.5px',
  lineHeight: '1.5rem',
  transition: 'color .5s ease-out',
  '&:hover ': {
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
  },
}));

const ActiveNavButton = styled(Button)(({ theme }) => ({
  paddingTop: '1rem',
  paddingBottom: '1rem',
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.grey[200],
  fontWeight: '600',
  fontSize: '1.4rem !important',
  margin: '.3rem',
  marginRight: '2.2rem',
  letterSpacing: '-0.5px',
  lineHeight: '1.5rem',
  transition: 'color .5s ease-out',
  borderRadius: '0.8rem',
  '&:hover ': {
    backgroundColor: theme.palette.grey['200'],
    color: theme.palette.primary.main,
  },
}));

const DesktopMenu: React.FunctionComponent<DesktopMenuProps> = (props: DesktopMenuProps) => {
  const { desktopMenuItems } = props;

  const buildDesktopMenu = (item: DesktopMenuItem, index: number) => {
    if (item.isPrimaryButton) {
      return (
        <Button
          key={index}
          color="primary"
          variant="contained"
          disabled={item.isDisabled}
          onClick={item.onClick}
          classes={{ root: classes.primaryButton }}
        >
          {item.title}
        </Button>
      );
    } else if (item.showActive) {
      return (
        <ActiveNavButton size="large" variant="contained" disableElevation key={index} onClick={item.onClick}>
          {item.title}
        </ActiveNavButton>
      );
    } else {
      return (
        <NavButton key={index} onClick={item.onClick}>
          {item.title}
        </NavButton>
      );
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: { xs: 'none', md: 'flex' },
        justifyContent: 'flex-end',

        alignItems: 'center',
      }}
    >
      <Stack flexDirection="row" justifyContent="space-evenly" alignItems="center">
        {desktopMenuItems.filter((item) => item.showItem).map(buildDesktopMenu)}
      </Stack>
    </Box>
  );
};

export default DesktopMenu;
