
import React, {useState} from 'react';
import styles from '../Flap-Container/Flap-container.module.scss';

const FlapContainer = (props) => {

    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>
    <div className={`${styles["wonga-flap-container"]} ${isOpen && styles.open} ${props.theme === "dark" ? styles.dark : ''} ${props.theme === "light" ? styles.light : ''}`}>
        <div className={styles["dropdown-label-container"]} onClick={toggleOpen}>
            <div className={styles["dropdown-label-text-container"]}>
                <p>{props.dropdownText}</p>
            </div>
            <div className={styles["dropdown-label-arrow-container"]}>
                <div className={styles["dropdown-label-arrow"]}></div>
            </div>
        </div>
        <div className={styles["flap-container-content"]}>
            {props.children}
        </div>
    </div>
    </>
    );
};

export default FlapContainer;