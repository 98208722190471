import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { RouterRoutes } from "../utils/RouterRoutes";
import { useLocation } from "react-router-dom";

const descriptionMetaContent = [
    {
        route: RouterRoutes.login,
        content: 'Login to Wonga.'
    },
    {
        route: RouterRoutes.myLoan,
        content: 'View your Wonga account.'
    },
    {
        route: RouterRoutes.recoverPassword,
        content: 'Recover your Wonga account password.'
    },
    {
        route: RouterRoutes.confirmPasswordReset,
        content: 'Reset your Wonga account password.'
    },
    {
        route: RouterRoutes.register,
        content: 'Register for a Wonga account to access short term loans up to R4000 for new customers.'
    },
    {
        route: 'Default',
        content: 'Short term loans from R500 - R4000. Fast, flexible and 100% online. No branch visits required, no hidden charges. Quick online decision, cash straight to your bank account if approved.'
    }
];

export const SeoContext = React.createContext(null);

export const useSeoContext = () => {
    return useContext(SeoContext);
};

interface SeoContextProps {
    children: React.ReactNode
}

export const SeoContextProvider = (props: SeoContextProps) => {
    const location = useLocation();
    const defaultDescription = descriptionMetaContent.find((item) => item.route === "Default")?.content;
    const [description, setDescription] = useState(defaultDescription);

    useEffect(() => {
        const matchedDescription = descriptionMetaContent.find((item) => location.pathname.includes(item.route))?.content;
        if (!matchedDescription) setDescription(defaultDescription ?? "");
        else setDescription(matchedDescription ?? "");
    }, [location, defaultDescription]);

    return (
        <SeoContext.Provider value={null}>
            <Helmet>
                <title>Wonga Official Site - Short Term Loans Online</title>
                <meta name="description" content={description} />
            </Helmet>
            {props.children}
        </SeoContext.Provider>
    );
};

export default SeoContextProvider;