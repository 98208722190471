import { EmploymentStatusEnum } from "../../clients/AccountClient";
import { differenceInCalendarMonths, differenceInCalendarYears, sub } from "date-fns";
import { utcFormattedDate } from "./DateHelpers";
import { utcToZonedTime } from "date-fns-tz";

export const shouldShowEmployer = (employmentStatus: string): boolean =>
    employmentStatus === EmploymentStatusEnum.EmployedFullTime ||
    employmentStatus === EmploymentStatusEnum.EmployedPartTime ||
    employmentStatus === EmploymentStatusEnum.EmployedTemporary;

export const shouldShowTime = (showEmployer: boolean, employmentStatus: string): boolean =>
    showEmployer || employmentStatus === EmploymentStatusEnum.Student || employmentStatus === EmploymentStatusEnum.ArmedForces;

export const shouldShowWorkNumber = (showEmployer: boolean, employmentStatus: string): boolean => showEmployer || employmentStatus === EmploymentStatusEnum.SelfEmployed;

export const shouldShowUniversity = (employmentStatus: string): boolean => employmentStatus === EmploymentStatusEnum.Student;

export const shouldShowArmedForces = (employmentStatus: string): boolean => employmentStatus === EmploymentStatusEnum.ArmedForces;

export const getEmployedOnDate = (years: string, months: string): string => {
    const now = new Date();

    return utcFormattedDate(sub(now, {
        years: +years,
        months: +months
    }), 'yyyy-MM-dd');
}

export const getYears = (employedOn?: Date) => {
    if (!employedOn)
        return "";

    return differenceInCalendarYears(utcToZonedTime(new Date(), 'UTC'), employedOn).toString();
}

export const getMonths = (employedOn?: Date) => {
    if (!employedOn)
        return "";

    const totalMonths = differenceInCalendarMonths(utcToZonedTime(new Date(), 'UTC'), employedOn);
    const remainingMonths = totalMonths % 12;
    return remainingMonths.toString();
}

export { };