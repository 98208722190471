import { Grid, FormLabel } from '@mui/material';
import React, { ReactNode } from 'react';
import { FULL_COLUMN_SIZE, THIRD_COLUMN_SIZE } from '../../utils/GridColumnSizeDefinitions';

interface FormInputLableProps {
  children: ReactNode;
}

const FormInputLabel: React.FC<FormInputLableProps> = (props) => {
  return (
    <Grid
      item
      xs={FULL_COLUMN_SIZE}
      sm={THIRD_COLUMN_SIZE}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: { xs: 0, md: 1.4 },
      }}
    >
      <FormLabel sx={{ marginTop: { md: 2, xs: 'none', fontWeight: 300, width: "100%" } }}>{props.children}</FormLabel>
    </Grid>
  );
};

export default FormInputLabel;
