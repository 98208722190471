import { Button } from "@mui/material";
import React, { ReactNode } from "react";

interface TextButtonProps {
    children: ReactNode;
    type?: "button" | "submit" | "reset";
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    removePadding?: boolean;
}

const TextButton = ({ type, onClick, disabled, children, removePadding }: TextButtonProps) => {
    return (
        <Button
            disabled={disabled}
            variant="text"
            type={type}
            onClick={onClick}
            sx={removePadding ?
                {
                    height: '5rem',
                    padding: 0,
                    fontWeight: 300,
                    minWidth: 0,
                    "&.MuiButtonBase-root:hover": {
                        bgcolor: "transparent"
                    }
                }
                :
                {
                    width: { md: '12.5rem', xs: '100%' },
                    height: '5rem',
                    fontWeight: 300,
                    "&.MuiButtonBase-root:hover": {
                        bgcolor: "transparent"
                    }
                }}
        >
            {children}
        </Button>
    );
};

export default TextButton;
