import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import useLoanClient from "../../../../../hooks/loan/Client";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useCallback, useEffect } from "react";
import { WaitApplicationResponse } from "../../../../../clients/LoanClient";
import { useSnackBar } from "../../../../../contexts/SnackBarContext";
import Loading from "../../../../../components/Loading/Loading";
import { useTracking } from "../../../../../Tracking/TrackingContext";
import { navigateToDocumentUpload, navigateToTermsAndConditions } from "../../../../../utils/Helpers/NavigationHelper";
import { PostProcessingRoutes, RouterRoutes } from "../../../../../utils/RouterRoutes";

const CounterofferProcessing: React.FC = () => {
  const loanClient = useLoanClient();
  const { displaySnackBar } = useSnackBar();
  const location = useLocation();
  const loanId = location.state["loanId"] ?? "";
  const { isExistingCustomer } = useTracking();
  const navigate = useNavigate();

  const waitApplication = useCallback(async (loanId: string) => {
    let response: WaitApplicationResponse | undefined;

    for (let i = 0; i < 10; i++) {
      response =
        await loanClient.waitApplication(
          loanId,
          30,
          true,
          false,
          false,
          false,
          false,
          false
        );

      if (response.success || response.applicationInfo?.isDeclined) break;
    }
    return response;
  }, [loanClient]);

  const handleDecision = (waitApplicationResponse: WaitApplicationResponse, loanId: string) => {
    const isRequireSign = waitApplicationResponse?.applicationAdvice?.requireSign;
    const isRequireDocuments = waitApplicationResponse?.applicationAdvice?.requireDocuments;
    const isDeclined = waitApplicationResponse?.applicationInfo?.isDeclined;
    const success = waitApplicationResponse?.success;
    // Added !isDeclined to this because when the application is declined, success is returned as false
    const isTimedOut = !success && !isDeclined;
    const counterOfferAvailable = waitApplicationResponse?.applicationAdvice?.counterOffer;
    if (isRequireSign) {
      navigateToTermsAndConditions(navigate, loanId, isExistingCustomer, true);
    } else if (isRequireDocuments) {
      navigateToDocumentUpload(navigate, loanId, isExistingCustomer, true);
    } else if (isDeclined) {
      navigate(`/${isExistingCustomer ? RouterRoutes.existingJourney : RouterRoutes.newJourney}/${PostProcessingRoutes.declined}`, { replace: true });
    } else if (counterOfferAvailable) {
      navigate(`/${isExistingCustomer ? RouterRoutes.existingJourney : RouterRoutes.newJourney}/${PostProcessingRoutes.counterOffer}`, { replace: true });
    } else if (isTimedOut) {
      navigate(`/${RouterRoutes.myLoan}`, { replace: true });
    }
  };

  const processCounteroffer = async () => {
    try {
      const waitApplicationResponse = await waitApplication(loanId);

      // eslint-disable-next-line
      handleDecision(waitApplicationResponse!, loanId);
    } catch (error) {
      displaySnackBar("Oops! An unexpected error has occurred.", "error");
    }
  };

  useEffect(() => {
    processCounteroffer();
    // eslint-disable-next-line
  }, []);

  return (
    /* eslint-disable  @typescript-eslint/no-non-null-assertion */
    <Stack flexGrow={1} alignItems="center" padding={"4rem 2rem 4rem 2rem"}>
      <Typography variant="h2" textAlign={"center"}>We are processing your counter offer application</Typography>
      <Loading />
      <Typography variant="body1" marginTop={2}>
        This may take up to <b>five minutes</b>.
      </Typography>
      <Typography variant="body1" marginTop={2}>
        Please be patient and do not close your browser.
      </Typography>
    </Stack>
  );
};

export default CounterofferProcessing;
