/* eslint-disable @typescript-eslint/no-explicit-any */
import { Controller, useFormContext } from "react-hook-form";
import { FieldName } from "./types";
import CustomInput from "../Input/CustomInput";

interface FormControlProps {
  name: FieldName;
  label: string;
  placeholder?: string;
  type?: string;
  control: any;
  register: any;
  mobileNumberForm?: any;
  disabled?: boolean;
  currencyField?: boolean;
  salaryDay?: number;
  disableError?: boolean;
}

const InputFormController: React.FC<FormControlProps> = (props) => {
  const { control, name, register, currencyField, type, salaryDay, disableError } = props;
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <CustomInput
          currencyField={currencyField}
          name={props.name}
          label={props.label}
          type={type}
          salaryDay={salaryDay}
          disabled={props.disabled}
          placeholder={props.placeholder}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          register={register}
          isError={!!errors[name]}
          errorText={disableError ? "" : errors[name]?.message}
          disableError={disableError}
        />
      )}
    />
  );
};

export default InputFormController;
