import { Box, Button, Container, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import searchBlue from '../../assets/img/webp/search.webp';

const PageNotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: { xs: 2, md: 10 },
          marginBottom: { xs: 5, md: 5 },
        }}
      >
        <Stack sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            component="img"
            sx={{
              width: 150,
              transform: 'rotate(-45deg)',
            }}
            alt=""
            src={searchBlue}
          />
          <Typography variant="h2" sx={{ textAlign: 'center' }} fontSize={{ xs: '25px', md: '40px' }}>
            {"Unfortunately, the page you're looking for doesn't exist."}
          </Typography>
          <Typography variant="body1" textAlign={'center'} marginTop={1}>
            {"Let's get you back on track - you may have mistyped the address or the page may have moved."}
          </Typography>
        </Stack>
        <Box padding={5} width={'100%'} display={'flex'} justifyContent={'center'}>
          <Button
            color="primary"
            variant="contained"
            type={'button'}
            onClick={() => {
              navigate('/');
            }}
            sx={{
              width: { sm: '20rem', xs: '100%' },
              height: '5rem',
              borderRadius: '0.8rem',
              boxShadow: 'none',
              ':hover': {
                boxShadow: 'none',
              },
            }}
          >
            Back To Home
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default PageNotFound;
