import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import parse from "html-react-parser";
import { useTheme } from "@mui/material";
import Loading from "../Loading/Loading";

interface IProps {
  url: string;
}

export default function AgreementTemplate(props: IProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [legalDocumentContent, setLegalDocumentContent] = useState<string>("");
  const theme = useTheme();

  const downloadLoanAgreementContent = (url: string) => {
    setLoading(true);

    const headers = {
      "Content-Type": "text/plain",
    };

    fetch(url, {
      method: "GET",
      mode: "cors",
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
          console.log("Failed to generate Loan Agreement data", response);
        }
        return response.text();
      })
      .then((data) => {
        setLegalDocumentContent(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    downloadLoanAgreementContent(props.url);
  }, [props.url]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "40rem",
          width: "inherit",
          overflow: "hidden",
          margin: "auto",
          overflowX: "scroll",
          overflowY: "scroll",
          marginY: 0.125,
          paddingX: 0.6,
          border: `0.2rem solid ${theme.palette.grey["400"]}`,
          borderRadius: "8px",
        }}
      >
        {loading ? <Loading /> : parse(legalDocumentContent)}
      </Box>
    </>
  );
}
