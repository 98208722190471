import { Turnstile } from '@marsidev/react-turnstile';
import React, { forwardRef } from 'react';
import type { TurnstileInstance } from '@marsidev/react-turnstile';
import { useTracking } from '../../Tracking/TrackingContext';
import TurnstileSize from '../../utils/Turnstile';

interface CFTurnstileProps {
  /** SiteKey for the appropriate action */
  siteKey: string;
  /** This needs to match the enum in Account.Api which corresponds to a secret key, important for analytics in CF */
  action: string;
  /** Name of the parent for better error tracking */
  component: string;
  /** Prefered display style */
  turnstileSize: TurnstileSize;
  setTurnstileSize: React.Dispatch<React.SetStateAction<TurnstileSize>>;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
}

export const CFTurnstile = forwardRef<TurnstileInstance, CFTurnstileProps>(function CFTurnstile(props, ref) {
  const { siteKey, action, component, turnstileSize, setTurnstileSize, setToken } = props;
  const { TrackError } = useTracking();

  const resetTurnstile = (errorCode: string) => {
    TrackError(`${component}: Challenge failed or a network error has occured. ErrorCode=${errorCode}`);
    setTurnstileSize('normal');
    if (ref && typeof ref !== 'function' && ref.current) {
      ref.current?.reset();
    }
  };

  const onSuccessCallback = (token: string) => {
    setToken(token);
    setTurnstileSize('invisible');
  };

  const onBeforeInteractive = () => {
    setTurnstileSize('normal');
  };

  return (
    <Turnstile
      onSuccess={onSuccessCallback}
      onError={resetTurnstile}
      onBeforeInteractive={onBeforeInteractive}
      ref={ref}
      siteKey={siteKey}
      options={{
        action: action,
        theme: 'light',
        language: 'en',
        size: turnstileSize,
      }}
      scriptOptions={{
        appendTo: 'body',
      }}
    />
  );
});
