import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';

import {
  ICustomerDetails,
  UpdateCustomerResponse,
  CustomerDetails,
  HomeLanguageEnum,
  HomeStatusEnum,
  MaritalStatusEnum,
  IContactDetails,
  UpdateContactResponse,
  ContactDetails,
  ErrorResponse,
} from '../../../clients/AccountClient';
import { Form, useForm } from '../../../components/Form/Form';
import FormInputLabel from '../../../components/Form/FormInputLabel';
import FormInputWrapper from '../../../components/Form/FormInputWrapper';
import FormWrapper from '../../../components/Form/FormWrapper';
import Loading from '../../../components/Loading/Loading';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import useAccountClient from '../../../hooks/account/Client';
import { customerDetailsSchema } from '../../../schemas/Schemas';
import { FULL_COLUMN_SIZE } from '../../../utils/GridColumnSizeDefinitions';
import SelectFormController from '../../../components/MuiInput/FormControllers/SelectFormController';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import ChangeLoanValues from '../../../components/ChangeLoanValues/ChangeLoanValues';

import SubscribeOnMail from './SubscribeOnMail/SubscribeOnMail';
import {
  dependentMappings,
  homeLanguageMappings,
  homeStatusMappings,
  maritalStatusMappings,
} from '../../../models/SelectOptions';
import { useWizardOutletContext } from '../Wizard';
import ButtonLoadingIndicator from '../../../components/Loading/ButtonLoadingIndicator';
import { useTracking } from '../../../Tracking/TrackingContext';

/* eslint-disable  @typescript-eslint/no-unused-vars*/
const CustomerDetailsPage: React.FunctionComponent = () => {
  const { next } = useWizardOutletContext();
  const { displaySnackBar } = useSnackBar();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const client = useAccountClient();
  const [contactData, setContactData] = useState<IContactDetails>();
  const [customerData, setCustomerData] = useState<ICustomerDetails>();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(true);
  const { TrackError } = useTracking();

  const customerDetailsForm = useForm({
    criteriaMode: 'all',
    mode: 'onBlur',
    schema: customerDetailsSchema,
  });

  const fetchData = async () => {
    try {
      const contactPromise = client.getContact();
      const customerPromise = client.getCustomer();
      const [contactResponse, customerResponse] = await Promise.all([contactPromise, customerPromise]);

      if (customerResponse && contactResponse) {
        setContactData(contactResponse.contact);
        setCustomerData(customerResponse.customer);
        customerDetailsForm.setValue('dependents', customerResponse.customer?.dependents?.toString() ?? 'Unspecified');
        customerDetailsForm.setValue(
          'homeLanguage',
          customerResponse.customer?.homeLanguageEnum
            ? customerResponse.customer?.homeLanguageEnum
            : HomeLanguageEnum.Unspecified
        );
        customerDetailsForm.setValue(
          'homeStatus',
          customerResponse.customer?.homeStatusEnum
            ? customerResponse.customer?.homeStatusEnum
            : HomeStatusEnum.Unspecified
        );
        customerDetailsForm.setValue(
          'maritalStatus',
          customerResponse.customer?.maritalStatusEnum
            ? customerResponse.customer?.maritalStatusEnum
            : MaritalStatusEnum.Unspecified
        );
        customerDetailsForm.setValue('marketing', true);
        setIsFetchingData(false);
      }
    } catch (error) {
      displaySnackBar('We’re currently experiencing a temporary technical issue. Please try again later.', 'error');
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  /* eslint-disable  @typescript-eslint/no-explicit-any*/
  const updateCustomer = async (data: any) => {
    setSubmitLoading(true);

    try {
      const customer: ICustomerDetails = {
        ...customerData,
        dependents: data.dependents,
        homeLanguageEnum: data.homeLanguage,
        homeStatusEnum: data.homeStatus,
        maritalStatusEnum: data.maritalStatus,

        marriedInCommunityProperty: data.maritalStatus === '2',
      };

      const updateCustomerPromise: Promise<UpdateCustomerResponse> = client.updateCustomer(
        new CustomerDetails(customer)
      );

      const contact: IContactDetails = {
        ...contactData,
        marketing: data.marketing,
      };

      const updateContactPromise: Promise<UpdateContactResponse> = client.updateContact(new ContactDetails(contact));

      const results = await Promise.allSettled([updateCustomerPromise, updateContactPromise]);

      const customerUpdatedSuccessfully = results[0].status === 'fulfilled' && results[0].value.customerUpdated;
      const contactUpdatedSuccessfully = results[1].status === 'fulfilled' && results[1].value.contactUpdated;

      if (customerUpdatedSuccessfully && contactUpdatedSuccessfully) {
        displaySnackBar('Customer Details saved', 'success');
        next();
      }
    } catch (error) {
      if (error instanceof ErrorResponse && error.errorCode === 400) {
        TrackError(error, error.message);
        displaySnackBar(error.message ?? "An validation error has occurred", 'error');
      } else {
        displaySnackBar('Oops, an error has occurred please try again', 'error');
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  if (isFetchingData) return <Loading text="Please wait while we retrieve your details." />;

  return (
    <>
      <ChangeLoanValues />
      <FormWrapper title="My Details">
        <Form form={customerDetailsForm} onSubmit={updateCustomer}>
          <Grid container>
            <FormInputLabel>Home Language</FormInputLabel>
            <FormInputWrapper>
              <SelectFormController
                name="homeLanguage"
                label="Home language"
                menuItems={homeLanguageMappings}
                register={customerDetailsForm.register}
                control={customerDetailsForm.control}
              />
            </FormInputWrapper>

            <FormInputLabel>Home Status</FormInputLabel>
            <FormInputWrapper>
              <SelectFormController
                name="homeStatus"
                label="Home Status"
                menuItems={homeStatusMappings}
                register={customerDetailsForm.register}
                control={customerDetailsForm.control}
              />
            </FormInputWrapper>

            <FormInputLabel>Marital status</FormInputLabel>
            <FormInputWrapper>
              <SelectFormController
                name="maritalStatus"
                label="Marital status"
                menuItems={maritalStatusMappings}
                register={customerDetailsForm.register}
                control={customerDetailsForm.control}
              />
            </FormInputWrapper>

            <FormInputLabel>Number of dependents</FormInputLabel>
            <FormInputWrapper>
              <SelectFormController
                name="dependents"
                label="Dependents"
                menuItems={dependentMappings}
                register={customerDetailsForm.register}
                control={customerDetailsForm.control}
              />
            </FormInputWrapper>
          </Grid>

          <SubscribeOnMail customerDetailsForm={customerDetailsForm} />
          <Box sx={{ padding: '0 0 5rem 0' }}>
            {submitLoading && <ButtonLoadingIndicator />}
            {!submitLoading && (
              <Grid container alignItems="center">
                <Grid
                  item
                  xs={FULL_COLUMN_SIZE}
                  display="flex"
                  justifyContent={{
                    xs: 'flex-end',
                  }}
                >
                  <PrimaryButton type="submit">Next</PrimaryButton>
                </Grid>
              </Grid>
            )}
          </Box>
        </Form>
      </FormWrapper>
    </>
  );
};

export default CustomerDetailsPage;
