/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import classNames from "classnames";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { FieldName, MenuItem, RegisterProps } from "../FormControllers/types";
import classes from "./InputSelect.module.scss";

interface SelectComponentProps {
  name: FieldName;
  value: string;
  type?: string;
  isError?: boolean;
  errorText?:
  | string
  | FieldError
  | Merge<FieldError, FieldErrorsImpl<any>>
  | undefined;
  required?: boolean;
  register?: RegisterProps;
  onBlur?: (...event: any[]) => void;
  menuItems: MenuItem[];
  disabled?: boolean;
}

const InputSelect: React.FC<SelectComponentProps> = ({
  name,
  isError,
  errorText,
  register,
  required,
  value,
  onBlur,
  menuItems,
  disabled,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const hasValue = value && value != "Unspecified";

  const inputClass = classNames(classes.select, {
    [classes.invalid]: isError && !isFocused && !disabled,
    [classes.focus]: !isError && isFocused && !disabled,
    [classes.valid]: hasValue && !isError && !isFocused && !disabled,
  });

  const rest = register && register(name);

  const handleBlur = () => {
    if (onBlur) {
      onBlur();
    }
    setIsFocused(false);
  };

  return (
    <div className={classes.inputContainer}>
      <select
        disabled={disabled}
        className={inputClass}
        required={required}
        value={value}
        {...rest}
        onBlur={handleBlur}
        onFocus={() => setIsFocused(true)}
      >
        {/* <option value="" disabled>
          Please select
        </option> */}
        {menuItems &&
          menuItems.map((item) => (
            <option
              key={item.value}
              value={item.value}
              disabled={item.value === "" || item.value === "Unspecified"}
            >
              {item.key}
            </option>
          ))}
      </select>
      {isError && (
        <div className={classes.errorText}>{errorText?.toString()}</div>
      )}
    </div>
  );
};

export default InputSelect;
