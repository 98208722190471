import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { RadioButton } from '../../../../../components/RadioButton/RadioButton';
import { marketingPreferenceSchema } from '../../../../../schemas/Schemas';
import { Form, useForm } from '../../../../../components/Form/Form';
import { ContactDetails, ErrorResponse, IContactDetails } from '../../../../../clients/AccountClient';
import Loading from '../../../../../components/Loading/Loading';
import '../../../../../styles/_forms.scss';
import '../../../../../styles/_buttons.scss';
import { LABEL_COLUMN_SIZE, INPUT_COLUMN_SIZE, FULL_COLUMN_SIZE } from '../../../../../utils/GridColumnSizeDefinitions';
import useAccountClient from '../../../../../hooks/account/Client';
import { Box, Stack, Typography } from '@mui/material';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import TextButton from '../../../../../components/Buttons/TextButton';
import { useTracking } from '../../../../../Tracking/TrackingContext';
import { useSnackBar } from '../../../../../contexts/SnackBarContext';

interface IChangeMarketingLNProps {
    closeEditing: (isUpdated: boolean) => Promise<void>;
    contact: ContactDetails;
}

const options = [
    {
        label: 'Yes',
        value: true,
    },
    {
        label: 'No',
        value: false,
    },
];

const ChangeMarketingLN: React.FunctionComponent<IChangeMarketingLNProps> = ({
    contact,
    closeEditing,
}: IChangeMarketingLNProps) => {
    const client = useAccountClient();
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const { TrackError } = useTracking();
    const { displaySnackBar } = useSnackBar();

    const marketingPreferenceForm = useForm({
        schema: marketingPreferenceSchema,
    });

    useEffect(() => {
        marketingPreferenceForm.setValue('marketing', contact.marketing ?? false);
    }, [contact, marketingPreferenceForm]);

    const updateContactDetails = async () => {
        try {
            setIsSubmitLoading(true);
            const updateContact: IContactDetails = { ...contact };
            updateContact.marketing = stringToBoolean(marketingPreferenceForm.getValues('marketing').toString());
            const response = await client.updateContact(new ContactDetails(updateContact));
            if (response !== null) {
                await closeEditing(true);
            }
        } catch (error) {
            if (error instanceof ErrorResponse && error.errorCode === 400) {
                TrackError(error, error.message);
                displaySnackBar(error.message ?? "An validation error has occurred", 'error');
            } else {
                displaySnackBar('Oops, an error has occurred please try again', 'error');
            }
        } finally {
            setIsSubmitLoading(false);
        }
    };

    const stringToBoolean = (value: string) => {
        return value === 'true';
    };

    return (
        <>
            <Form form={marketingPreferenceForm} onSubmit={() => console.log()}>
                <Grid container marginBottom={'2.2rem'}>
                    <Grid item xs={FULL_COLUMN_SIZE}>
                        <Typography
                            variant="body1"
                            fontWeight={'400'}
                            letterSpacing={'-0.05rem'}
                            fontSize={{ xs: "2.4rem" }}
                        >
                            Please update your communication preference below.
                        </Typography>
                    </Grid>
                    <Grid item sm={FULL_COLUMN_SIZE} sx={{ display: { xs: 'none', sm: 'initial' } }}>
                        <Typography
                            variant="body1"
                            fontWeight={'300'}
                            lineHeight={'2.2rem'}
                            letterSpacing={'-0.04rem'}
                            fontSize={'1.8rem'}
                            marginTop={'1.5rem'}
                        >
                            Your current communication prefernce is indicated below.
                        </Typography>
                    </Grid>
                    <Grid item xs={FULL_COLUMN_SIZE} sm={INPUT_COLUMN_SIZE}>
                        <Typography
                            variant="body1"
                            fontWeight={'300'}
                            lineHeight={'2.2rem'}
                            letterSpacing={'-0.04rem'}
                            marginTop={'1.5rem'}
                        >
                            I am happy to receive updates and other communications from Wonga via email and sms.
                        </Typography>
                    </Grid>
                    <Grid item xs={FULL_COLUMN_SIZE} sm={LABEL_COLUMN_SIZE} marginTop={'1rem'}>
                        <Box display="flex" flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'} flexGrow={1}>
                            <RadioButton name="marketing" control={marketingPreferenceForm.control} options={options} />
                        </Box>
                    </Grid>
                    {isSubmitLoading ? (
                        <Grid item xs={FULL_COLUMN_SIZE}>
                            <Stack
                                alignItems={{ xs: 'center', sm: 'flex-end' }}
                                marginTop={'1rem'}
                            >
                                <Box textAlign={"center"} width={{ xs: "100%", sm: "25rem" }}>
                                    <Loading />
                                </Box>
                            </Stack>
                        </Grid>
                    ) : (
                        <>
                            <Grid item xs={FULL_COLUMN_SIZE}>
                                <Stack
                                    flexGrow={1}
                                    flexDirection={{ xs: 'column', sm: 'row' }}
                                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                                    justifyContent={{ xs: 'center', sm: 'space-between' }}
                                    marginTop={'1rem'}
                                >
                                    <TextButton removePadding onClick={() => closeEditing(false)}>Cancel</TextButton>
                                    <PrimaryButton type="submit" onClick={updateContactDetails}>
                                        Update
                                    </PrimaryButton>
                                </Stack>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Form>
        </>
    );
};

export default ChangeMarketingLN;
