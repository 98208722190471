import React, { useState } from 'react';
import { theme } from '../../../../theme/Theme';
import { FormControlLabel, Radio, RadioGroup, Typography, useMediaQuery } from '@mui/material';
import infoLogo from '../../../../assets/img/info-icon.png';
import styles from './PepFormField.module.scss';
import PepDefinition from './PepDefinition';

interface Option {
  label: string;
  value: boolean;
}

interface PepFormFieldProps {
  isPipOrPep: boolean;
  showPipOrPepDefinition: boolean;
  setShowPipOrPepDefinition: React.Dispatch<React.SetStateAction<boolean>>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}

const PepFormField: React.FunctionComponent<PepFormFieldProps> = (props) => {
  const { isPipOrPep, showPipOrPepDefinition, setShowPipOrPepDefinition, onChange } = props;
  const [showInput, setShowInput] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const options: Option[] = [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ];

  const RadioOptions = () => {
    return options.map((data: Option) => (
      <FormControlLabel value={data.value} key={data.label} label={data.label} control={<Radio />} />
    ));
  };

  return (
    <div className={isMobile ? styles['pep-form-field-container-mobile'] : styles['pep-form-field-container-desktop']}>
      <div className={styles['pep-form-field-paragraph']}>
        Are you, or anyone you’re associated with, a{' '}
        <Typography
          component={'span'}
          fontSize={'1.5rem'}
          fontWeight={300}
          lineHeight={'2.2rem'}
          color={theme.palette.primary.main}
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => setShowInput((prev) => !prev)}
        >
          Politically Exposed Person?
        </Typography>
        <img
          className={styles['pep-form-field-info-logo']}
          onClick={() => setShowPipOrPepDefinition(true)}
          src={infoLogo}
        />
        <PepDefinition
          showPipOrPepDefinition={showPipOrPepDefinition}
          setShowPipOrPepDefinition={setShowPipOrPepDefinition}
        />
      </div>
      {showInput && (
        <RadioGroup
          row={isMobile ? false : true}
          value={isPipOrPep}
          onChange={onChange}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            '& .MuiSvgIcon-root': {
              fontSize: 28,
            },
          }}
        >
          {RadioOptions()}
        </RadioGroup>
      )}
    </div>
  );
};

export default PepFormField;
