import { datadogRum } from '@datadog/browser-rum';
import { AppSettings } from '../../models/AppSettings';
import { datadogLogs } from '@datadog/browser-logs';

export const InitDatadogRum = (appSettings: AppSettings) => {
  datadogRum.init({
    applicationId: appSettings.datadogConfig.applicationId,
    clientToken: appSettings.datadogConfig.clientToken,
    site: appSettings.datadogConfig.site,
    service: appSettings.datadogConfig.service,
    enableExperimentalFeatures: [appSettings.datadogConfig.enableExperimentalFeatures],
    env: appSettings.datadogConfig.env,
    sessionSampleRate: appSettings.datadogConfig.sessionSampleRate,
    sessionReplaySampleRate: appSettings.datadogConfig.sessionReplaySampleRate,
    trackUserInteractions: appSettings.datadogConfig.trackUserInteractions,
    trackResources: appSettings.datadogConfig.trackResources,
    trackLongTasks: appSettings.datadogConfig.trackLongTasks,
    defaultPrivacyLevel: appSettings.datadogConfig.defaultPrivacyLevel,
    version: appSettings.datadogConfig.version,
  });

  datadogRum.startSessionReplayRecording();
};

export const InitDatadogLogs = (appSettings: AppSettings) => {
  datadogLogs.init({
    clientToken: appSettings.datadogConfig.clientToken,
    site: appSettings.datadogConfig.site,
    service: appSettings.datadogConfig.service,
    env: appSettings.datadogConfig.env,
    version: appSettings.datadogConfig.version,
    sessionSampleRate: appSettings.datadogConfig.sessionSampleRate,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['warn', 'error'],
    storeContextsAcrossPages: true,
  });
};
