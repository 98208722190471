import { differenceInDays, getYear } from "date-fns";
import regex from "./RegularExpressions";

export function ValidateIdNumber(value: string) {
    const re = new RegExp(regex.idNumber);

    if (!re.test(value) || !validateSAID(value)) {
        return "This is not a valid RSA ID";
    }

    if (!calculateValidAge(value)) {
        return "You must be 18 years or older";
    }
}

const hasValidDate = (id: string) => {
    // The first 6 digits (YYMMDD) represents date of birth
    const yy = id.substring(0, 2);
    const dd = id.substring(4, 6)
    const mm = id.substring(2, 4)
    // The second last digit (C) shows if you're an SA (0) citizen or permanent resident (1) 
    const c = id.substring(10, 11)

    if (!dd.match(/^\d{2}$/) || !mm.match(/^\d{2}$/)) {
        return false;
    }
    // ensure third to last is a 1 or 0
    if (Number(c) > 1) {
        return false;
    }
    // constructing the dob of the customer
    const dob: Date = new Date(Number(yy), Number(mm) - 1, Number(dd));

    if (
        !(
            dob.getFullYear().toString().substring(2, 4) === yy &&
            dob.getMonth() === Number(mm) - 1 &&
            dob.getDate() === Number(dd)
        )
    ) {
        return false;
    }

    return true;
}

const isOdd = (value: number): boolean => {
    return value % 2 !== 0;
}

function validateSAID(id: string): boolean {
    let result = false;
    let sum = 0;
    const size = id.length - 1;

    for (let i = 0; i <= size; i++) {
        let digit: number = parseInt(id[size - i]);

        if (isOdd(i)) {
            digit = digit * 2;

            if (digit > 9) {
                let subSum = 0;

                while (digit > 0) {
                    subSum += digit % 10;
                    digit = Math.floor(digit / 10);
                }

                digit = subSum;
            }
        }

        sum += digit;
    }

    result = sum % 10 === 0;
    return result && hasValidDate(id);

}

const calculateValidAge = (idNumber: string) => {
    const birthDate = getBirthDate(idNumber);

    const daysOld = calculateAge(birthDate);

    let valid;
    if (daysOld >= 6570 && daysOld < 36500) {
        valid = true;
    } else {
        valid = false;
    }

    return valid;
};

const getBirthDate = (idNumber: string) : Date => {
    const year = idNumber.substring(0, 2);
    const currentYear = new Date().getFullYear() % 100;
    let prefix = "19";

    if (+year < currentYear) prefix = "20";

    const fullYear = prefix + year;

    const month = idNumber.substring(2, 4);
    const day = idNumber.substring(4, 6);
    const date = new Date(parseInt(fullYear), parseInt(month) - 1, parseInt(day));

    return date;
};

const calculateAge = (birthday: Date) => {
    let daysOld = differenceInDays(new Date(), birthday);

    const birthYear = getYear(birthday);

    const leapDays = leapYears(birthYear, daysOld);

    daysOld = daysOld - leapDays;

    return daysOld;
};

const leapYears = (birthYear: number, daySold: number) => {
    const age = Math.floor(daySold / 365);

    let countLeapYears = 0;

    for (let i = 0; i <= age; i++) {
        birthYear = birthYear + 1;

        if (birthYear % 4 === 0 && birthYear % 10 !== 0) {
            countLeapYears = countLeapYears + 1;
        } else if (
            birthYear % 4 === 0 &&
            birthYear % 10 === 0 &&
            birthYear % 40 === 0
        ) {
            countLeapYears = countLeapYears + 1;
        }
    }
    return countLeapYears;
};
