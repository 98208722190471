import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import { FULL_COLUMN_SIZE, TWO_THIRDS_COLUMN_SIZE } from '../../utils/GridColumnSizeDefinitions';

interface FormInputWrapperProps {
  children: ReactNode;
}

const FormInputWrapper: React.FC<FormInputWrapperProps> = (props) => {
  return (
    <Grid
      item
      xs={FULL_COLUMN_SIZE}
      sm={TWO_THIRDS_COLUMN_SIZE}
      sx={{
        marginTop: { xs: 0, md: 1.5 },
      }}
    >
      {props.children}
    </Grid>
  );
};

export default FormInputWrapper;
