import { useTracking } from '../../../../Tracking/TrackingContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { navigateToDocumentUpload } from '../../../../utils/Helpers/NavigationHelper';
import { Container, Stack, Typography } from '@mui/material';
import { formatMoney } from '../../../../utils/Helpers/SliderHelperFunctions';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import styles from "./FailedOpenBanking.module.scss";
import React from 'react';

export const FailedOpenBanking = () => {
  const navigate = useNavigate();
  const { isExistingCustomer } = useTracking();
  const location = useLocation();
  const loanId = location && location.state ? location.state["loanId"] ?? "" : "";
  const loanAmount = location && location.state ? location.state["loanAmount"] : 0;
  const title = location && location.state ? location.state["title"] : "";

  const redirectToDocumentUpload = () => {
    navigateToDocumentUpload(navigate, loanId, isExistingCustomer);
  };

  return (
    <>
      <Container className={styles["uploadDoc-container"]}>
        <Typography className={styles["uploadDoc-title"]}>
          {title}
        </Typography>

        <Typography className={styles['loan-amount']}>
          <b>R {formatMoney(loanAmount  ?? 0)}</b>
        </Typography>
        <p style={{textAlign: "center"}}> Application Amount </p>

        <Typography style={{paddingTop: '20px'}}>
          To proceed with your application, please upload your bank statements.
        </Typography>

        <Stack sx={{  marginTop: { xs: '50px', md: '100px' } }} direction={"row"} justifyContent={"right"}>
          <PrimaryButton onClick={redirectToDocumentUpload}>
            <Typography variant="body1" fontSize="1.5rem" fontWeight={300}>
              Upload documents
            </Typography>
          </PrimaryButton>
        </Stack>

      </Container>
    </>
  );
}
