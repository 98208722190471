/* eslint  @typescript-eslint/no-non-null-assertion: 0 */
/* Turn rule off for file as used for setting values on page from response. If response is null then logic should remain. */
import { Box, Container, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IGetBankResponse, IGetPersonalDetailsResponse } from '../../../../../clients/AccountClient';
import InputDisplayOnly from '../../../../../components/Input/DisplayOnly/InputDisplayOnly';
import Loading from '../../../../../components/Loading/Loading';
import useAccountClient from '../../../../../hooks/account/Client';

import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import { MaskBankAccountNumber } from '../../../../../components/Validation/MaskBankAccountNumber';
import useLoanClient from '../../../../../hooks/loan/Client';
import '../../../../../styles/_buttons.scss';
import '../../../../../styles/_forms.scss';
import { FULL_COLUMN_SIZE, INPUT_COLUMN_SIZE, LABEL_COLUMN_SIZE } from '../../../../../utils/GridColumnSizeDefinitions';
import TextButton from '../../../../../components/Buttons/TextButton';
import ChangeLoanValuesNewDesign from '../../../../../components/ChangeLoanValues/ChangeLoanValuesNewDesign';
import sharedStyles from '../_common.module.scss';
import ChangeBankLN from './ChangeBank';
import ChangeAddressLN from './ChangeAddress';
import ChangeContactLN from './ChangeContact';
import ChangeMarketingLN from './ChangeMarketing';
import { useWizardOutletContext } from '../../../Wizard';

/* eslint-disable  @typescript-eslint/no-unused-vars*/
const ConfirmCustomerDetails: React.FunctionComponent = () => {
  const [personalDetailsResponse, setPersonalDetailsResponse] = useState<IGetPersonalDetailsResponse | null>();
  const [bankDetailsResponse, setBankDetailsResponse] = useState<IGetBankResponse>();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const showChangeMobilePhone = useRef(false);
  const showChangeAddressDetails = useRef(false);
  const showChangeBankingDetails = useRef(false);
  const showChangeMarketingPreference = useRef(false);
  const accountClient = useAccountClient();
  const loanClient = useLoanClient();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { next } = useWizardOutletContext();

  const getCustomerData = useCallback(async () => {
    try {
      const response = await accountClient.getPersonalDetails();
      const bankDetailsPromise = accountClient.getBankAccount();
      const openLoanDetailsPromise = loanClient.getOpenLoanDetails(undefined);
      const [bankDetailsResponse] = await Promise.all([bankDetailsPromise, openLoanDetailsPromise]);
      setPersonalDetailsResponse(response);
      setBankDetailsResponse(bankDetailsResponse);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, [accountClient, loanClient]);

  useEffect(() => {
    getCustomerData();
  }, [getCustomerData]);

  const toggleChangeMobilePhoneComponent = () => {
    showChangeMobilePhone.current = true;
    setIsEditing(true);
  };

  const toggleChangeAddressDetailsComponent = () => {
    showChangeAddressDetails.current = true;
    setIsEditing(true);
  };

  const toggleChangeBankingDetails = () => {
    showChangeBankingDetails.current = !showChangeBankingDetails.current;
    setIsEditing(true);
  };

  const toggleMarketingPreferenceComponent = () => {
    showChangeMarketingPreference.current = true;
    setIsEditing(true);
  };

  const onCloseEditing = async (isUpdated: boolean) => {
    showChangeMobilePhone.current = false;
    showChangeAddressDetails.current = false;
    showChangeBankingDetails.current = false;
    showChangeMarketingPreference.current = false;
    if (isUpdated) await getCustomerData();
    setIsEditing(false);
  };

  if (isLoading) return <Loading text="Please wait while we retrieve your details" />;

  const handleLoanValueEdited = (editing: boolean) => {
    setIsEditing(editing);
  };

  const mobilePhoneContent = (
    <>
      <Grid
        item
        xs={FULL_COLUMN_SIZE}
        xl={LABEL_COLUMN_SIZE}
        lg={LABEL_COLUMN_SIZE}
        md={LABEL_COLUMN_SIZE}
        sm={LABEL_COLUMN_SIZE}
        sx={{ marginTop: isMobile ? '0' : '1.6rem' }}
      >
        <label style={{ paddingLeft: 0, fontWeight: 300 }} htmlFor="mobileNumber">
          Cell phone number
        </label>
      </Grid>
      <Grid
        item
        xs={FULL_COLUMN_SIZE}
        xl={INPUT_COLUMN_SIZE}
        lg={INPUT_COLUMN_SIZE}
        md={INPUT_COLUMN_SIZE}
        sm={INPUT_COLUMN_SIZE}
      >
        <InputDisplayOnly
          id="mobileNumber"
          value={
            personalDetailsResponse?.personalDetails?.contact
              ? personalDetailsResponse?.personalDetails.contact!.mobileNumber
              : ''
          }
        />
      </Grid>
      <Grid
        item
        xs={FULL_COLUMN_SIZE}
        xl={LABEL_COLUMN_SIZE}
        lg={LABEL_COLUMN_SIZE}
        md={LABEL_COLUMN_SIZE}
        sm={LABEL_COLUMN_SIZE}
      ></Grid>
      <Grid
        item
        xs={FULL_COLUMN_SIZE}
        xl={INPUT_COLUMN_SIZE}
        lg={INPUT_COLUMN_SIZE}
        md={INPUT_COLUMN_SIZE}
        sm={INPUT_COLUMN_SIZE}
        style={{ height: '60px' }}
        display="flex"
        alignItems="flex-start"
      >
        {!isEditing && (
          <TextButton removePadding onClick={toggleChangeMobilePhoneComponent}>
            Change your cell phone number
          </TextButton>
        )}
      </Grid>
    </>
  );

  const bankContent = (
    <>
      <Grid
        item
        xs={FULL_COLUMN_SIZE}
        xl={LABEL_COLUMN_SIZE}
        lg={LABEL_COLUMN_SIZE}
        md={LABEL_COLUMN_SIZE}
        sm={LABEL_COLUMN_SIZE}
        sx={{ marginTop: isMobile ? '0' : '1.6rem' }}
      >
        <label style={{ paddingLeft: 0, fontWeight: 300 }} htmlFor="mobileNumber">
          Bank Account number
        </label>
      </Grid>
      <Grid
        item
        xs={FULL_COLUMN_SIZE}
        xl={INPUT_COLUMN_SIZE}
        lg={INPUT_COLUMN_SIZE}
        md={INPUT_COLUMN_SIZE}
        sm={INPUT_COLUMN_SIZE}
      >
        <InputDisplayOnly value={MaskBankAccountNumber(bankDetailsResponse?.bank?.accountNumber)} />
      </Grid>
      <Grid
        item
        xs={FULL_COLUMN_SIZE}
        xl={LABEL_COLUMN_SIZE}
        lg={LABEL_COLUMN_SIZE}
        md={LABEL_COLUMN_SIZE}
        sm={LABEL_COLUMN_SIZE}
      ></Grid>
      <Grid
        item
        xs={FULL_COLUMN_SIZE}
        xl={INPUT_COLUMN_SIZE}
        lg={INPUT_COLUMN_SIZE}
        md={INPUT_COLUMN_SIZE}
        sm={INPUT_COLUMN_SIZE}
        style={{ height: '60px' }}
        display="flex"
        alignItems="flex-start"
      >
        {!isEditing && (
          <TextButton removePadding onClick={toggleChangeBankingDetails}>
            Change your bank account
          </TextButton>
        )}
      </Grid>
    </>
  );

  const changeBankContent = (
    <>
      <ChangeBankLN bank={bankDetailsResponse?.bank} closeEditing={onCloseEditing} />
    </>
  );

  const changeMobilePhoneContent = (
    <>
      <ChangeContactLN
        closeEditing={onCloseEditing}
        contact={personalDetailsResponse?.personalDetails?.contact}
      ></ChangeContactLN>
    </>
  );

  const addressContent = (
    <>
      <Grid
        item
        xs={FULL_COLUMN_SIZE}
        xl={LABEL_COLUMN_SIZE}
        lg={LABEL_COLUMN_SIZE}
        md={LABEL_COLUMN_SIZE}
        sm={LABEL_COLUMN_SIZE}
        sx={{ marginTop: isMobile ? '0' : '1.6rem' }}
      >
        <label style={{ paddingLeft: 0, fontWeight: 300 }} htmlFor="fullAddress">
          Current Address
        </label>
      </Grid>
      <Grid
        item
        xs={FULL_COLUMN_SIZE}
        xl={INPUT_COLUMN_SIZE}
        lg={INPUT_COLUMN_SIZE}
        md={INPUT_COLUMN_SIZE}
        sm={INPUT_COLUMN_SIZE}
      >
        <InputDisplayOnly
          id="fullAddress"
          type="text"
          value={
            personalDetailsResponse?.personalDetails?.address
              ? `${personalDetailsResponse?.personalDetails!.address?.houseNumber} ${
                  personalDetailsResponse?.personalDetails!.address?.street
                }`
              : ''
          }
        />
      </Grid>
      <Grid
        item
        xs={FULL_COLUMN_SIZE}
        xl={LABEL_COLUMN_SIZE}
        lg={LABEL_COLUMN_SIZE}
        md={LABEL_COLUMN_SIZE}
        sm={LABEL_COLUMN_SIZE}
      >
        <label htmlFor="town"></label>
      </Grid>
      <Grid
        item
        xs={FULL_COLUMN_SIZE}
        xl={INPUT_COLUMN_SIZE}
        lg={INPUT_COLUMN_SIZE}
        md={INPUT_COLUMN_SIZE}
        sm={INPUT_COLUMN_SIZE}
      >
        <InputDisplayOnly id="town" type="text" value={personalDetailsResponse?.personalDetails!.address?.town} />
      </Grid>

      <Grid
        item
        xs={FULL_COLUMN_SIZE}
        xl={LABEL_COLUMN_SIZE}
        lg={LABEL_COLUMN_SIZE}
        md={LABEL_COLUMN_SIZE}
        sm={LABEL_COLUMN_SIZE}
      >
        <label htmlFor="postCode"></label>
      </Grid>
      <Grid
        item
        xs={FULL_COLUMN_SIZE}
        xl={INPUT_COLUMN_SIZE}
        lg={INPUT_COLUMN_SIZE}
        md={INPUT_COLUMN_SIZE}
        sm={INPUT_COLUMN_SIZE}
      >
        <InputDisplayOnly
          id="postCode"
          type="text"
          value={personalDetailsResponse?.personalDetails!.address?.postCode}
        />
      </Grid>
      <Grid
        item
        xs={FULL_COLUMN_SIZE}
        xl={LABEL_COLUMN_SIZE}
        lg={LABEL_COLUMN_SIZE}
        md={LABEL_COLUMN_SIZE}
        sm={LABEL_COLUMN_SIZE}
      ></Grid>
      <Grid
        item
        xs={FULL_COLUMN_SIZE}
        xl={INPUT_COLUMN_SIZE}
        lg={INPUT_COLUMN_SIZE}
        md={INPUT_COLUMN_SIZE}
        sm={INPUT_COLUMN_SIZE}
        style={{ height: '60px' }}
        display="flex"
        alignItems="flex-start"
      >
        {!isEditing && (
          <TextButton removePadding onClick={toggleChangeAddressDetailsComponent}>
            Change your address
          </TextButton>
        )}
      </Grid>
    </>
  );

  const changeAddressContent = (
    <>
      <ChangeAddressLN closeEditing={onCloseEditing} address={personalDetailsResponse?.personalDetails?.address} />
    </>
  );

  const marketingPreferencesContent = (
    <>
      {!isMobile && (
        <Grid item xs={FULL_COLUMN_SIZE} sm={LABEL_COLUMN_SIZE}>
          <label style={{ fontWeight: 300 }}>Preferences</label>
        </Grid>
      )}
      <Grid item xs={FULL_COLUMN_SIZE} sm={INPUT_COLUMN_SIZE}>
        {personalDetailsResponse?.personalDetails!.contact?.marketing ? (
          <label style={{ fontWeight: '300', letterSpacing: '-0.03rem' }}>
            Happy to receive communication from Wonga
          </label>
        ) : (
          <label style={{ fontWeight: '300', letterSpacing: '-0.03rem' }}>
            Not happy to receive communication from Wonga
          </label>
        )}
      </Grid>
      <Grid item xs={FULL_COLUMN_SIZE} sm={LABEL_COLUMN_SIZE}></Grid>
      <Grid item xs={FULL_COLUMN_SIZE} sm={LABEL_COLUMN_SIZE}>
        {!isEditing && (
          <TextButton removePadding onClick={toggleMarketingPreferenceComponent}>
            Change your preferences
          </TextButton>
        )}
      </Grid>
      <Grid item xs={FULL_COLUMN_SIZE} sm={LABEL_COLUMN_SIZE}></Grid>
    </>
  );

  const changeMarketingPreferenceContent = (
    <>
      <ChangeMarketingLN closeEditing={onCloseEditing} contact={personalDetailsResponse?.personalDetails?.contact!} />
    </>
  );

  return (
    <>
      <Container maxWidth="lg" sx={{ padding: { md: '1.6rem 5rem 5rem 5rem', xs: '0.6rem 2rem 5rem 2rem' } }}>
        <ChangeLoanValuesNewDesign onEditing={handleLoanValueEdited} />
        <Grid container marginLeft={'0rem'}>
          <Grid
            item
            xs={FULL_COLUMN_SIZE}
            xl={FULL_COLUMN_SIZE}
            lg={FULL_COLUMN_SIZE}
            md={FULL_COLUMN_SIZE}
            sm={FULL_COLUMN_SIZE}
          >
            <Stack gap={2} marginBottom={isMobile ? 2 : 1}>
              <Typography
                variantMapping={{ h3: 'h1' }}
                sx={{
                  fontWeight: 400,
                  marginTop: 2,
                  fontSize: isMobile ? '24px' : '32px',
                }}
              >
                Loan application
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 400,
                  fontSize: isMobile ? '15px' : '24px',
                }}
              >
                Please confirm that the details we have for you are correct.{' '}
              </Typography>
            </Stack>
          </Grid>

          {!showChangeMobilePhone.current ? mobilePhoneContent : changeMobilePhoneContent}
          <Grid
            item
            xs={FULL_COLUMN_SIZE}
            xl={FULL_COLUMN_SIZE}
            lg={FULL_COLUMN_SIZE}
            md={FULL_COLUMN_SIZE}
            sm={FULL_COLUMN_SIZE}
          >
            <hr className={sharedStyles['divider']} />
          </Grid>
          {!showChangeAddressDetails.current ? addressContent : changeAddressContent}
          <Grid
            item
            xs={FULL_COLUMN_SIZE}
            xl={FULL_COLUMN_SIZE}
            lg={FULL_COLUMN_SIZE}
            md={FULL_COLUMN_SIZE}
            sm={FULL_COLUMN_SIZE}
          >
            <hr className={sharedStyles['divider']} />
          </Grid>
          {!showChangeBankingDetails.current ? bankContent : changeBankContent}
          <Grid
            item
            xs={FULL_COLUMN_SIZE}
            xl={FULL_COLUMN_SIZE}
            lg={FULL_COLUMN_SIZE}
            md={FULL_COLUMN_SIZE}
            sm={FULL_COLUMN_SIZE}
          >
            <hr className={sharedStyles['divider']} />
          </Grid>
          {!showChangeMarketingPreference.current ? marketingPreferencesContent : changeMarketingPreferenceContent}

          <Box width={'100%'}>
            <Stack alignItems={{ md: 'flex-end', xs: 'center' }}>
              <PrimaryButton
                disabled={isEditing}
                type="submit"
                onClick={() => {
                  next();
                }}
              >
                Next
              </PrimaryButton>
            </Stack>
          </Box>
        </Grid>
      </Container>
    </>
  );
};

export default ConfirmCustomerDetails;
