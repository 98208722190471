import React, { useEffect, useState } from "react";
import { executeIovationScript } from "./static/iovation";
import { AdBlockPopup } from "../AdBlockPopup/AdBlockPopup";

export const Iovation = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  // Not used currently
  const [openAdBlockPopup, setOpenAdBlockPopup] = useState(false);


  const handleAdBlockPopupClose = () => {
    setOpenAdBlockPopup(false);
  };

  useEffect(() => {
    /*
        In the code below, if the window object IGLOO already exists, it's not created
        hence the OR operator ||

       window.io_global_object_name = "IGLOO";: This line is creating a global variable named io_global_object_name and setting its value to the string "IGLOO".

       window.IGLOO = window.IGLOO || {...};: This line is creating a global object named IGLOO and setting its value to an object.
       If window.IGLOO already exists (which might have been set in a previous script execution), the existing value is used; otherwise, a new object is created.

      Inside this object, there are three properties:

      enable_flash: This property is set to false.
      bb_callback: This is a function that takes two parameters, bb (which seems to be a string) and complete (which seems to be a boolean).
      If complete is true, the function sets an item in the browser's localStorage with the key "blackbox" and the value of bb. It then returns true.
      loader: This is an object with a version property set to "general5". There's also a commented-out property called trace_handler which,
       if uncommented and defined as a function, could potentially handle a trace event.
     */

    window.io_global_object_name = "IGLOO";
    window.IGLOO = window.IGLOO || {
      "enable_flash": false,
      "bb_callback": function (bb: string, complete: boolean) {
        if (complete) {
          localStorage.setItem("blackbox", bb);
          return true;
        }
      },
      "loader": {
        "version": "general5",
        // "trace_handler": () => {
        //   setOpenAdBlockPopup(true); // Errors could be caused by other reasons, and not necessarily a failure to acquire Blackbox data
        // }
      }
    };

    if (!scriptLoaded) {
      executeIovationScript();
      console.log("Iovation script executed.");
      setScriptLoaded(true);
    }
  }, [scriptLoaded]);

  return (
    <>
      <AdBlockPopup handleDialogClose={handleAdBlockPopupClose} open={openAdBlockPopup} />
    </>
  );
}
