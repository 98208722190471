/* eslint  @typescript-eslint/no-non-null-assertion: 0 */
import React, { useEffect, useState, useCallback } from 'react';
import {
  employmentStatusMappings,
  incomeFrequencyMappings,
  industryMappings,
  positionMappings,
  yearsMappings,
  monthsMappings,
} from '../../../../../models/SelectOptions';
import '../../../../../styles/_forms.scss';
import './../../../../../styles/_buttons.scss';
import {
  CustomerDetails,
  EmploymentDetails,
  EmploymentIndustryEnum,
  EmploymentStatusEnum,
  ErrorResponse,
  ICustomerDetails,
  IEmploymentDetails,
  IGetCustomerResponse,
  IGetEmploymentResponse,
  IncomeFrequencyEnum,
  PositionEnum,
  UpdateEmploymentResponse,
} from '../../../../../clients/AccountClient';
import {
  shouldShowEmployer,
  shouldShowWorkNumber,
  shouldShowTime,
  shouldShowUniversity,
  shouldShowArmedForces,
  getEmployedOnDate,
  getYears,
  getMonths,
} from '../../../../../utils/Helpers/Employment';
import { Form, useForm } from '../../../../../components/Form/Form';
import { Box, Container, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import FormInputWrapper from '../../../../../components/Form/FormInputWrapper';
import InputFormController from '../../../../../components/MuiInput/FormControllers/InputFormController';
import FormInputLabel from '../../../../../components/Form/FormInputLabel';
import RepaymentDate from '../../../../../components/Sliders/RepaymentDate';
import SelectFormController from '../../../../../components/MuiInput/FormControllers/SelectFormController';
import { FULL_COLUMN_SIZE, TWO_THIRDS_COLUMN_SIZE } from '../../../../../utils/GridColumnSizeDefinitions';
import { employmentDetailsSchema } from '../../../../../schemas/Schemas';
import { useSnackBar } from '../../../../../contexts/SnackBarContext';
import useAccountClient from '../../../../../hooks/account/Client';
import { theme } from '../../../../../theme/Theme';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import Loading from '../../../../../components/Loading/Loading';
import PepFormField from '../../PepDefinition/PepFormField';
import TextButton from '../../../../../components/Buttons/TextButton';
import { useTracking } from '../../../../../Tracking/TrackingContext';

interface IChangeEmploymentDetailsLNProps {
  employmentDetails?: IGetEmploymentResponse;
  customerDetails?: IGetCustomerResponse;
  onCloseEditing: () => Promise<void>;
}

const ChangeEmploymentDetailsLN: React.FunctionComponent<IChangeEmploymentDetailsLNProps> = (props) => {
  const [showEmployer, setShowEmployer] = useState(false);
  const [showWorkNumber, setShowWorkNumber] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [showUniversity, setShowUniversity] = useState(false);
  const [showArmedForces, setShowArmedForces] = useState(false);
  const [timeWithText, setTimeWithText] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const { displaySnackBar } = useSnackBar();
  const client = useAccountClient();
  const [showRepaymentDatePicker, setShowRepaymentDatePicker] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isPipOrPep, setIsPipOrPep] = useState(false);
  const [showPipOrPepDefinition, setShowPipOrPepDefinition] = useState(false);
  const { TrackError } = useTracking();

  const employmentForm = useForm({
    criteriaMode: 'all',
    mode: 'onBlur',
    schema: employmentDetailsSchema,
  });

  const showNetIncomeHint = !employmentForm.getFieldState('netIncome').invalid;

  const getSalaryDayFromLocalStorage = (): string => {
    const applyValues = localStorage.getItem('applyValues');
    if (applyValues) {
      const salaryDay = JSON.parse(applyValues)['salaryDay'];
      return `${salaryDay == 0 ? '' : salaryDay}`;
    }
    return '';
  };

  useEffect(() => {
    employmentForm.setValue('years', getYears(props.employmentDetails?.employment?.employedOn));
    employmentForm.setValue('months', getMonths(props.employmentDetails?.employment?.employedOn));

    employmentForm.setValue(
      'employerName',
      props.employmentDetails?.employment?.employerName ? props.employmentDetails.employment!.employerName! : ''
    );
    employmentForm.setValue(
      'position',
      props.employmentDetails?.employment?.positionEnum
        ? props.employmentDetails.employment!.positionEnum!
        : PositionEnum.Unspecified
    );
    employmentForm.setValue(
      'industry',
      props.employmentDetails?.employment?.industryEnum
        ? props.employmentDetails.employment!.industryEnum!
        : EmploymentIndustryEnum.Unspecified
    );
    employmentForm.setValue(
      'incomeFrequency',
      props.employmentDetails?.employment?.incomeFrequencyEnum
        ? props.employmentDetails.employment!.incomeFrequencyEnum
        : IncomeFrequencyEnum.Unspecified
    );
    employmentForm.setValue(
      'salaryDay',
      // If a day was picked from Sliders, use that date even though the user has a salary date from a previous journey in the backend response
      // if a day was not picked from Sliders (for STLs), use day from previous journey (from database)
      // In case the neither condition is met, the user has to select a new salary day.
      getSalaryDayFromLocalStorage().trim() !== ''
        ? getSalaryDayFromLocalStorage()
        : props.employmentDetails?.employment?.salaryDay
        ? props.employmentDetails?.employment!.salaryDay!.toString()
        : ''
    );
    employmentForm.setValue(
      'employmentStatus',
      props.employmentDetails?.employment?.employmentStatusEnum
        ? props.employmentDetails.employment!.employmentStatusEnum
        : EmploymentStatusEnum.Unspecified
    );
    employmentForm.setValue(
      'grossIncome',
      props.employmentDetails?.employment?.grossIncome
        ? props.employmentDetails.employment!.grossIncome!.toString()
        : ''
    );
    employmentForm.setValue(
      'netIncome',
      props.employmentDetails?.employment?.netIncome ? props.employmentDetails.employment!.netIncome!.toString() : ''
    );
    employmentForm.setValue(
      'workPhone',
      props.employmentDetails?.employment?.workPhone ? props.employmentDetails.employment!.workPhone! : ''
    );

    employmentForm.setValue('university', '');
    employmentForm.setValue('division', '');
    setIsPipOrPep(
      props.customerDetails?.customer?.isPip !== undefined ? props.customerDetails?.customer?.isPip : false
    );
  }, [props.employmentDetails, props.customerDetails, employmentForm]);

  const currentEmploymentStatus = employmentForm.watch('employmentStatus');

  const determineFieldsToDisplay = useCallback(() => {
    setShowEmployer(() => {
      return shouldShowEmployer(currentEmploymentStatus);
    });

    setShowWorkNumber(() => {
      return shouldShowWorkNumber(showEmployer, currentEmploymentStatus);
    });

    setShowTime(() => {
      return shouldShowTime(showEmployer, currentEmploymentStatus);
    });

    setShowUniversity(() => {
      return shouldShowUniversity(currentEmploymentStatus);
    });

    setShowArmedForces(() => {
      return shouldShowArmedForces(currentEmploymentStatus);
    });
  }, [currentEmploymentStatus, showEmployer]);

  const determineTimeWithText = useCallback(() => {
    if (showEmployer) {
      return 'Time with employer';
    }
    if (showUniversity) {
      return 'Time with university';
    }
    if (showArmedForces) {
      return 'Time with service';
    }
    return '';
  }, [showEmployer, showUniversity, showArmedForces]);

  const updateSalaryDayInLocalStorage = (salaryDay: string) => {
    const applyValuesString = localStorage.getItem('applyValues');
    const applyValues = JSON.parse(applyValuesString ?? '');
    if (applyValues) {
      localStorage.setItem('applyValues', JSON.stringify({ ...applyValues, salaryDay }));
    }
  };

  useEffect(() => {
    if (props.employmentDetails) {
      determineFieldsToDisplay();
      setTimeWithText(() => determineTimeWithText());
    }
  }, [props.employmentDetails, determineFieldsToDisplay, determineTimeWithText]);

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  /* This should be revisited, employedOn is part of IEmploymentDetails but is explicitly ignored. */
  const updateEmployment = async (data: any) => {
    const employ: IEmploymentDetails = {
      ...props.employmentDetails,
      employerName: data.employerName.trim(),
      employmentStatusEnum: data.employmentStatus,
      workPhone: data.workPhone,
      industryEnum: data.industry,
      positionEnum: data.position,
      incomeFrequencyEnum: data.incomeFrequency,
      salaryDay: data.salaryDay,
      grossIncome: data.grossIncome,
      netIncome: data.netIncome,
      employedOn:
        (data.years && data.months) === ''
          ? new Date('1/1/0001')
          : new Date(getEmployedOnDate(data.years, data.months)),
    };

    try {
      const employment: UpdateEmploymentResponse = await client.updateEmployment(new EmploymentDetails(employ));

      if (employment.employmentDetailsUpdated) {
        updateSalaryDayInLocalStorage(data.salaryDay);
        await props.onCloseEditing();
        displaySnackBar('Employment Details saved', 'success');
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
      if (error instanceof ErrorResponse && error.errorCode === 400) {
        TrackError(error, error.message);
        displaySnackBar(error.message ?? "An validation error has occurred", 'error');
      } else {
        displaySnackBar('Oops, an error has occurred please try again', 'error');
      }
    }
  };

  const updateCustomer = async () => {
    const customer: ICustomerDetails = {
      ...props.customerDetails?.customer,
      isPip: isPipOrPep,
    };

    try {
      await client.updateCustomer(new CustomerDetails(customer));
    } catch (error) {
      setSubmitLoading(false);
      if (error instanceof ErrorResponse && error.errorCode === 400) {
        TrackError(error, error.message);
        displaySnackBar(error.message ?? "An validation error has occurred", 'error');
      } else {
        displaySnackBar('Oops, an error has occurred please try again', 'error');
      }
    }
  };

  const onFormSubmit = async (data: any) => {
    setSubmitLoading(true);
    await updateCustomer();
    await updateEmployment(data);
  };

  const onSalaryDayPick = (day: number) => {
    employmentForm.setValue('salaryDay', day.toString());
    employmentForm.trigger('salaryDay');
    setShowRepaymentDatePicker(false);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const optInValue = event.target.value === 'true';
    setIsPipOrPep(optInValue);
    setShowPipOrPepDefinition(optInValue);
  };

  return (
    <>
      <Typography variant="h2" fontSize={{ xs: '2.4rem', sm: '3.2rem' }} marginBottom={'2.4rem'}>
        Employment details
      </Typography>
      <Form form={employmentForm} onSubmit={onFormSubmit}>
        <Grid container style={{ position: 'relative' }} marginBottom={'2.2rem'}>
          <FormInputLabel>Employment Status</FormInputLabel>
          <FormInputWrapper>
            <SelectFormController
              name="employmentStatus"
              label="Employment Status"
              menuItems={employmentStatusMappings}
              register={employmentForm.register}
              control={employmentForm.control}
            />
          </FormInputWrapper>
          {showEmployer && (
            <>
              <FormInputLabel>Employer Name</FormInputLabel>
              <FormInputWrapper>
                <InputFormController
                  name="employerName"
                  label="Employer Name"
                  placeholder="Employer Name"
                  register={employmentForm.register}
                  control={employmentForm.control}
                />
              </FormInputWrapper>
            </>
          )}

          <FormInputLabel>Gross monthly income (before tax)</FormInputLabel>
          <FormInputWrapper>
            <InputFormController
              name="grossIncome"
              label="Gross Income"
              placeholder=""
              register={employmentForm.register}
              control={employmentForm.control}
              currencyField
            />
          </FormInputWrapper>

          <FormInputLabel>Net monthly income (after tax)</FormInputLabel>
          <FormInputWrapper>
            <InputFormController
              name="netIncome"
              label="Net Income"
              placeholder=""
              register={employmentForm.register}
              control={employmentForm.control}
              currencyField
            />
            {showNetIncomeHint && (
              <Box display="flex" justifyContent="left" height={'1rem'}>
                <Typography
                  justifyContent="left"
                  marginTop={'-1.1rem'}
                  sx={{
                    fontWeight: 300,
                    fontSize: '1.5rem',
                    fontStyle: 'italic',
                    lineHeight: '2rem',
                    color: '#757575',
                  }}
                >
                  *Your salary amount <span style={{ fontWeight: 400 }}>paid into your bank account</span>
                </Typography>
              </Box>
            )}
          </FormInputWrapper>
          {showArmedForces && (
            <>
              <FormInputLabel>Division</FormInputLabel>
              <FormInputWrapper>
                <InputFormController
                  name="division"
                  label=""
                  placeholder="Division"
                  register={employmentForm.register}
                  control={employmentForm.control}
                />
              </FormInputWrapper>
            </>
          )}
          {showUniversity && (
            <>
              <FormInputLabel>University</FormInputLabel>
              <FormInputWrapper>
                <InputFormController
                  name="university"
                  label="University"
                  placeholder="University"
                  register={employmentForm.register}
                  control={employmentForm.control}
                />
              </FormInputWrapper>
            </>
          )}
          {showEmployer && (
            <>
              <FormInputLabel>Employer Industry</FormInputLabel>
              <FormInputWrapper>
                <SelectFormController
                  name="industry"
                  label="Employer Industry"
                  menuItems={industryMappings}
                  register={employmentForm.register}
                  control={employmentForm.control}
                />
              </FormInputWrapper>
              <FormInputLabel>Position</FormInputLabel>
              <FormInputWrapper>
                <SelectFormController
                  name="position"
                  label="Position"
                  menuItems={positionMappings}
                  register={employmentForm.register}
                  control={employmentForm.control}
                />
              </FormInputWrapper>
            </>
          )}
          {showTime && (
            <>
              {<FormInputLabel>{timeWithText}</FormInputLabel>}
              <Grid
                item
                xs={FULL_COLUMN_SIZE}
                sm={TWO_THIRDS_COLUMN_SIZE}
                sx={{
                  display: { sm: 'flex', xs: 'grid' },
                  gap: 0.5,
                  marginTop: 1.5,
                }}
              >
                <SelectFormController
                  name="years"
                  label="Years"
                  menuItems={yearsMappings}
                  register={employmentForm.register}
                  control={employmentForm.control}
                />

                <SelectFormController
                  name="months"
                  label="Months"
                  menuItems={monthsMappings}
                  register={employmentForm.register}
                  control={employmentForm.control}
                />
              </Grid>
            </>
          )}

          {showWorkNumber && (
            <>
              <FormInputLabel>Work contact number</FormInputLabel>
              <FormInputWrapper>
                <InputFormController
                  name="workPhone"
                  label="Work Phone"
                  placeholder="(include area code)"
                  register={employmentForm.register}
                  control={employmentForm.control}
                />
              </FormInputWrapper>
            </>
          )}

          <FormInputLabel>Frequency of income</FormInputLabel>
          <FormInputWrapper>
            <SelectFormController
              name="incomeFrequency"
              label="Income Frequency"
              menuItems={incomeFrequencyMappings}
              register={employmentForm.register}
              control={employmentForm.control}
            />
          </FormInputWrapper>
          {showRepaymentDatePicker && (
            <Container
              style={{
                position: 'absolute',
                bottom: '-70px',
                left: isMobile ? '10px' : '385px',
              }}
            >
              <RepaymentDate
                SetShowRepaymentDatePicker={setShowRepaymentDatePicker}
                displayRepaymentDate={showRepaymentDatePicker}
                onSalaryDayPick={onSalaryDayPick}
              />
            </Container>
          )}

          <PepFormField
            isPipOrPep={isPipOrPep}
            showPipOrPepDefinition={showPipOrPepDefinition}
            setShowPipOrPepDefinition={setShowPipOrPepDefinition}
            onChange={handleRadioChange}
          />
          {!submitLoading && (
            <>
              <Grid item xs={FULL_COLUMN_SIZE}>
                <Stack
                  flexGrow={1}
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  alignItems={{ xs: 'flex-start', sm: 'center' }}
                  justifyContent={{ xs: 'center', sm: 'space-between' }}
                  marginTop={'1rem'}
                >
                  <TextButton type="reset" removePadding onClick={props.onCloseEditing}>
                    Cancel
                  </TextButton>
                  <PrimaryButton type="submit">Update</PrimaryButton>
                </Stack>
              </Grid>
            </>
          )}
          {submitLoading && (
            <Grid item xs={FULL_COLUMN_SIZE}>
              <Stack alignItems={{ xs: 'center', sm: 'flex-end' }} marginTop={'1rem'}>
                <Box textAlign={'center'} width={{ xs: '100%', sm: '25rem' }}>
                  <Loading />
                </Box>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Form>
    </>
  );
};

export default ChangeEmploymentDetailsLN;
