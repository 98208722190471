export abstract class SessionUtils {

  static calculateTimeUntilTokenRefresh(tokenExpiry: string | undefined) {
    if (tokenExpiry !== undefined){
      const durationUntilExpiry = SessionUtils.calculateTimeUntilTokenExpiry (tokenExpiry) - 300000; // The token should be refreshed 300000 milliseconds (5 minutes) before expiry

      // less than a minute, set hardcoded expiry
      if ((durationUntilExpiry/1000) < 60){
        return 60 * 60 * 1000 * 12; // return 12 hours by default
      }

      return durationUntilExpiry;
    }
  }

  // This methods runs twice. To calculate the time until the popup, and to calculate the time until the auto logout
  static calculateTimeUntilTokenExpiry = (expirationTimestamp: string) => {
    const currentTime = new Date().getTime(); // current time in milliseconds
    const expirationDateCleaned = expirationTimestamp.replace(/^"(.*)"$/, '$1');
    const expirationTime = new Date(expirationDateCleaned).getTime();

    console.log("Token expiration date: "+ expirationTimestamp)
    console.log("Token expiration time in milliseconds: "+ expirationTime)
    console.log("Current time in milliseconds: "+ currentTime)

    const durationUntilExpiry = expirationTime - currentTime;
    console.log('Duration until expiry in milliseconds: ' + durationUntilExpiry); // in milliseconds. Should be 15 minutes

    return durationUntilExpiry;
  }

  static millisecondsUntilFiveMinutesBeforeExp(exp: number): number {
    // Convert expiration time from seconds to milliseconds
    const expMs = exp * 1000;

    // Calculate 5 minutes before the expiration time
    const fiveMinutesBeforeExpMs = expMs - (5 * 60 * 1000);

    // Get the current time in milliseconds
    const currentTimeMs = new Date().getTime();

    // Calculate the difference in milliseconds
    return  fiveMinutesBeforeExpMs - currentTimeMs;
  }
}
