/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { Iovation } from '../../components/Iovation/Iovation';
import { useAuthentication } from '../../contexts/AuthContext';
import Loading from '../../components/Loading/Loading';
import useLoanClient from '../../hooks/loan/Client';
import { L0JourneyRoutes, LNJourneyRoutes, LNJourneyShortenedRoutes, RouterRoutes } from '../../utils/RouterRoutes';
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useSnackBar } from '../../contexts/SnackBarContext';
import { LimiterContext } from '../../contexts/LimiterContext';
import { AdBlockPopup } from '../../components/AdBlockPopup/AdBlockPopup';
import {
  JourneyConfig,
  PostProcessing,
  l0journey,
  lnJourney,
  lnJourneyShortened,
} from './JourneyConfig/JourneyConfigs';
import { useTracking } from '../../Tracking/TrackingContext';
import { JourneyType, determineJourneyType } from '../../utils/Helpers/NavigationHelper';
import { StateProps } from './JourneyPages/VAS/CreditLifePlus/CreditLifePlus';

type ContextType = {
  next: (state?: StateProps | undefined) => void;
  goToProcessing: () => void;
  goToCreditLifePlus: () => void;
};

export function useWizardOutletContext() {
  return useOutletContext<ContextType>();
}

interface JourneyStep {
  step: number;
  journey:
  | JourneyConfig<LNJourneyShortenedRoutes>[]
  | JourneyConfig<LNJourneyRoutes>[]
  | JourneyConfig<L0JourneyRoutes>[];
}

export default function Wizard() {
  const { isAuthenticated } = useAuthentication();
  const [isLoading, setIsLoading] = useState(true);
  const loanClient = useLoanClient();
  const navigate = useNavigate();
  const { displaySnackBar } = useSnackBar();
  const { isConnectionFailure, showLimiter } = useContext(LimiterContext);
  const isLimiterActive = isConnectionFailure || showLimiter;
  const [openAdBlockPopup, setOpenAdBlockPopup] = React.useState(false);
  const { isExistingCustomer, isPersonalDetailsComplete, TrackError } = useTracking();
  const location = useLocation();
  //loanId gets passed through the state on navigate from my loan
  //We only use this for loan agreement, doc upload and counter offer
  const loanId = location && location.state ? location.state['loanId'] ?? '' : '';

  const validateJourneyPath = () => {
    const { journey, step } = determineJourneyStep();
    const isPostDecisionPage = PostProcessing.findIndex((page) => location.pathname.includes(page.path)) !== -1;
    const isUserInWrongJourney =
      step === -1 ||
      !location.pathname.includes(isExistingCustomer ? RouterRoutes.existingJourney : RouterRoutes.newJourney);
    if (isUserInWrongJourney && !isPostDecisionPage) {
      navigate(`/${isExistingCustomer ? RouterRoutes.existingJourney : RouterRoutes.newJourney}/${journey[0].path}`);
    }
  };

  const isInPostprocessing = useCallback(() => {
    let isPostProcessingPage = false;
    PostProcessing.forEach(item => {
      if (location.pathname.includes(item.path))
        isPostProcessingPage = true;
    });
    return isPostProcessingPage;
  }, [location.pathname]);

  // This is to stop user from navigating back to the journey after processing
  useEffect(() => {
    const handlePopState = () => {
      const isProcessing = location.pathname.includes(L0JourneyRoutes.processing);
      if (isInPostprocessing() || isProcessing) {
        navigate(`/${RouterRoutes.myLoan}`);
      }
    };

    window.addEventListener('popstate', handlePopState);

    // Clean up the event listener on component unmount
    // Need setTimeout to prevent removing popstate event listener before popstate event is triggered.
    return () => {
      setTimeout(() => {
        window.removeEventListener('popstate', handlePopState);
      }, 0);
    };
  }, [isInPostprocessing, location.pathname, navigate]);

  const redirect = async () => {
    try {
      if (!isAuthenticated) {
        navigate(`/${RouterRoutes.login}`, { replace: true });
        return;
      }

      if (isLimiterActive) {
        navigate(`/${RouterRoutes.limiter}`, { replace: true });
        return;
      }

      if (loanId) setIsLoading(false);

      const applyValues = localStorage.getItem('applyValues');
      if (!applyValues && !loanId) {
        navigate(`/${RouterRoutes.home}`, { replace: true });
        return;
      }

      validateJourneyPath();

      //Don't call open loan details again if coming from my loan
      if (isAuthenticated && !loanId) {
        const openLoanDetailsResponse = await loanClient.getOpenLoanDetails(undefined);

        const showAccountSummary = openLoanDetailsResponse.hasOpenLoan || openLoanDetailsResponse.isDne;

        if (showAccountSummary) {
          navigate(`/${RouterRoutes.myLoan}`, { replace: true });
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      displaySnackBar('Oops! An error occurred. Please try again.', 'error');
      navigate(`${RouterRoutes.home}`, { replace: true });
    }
  };

  const handleAdBlockPopupClose = () => {
    setOpenAdBlockPopup(false);
  };

  const determineJourneyStep = (): JourneyStep => {
    const journeyType = determineJourneyType(isExistingCustomer, isPersonalDetailsComplete);
    if (journeyType === JourneyType.lnJourneyShortened)
      return {
        journey: lnJourneyShortened,
        step: lnJourneyShortened.findIndex((page) => location.pathname.includes(page.path)),
      };
    else if (journeyType === JourneyType.lnJourney)
      return { journey: lnJourney, step: lnJourney.findIndex((page) => location.pathname.includes(page.path)) };
    return { journey: l0journey, step: l0journey.findIndex((page) => location.pathname.includes(page.path)) };
  };

  const checkIfBlackboxDataExist = () => {
    if (!localStorage.getItem('blackbox')) {
      setOpenAdBlockPopup(true);
      TrackError('Missing blackbox data in journey');
    }
  };

  function next(state?: StateProps | undefined) {
    const { journey, step } = determineJourneyStep();
    const nextPath = journey[step + 1].path;
    navigate(nextPath, { state });
    if (step > 0) {
      checkIfBlackboxDataExist();
    }
  }

  //We use this for this for the one click journey
  function goToProcessing() {
    const { journey } = determineJourneyStep();
    const nextPath = journey[journey.length - 1].path;
    navigate(nextPath);
  }

  function goToCreditLifePlus() {
    const journeyType = determineJourneyType(isExistingCustomer, isPersonalDetailsComplete);

    let pathToNavigateTo: L0JourneyRoutes | LNJourneyRoutes | LNJourneyShortenedRoutes = L0JourneyRoutes.creditLifePlus;

    if (journeyType === JourneyType.lnJourneyShortened) pathToNavigateTo = LNJourneyShortenedRoutes.creditLifePlus;
    else if (journeyType === JourneyType.lnJourney) pathToNavigateTo = LNJourneyRoutes.creditLifePlus;
    else pathToNavigateTo = L0JourneyRoutes.creditLifePlus;

    navigate(pathToNavigateTo);
  }

  useEffect(() => {
    redirect();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Iovation />
      <AdBlockPopup handleDialogClose={handleAdBlockPopupClose} open={openAdBlockPopup} />
      <Stack
        sx={{
          width: '100%',
          flexGrow: 1,
          background: 'transparent',
        }}
      >
        {isLoading ? (
          <Loading text="Please wait while we retrieve your details." />
        ) : (
          <Outlet context={{ next, goToProcessing, goToCreditLifePlus }} />
        )}
      </Stack>
    </>
  );
}
