import React from 'react'
import styles from '../Flap/Flap.module.scss'

const Flap = (props) => {

  return (
    <div className={`${styles["wonga-flap"]} ${props.theme === "darkBlue" ? styles.darkBlue : styles.dark}`}>
      <div className={styles["flap-title-container"]}>
        <p>{props.title}</p>
      </div>
      <div className={styles["flap-content"]}>
        {props.children}
      </div>
    </div>
  )
}

export default Flap