import { Box, Container } from '@mui/material';
import React, { lazy, Suspense } from 'react';
import Loading from '../../../components/Loading/Loading';

const LegalDocuments = lazy(() => import('./LegalDocuments/LegalDocuments'));
const Statements = lazy(() => import('./Statements/Statements'));
const PaidUpLetters = lazy(() => import('./PaidUpLetters/PaidUpLetter'));

const LoanDocuments: React.FunctionComponent = () => {
  return (
    <Container disableGutters sx={{ display: 'flex', flexDirection: 'column', gap: { xs: '2.5rem', md: '6rem' } }}>
      <Suspense
        fallback={
          <Box display={'flex'} justifyContent={'center'} height={'80vh'} width={'100%'}>
            <Loading />
          </Box>
        }
      >
        <LegalDocuments />
        <Statements />
        <PaidUpLetters />
      </Suspense>
    </Container>
  );
};

export default LoanDocuments;
