/* eslint  @typescript-eslint/no-non-null-assertion: 0 */
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { IGetRepayStatusResponse, IOpenLoanDetailsResponse } from '../../../../clients/LoanClient';
import { IGetPersonalDetailsResponse } from '../../../../clients/AccountClient';
import { useNavigate } from 'react-router';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import { RouterRoutes } from '../../../../utils/RouterRoutes';
import { Avatar, Box, Button, Container, keyframes, Stack, useMediaQuery, useTheme } from '@mui/material';
import { formatMoney } from '../../../../utils/Helpers/SliderHelperFunctions';
import RepayNoticeModal from '../../../../components/RepayNoticeModal/RepayNoticeModal';
import { formatDateWithDefault } from '../../../../utils/Helpers/DateHelpers';
import OrangeInfoIcon from '../../../../assets/img/InformationOrangeIcon.png';
import RedInfoIcon from '../../../../assets/img/error-warning-fill.png';
import InfoIcon from '../../../../assets/img/info-icon.png';
import FeesBreakdown from '../FeesBreakdown/FeesBreakdown';
import chevronLeft from '../../../../assets/img/svg/Chevron.svg';
import ChevronRight from '../../../../assets/img/chevron-right.png';
import TextButton from '../../../../components/Buttons/TextButton';
import Transactions from '../Transactions/Transactions';
import NavigationButton from '../../../../components/Buttons/NavigationButton';
import LoanDetails from '../../../../components/LoanDetails/LoanDetails';
import SettlementDisclaimerPopup from './SettlementDisclaimerPopup';
import NavigateChevronLink from '../../../../components/NavigateChevronLink/NavigateChevronLink';

interface Arrears {
  openLoanResponse: IOpenLoanDetailsResponse;
  personalDetails: IGetPersonalDetailsResponse;
  repayStatus: IGetRepayStatusResponse | null;
}

const Arrears: React.FunctionComponent<Arrears> = ({ openLoanResponse, repayStatus }: Arrears) => {
  const {
    collectionDates,
    term,
    loanAmount,
    termInDays,
    totalServiceFees,
    interestAmount,
    initiationFee,
    serviceFee,
    insuranceFee,
    totalRepayable,
    deathBenefit, } =
    openLoanResponse.quotationData || {};
  const { currentBalance, totalArrearsAmount, loanId } = openLoanResponse.loanData || {};
  const navigate = useNavigate();
  const [showRepayModal, setShowRepayModal] = useState<boolean>(false);
  const [showLoanDetails, setShowLoanDetails] = useState<boolean>(false);
  const [showFeesBreakdown, setShowFeesBreakdown] = useState<boolean>(false);
  const [showTransactionHistory, setShowTransactionHistory] = useState<boolean>(false);
  const [showSettlementDisclaimer, setShowSettlementDisclaimer] = useState<boolean>(false);
  const isInstallmentLoan = collectionDates?.length! > 0;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const repayEnabled = repayStatus?.enabled === true;

  const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(247, 144, 47, 0.5);
  }

  70% {
    box-shadow: 0 0 0 20px rgba(247, 144, 47, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(247, 144, 47, 0);
  }
`;


  const navigateToRepay = () => {
    navigate(`${RouterRoutes.repay}`);
  };

  const getAmountOwed = (): string => {
    return formatMoney(totalArrearsAmount ?? 0);
  };

  const showDetails = () => {
    setShowLoanDetails(true);
  };

  const hideDetails = () => {
    setShowLoanDetails(false);
  };

  const showTransactions = () => {
    setShowTransactionHistory(true);
  };

  const hideTransactions = () => {
    setShowTransactionHistory(false);
  };

  const feesBreakdownHandler = () => {
    setShowFeesBreakdown(!showFeesBreakdown);
  };

  if (showLoanDetails)
    return (
      <Container>
        <Button
          onClick={hideDetails}
          sx={{
            padding: 0,
            minWidth: '5rem',
            textDecoration: 'none',
            color: 'inherit',
            '&.MuiButtonBase-root:hover': { bgcolor: 'transparent' },
          }}
        >
          <Stack
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            marginBottom={{ xs: '2rem', md: '2.4rem' }}
          >
            <Avatar
              src={chevronLeft}
              alt="Back button icon"
              sx={{
                width: '1.4rem',
                height: '1.4rem',
                marginRight: '0.4rem',
              }}
            />
            <Typography
              display={'inline'}
              fontSize={'1.5rem'}
              fontWeight={'300'}
              lineHeight={{ xs: '2.2rem' }}
              letterSpacing={{ xs: '-.0.04rem' }}
            >
              Back
            </Typography>
          </Stack>
        </Button>
        <LoanDetails
          isInstalmentLoan={isInstallmentLoan}
          loanAmount={loanAmount ?? 0}
          totalInterestAndFees={(totalServiceFees ?? 0) + (interestAmount ?? 0)}
          term={isInstallmentLoan ? term ?? 0 : termInDays ?? 0}
          onShowFeesBreakdown={feesBreakdownHandler}
        />
        {showFeesBreakdown ? (
          <FeesBreakdown
            initiationFee={initiationFee}
            serviceFee={serviceFee}
            totalInterest={interestAmount}
            insuranceFee={insuranceFee}
            feesBreakdownHandler={feesBreakdownHandler}
            totalRepayable={(totalRepayable ?? 0) + (deathBenefit?.contractValue ?? 0)}
            deathBenefitContractValue={deathBenefit?.contractValue ?? 0}
          />
        ) : null}
      </Container>
    );

  if (showTransactionHistory)
    return (
      <>
        <Button
          onClick={hideTransactions}
          sx={{
            marginLeft: 2,
            padding: 0,
            minWidth: '5rem',
            textDecoration: 'none',
            color: 'inherit',
            '&.MuiButtonBase-root:hover': { bgcolor: 'transparent' },
          }}
        >
          <Stack
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            marginBottom={{ xs: '2rem', md: '2.4rem' }}
          >
            <Avatar
              src={chevronLeft}
              alt="Back button icon"
              sx={{
                width: '1.4rem',
                height: '1.4rem',
                marginRight: '0.4rem',
              }}
            />
            <Typography
              display={'inline'}
              fontSize={'1.5rem'}
              fontWeight={'300'}
              lineHeight={{ xs: '2.2rem' }}
              letterSpacing={{ xs: '-.0.04rem' }}
            >
              Back
            </Typography>
          </Stack>
        </Button>
        <Transactions loanId={loanId}></Transactions>
      </>
    );

  const SoftArrearsContent = () => {
    return <>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={'1rem'}
      >
        <Avatar src={OrangeInfoIcon} alt="icon" sx={{ width: '1.6rem', height: '1.6rem' }} />
        <Typography variant="h2" fontSize={{ xs: '1.8rem', md: '2.4rem' }} fontWeight={600} lineHeight={'2rem'} color={theme.palette.secondary.main}>
          Overdue
        </Typography>
      </Stack>
      <Typography
        fontSize={'1.5rem'}
        fontWeight={300}
        lineHeight={'1.8rem'}>
        There is a <Typography component={'span'} fontSize={'1.5rem'} fontWeight={400} lineHeight={'1.8rem'} color={theme.palette.secondary.main}>small amount overdue</Typography> on your loan. Please settle this amount to avoid interest accruing on your outstanding amount. If paid already, please ignore.
      </Typography>
      <Stack justifyContent={'center'} width={'100%'}>
        <Typography fontSize={'1.8rem'} fontWeight={600} lineHeight={'2rem'}>
          Overdue: <Typography component={'span'} fontSize={'1.8rem'} fontWeight={600} lineHeight={'2rem'} color={theme.palette.secondary.main}>R {getAmountOwed()}</Typography>
        </Typography>
      </Stack></>
  };

  const HardArrearsContent = () => {
    return <>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={'1rem'}
      >
        <Avatar src={RedInfoIcon} alt="icon" sx={{ width: '2rem', height: '2rem' }} />
        <Typography variant="h2" fontSize={{ xs: '1.8rem', md: '2.4rem' }} fontWeight={600} lineHeight={'2rem'} color={'#D40000'}>
          Overdue
        </Typography>
      </Stack>
      <Typography
        fontSize={'1.5rem'}
        fontWeight={300}
        lineHeight={'1.8rem'}>
        Your loan is currently <Typography component={'span'} fontSize={'1.5rem'} fontWeight={400} lineHeight={'1.8rem'} color={'#D40000'}>in arrears.</Typography> Please settle your arrears balance to avoid interest accruing on your outstanding amount.
      </Typography>
      <Stack justifyContent={'center'} width={'100%'}>
        <Typography fontSize={'1.8rem'} fontWeight={600} lineHeight={'2rem'}>
          Overdue: <Typography component={'span'} fontSize={'1.8rem'} fontWeight={600} lineHeight={'2rem'} color={'#D40000'}>R {getAmountOwed()}</Typography>
        </Typography>
      </Stack></>
  };

  return (
    <>
      {showRepayModal && <RepayNoticeModal showModal={showRepayModal} onClose={navigateToRepay} />}
      <NavigateChevronLink route={`/${RouterRoutes.myLoan}`}>My loans</NavigateChevronLink>
      <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'center'} gap={'0.8rem'} marginTop={isMobile ? '2rem' : '4rem'}>
        <Typography
          variant="body1"
          fontSize={'3.2rem'}
          fontWeight={600}
          lineHeight={'2.2rem'}
          letterSpacing={'-0.04em'}
          textAlign={'center'}
          color={theme.palette.primary.dark}
        >
          R {formatMoney(currentBalance ?? 0)}
        </Typography>
        <Box paddingBottom={'4px'} sx={{ postion: 'relative' }}>
          <Avatar src={InfoIcon} alt="icon" sx={{ width: '1.8rem', height: '1.8rem', cursor: 'pointer', animation: `${pulse} 1.5s infinite cubic-bezier(0.66, 0, 0, 1)` }} onClick={() => setShowSettlementDisclaimer(prev => !prev)} />
          {showSettlementDisclaimer && <SettlementDisclaimerPopup showSettlementDisclaimer={showSettlementDisclaimer} setShowSettlementDisclaimer={setShowSettlementDisclaimer} />}
        </Box>
      </Stack>

      <Stack
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        marginTop={'1.1rem'}
        marginBottom={'2.4rem'}
      >
        <Typography variant="body1" fontSize={'1.4rem'} fontWeight={400} lineHeight={'1.7rem'}>
          Settlement balance
        </Typography>
        <Typography variant="body1" fontSize={'1.4rem'} fontWeight={300} lineHeight={'1.7rem'}>
          {`${formatDateWithDefault(undefined, 'dd MMM yyyy')}`}
        </Typography>
      </Stack>

      <Stack
        flexDirection={"column"}
        alignItems={'center'}
        justifyContent={'center'}
        padding={{ xs: '1.2rem 1.6rem' }}
        sx={{ backgroundColor: theme.palette.grey[200], borderRadius: '1rem' }}
        gap={'1.2rem'}
      >
        {parseFloat(getAmountOwed()) < 90 ? <SoftArrearsContent /> : <HardArrearsContent />}
      </Stack>

      <TextButton onClick={showTransactions} removePadding>
        <Typography fontSize={'16px'}>View full transaction history</Typography>
      </TextButton>

      <Stack
        borderTop={'1px solid #E4E4E4'}
        sx={{ padding: '1rem 0rem 2rem 0rem' }}
        spacing={{ xs: '2rem', sm: '3.2rem' }}
      >
        {repayEnabled && (
          <Stack
            display={'flex'}
            flexDirection={'column'}
            alignItems={{ xs: 'center', sm: 'flex-end' }}
            gap={'1.2rem'}
            sx={{ margin: { xs: '1.3rem 0rem 2rem 0rem', sm: '1.9rem 0rem 2rem 0rem' } }}
          >
            <Typography variant="body1" fontSize={'1.5rem'} fontWeight={300} lineHeight={'2.2rem'}>
              <Typography component="span" variant="body1" fontSize={'1.5rem'} fontWeight={500} lineHeight={'2.2rem'}>
                Settle
              </Typography>{' '}
              or{' '}
              <Typography component="span" variant="body1" fontSize={'1.5rem'} fontWeight={500} lineHeight={'2.2rem'}>
                repay any amount
              </Typography>{' '}
              towards your loan
            </Typography>
            <PrimaryButton onClick={() => setShowRepayModal(true)}>Make payment</PrimaryButton>
          </Stack>
        )}
        <NavigationButton onClick={showDetails}>
          <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
            <Typography fontSize={'1.6rem'} letterSpacing={'-0.4px'} fontWeight={400}>
              Loan application details
            </Typography>
            <img src={ChevronRight} alt="Chevron right" />
          </Stack>
        </NavigationButton>
      </Stack>
    </>
  );
};

export default Arrears;
