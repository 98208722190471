import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    fontFamily: "Nunito",
    h1: {
      fontSize: "56px"
    },
    h2: {
      fontSize: "34px"
    },
    h3: {
      fontSize: "24px"
    },
    h4: {
      fontSize: "18px"
    },
    body1: {
      fontSize: "15px"
    },
    body2: {
      fontSize: "12px"
    }
  },
  palette: {
    primary: {
      main: '#008fd5',
      dark: '#0069c0'
    },
    secondary: {
      main: '#f7902f',
      dark: '#f87428'
    },
    grey: {
      '200': '#f5f5f5',
      '400': '#d6d6d6',
      '500': '#757575',
      '600': '#777',
      '700': '#4e4e4e'
        },
    success: {
      main: "#7ac206"
    },
    warning: {
      main: '#f87428'
    },
    error: {
      main: '#d0021b'
    },
    info: {
      main: '#008fd5'
    },
    text: {
      primary: '#333'
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          '&.MuiButton-root': {
            fontSize: theme.typography.body1.fontSize,
            textTransform: 'none',
            textDecoration: 'none',
          },
          ...(ownerState.variant === 'contained' && ownerState.color === 'secondary' && {
            color: theme.palette.common.white
          })
        }),
      }
    },
    MuiSnackbar: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          '.MuiPaper-root.MuiSnackbarContent-root': {
            color: theme.palette.common.white,
            fontSize: "12px",
          },
          ...(ownerState.color === "success" && {
            '& .MuiPaper-root.MuiSnackbarContent-root': {
              backgroundColor: theme.palette.primary.main,
            }
          }),
          ...(ownerState.color === "error" && {
            '& .MuiPaper-root.MuiSnackbarContent-root': {
              backgroundColor: theme.palette.error.main,
            }
          }),
        }),
      }
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          '& .MuiGrid-item': {
            paddingLeft: "0px !important",
            marginBottom: "1rem"
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiFormLabel-root': {
            color: theme.palette.text.primary
          }
        }),
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiInputBase-root': {
            borderRadius: theme.spacing(1),
            '& .MuiOutlinedInput-notchedOutline ': {
              borderColor: `${theme.palette.grey['300']}`,
            },
          },
        }),
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
      }
    }
  }
});