import {
  Checkbox,
  CheckboxProps,
  Container,
  FormControl,
  Typography,
  useTheme,
} from "@mui/material";
import { Controller } from "react-hook-form";

interface FormCheckBoxProps extends CheckboxProps {
  name: string;
  label?: string;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  control: any;
}

const FormCheckBox: React.FC<FormCheckBoxProps> = (props: FormCheckBoxProps) => {
  const theme = useTheme();
  const label = { inputProps: { "aria-label": props.label } };
  return (
    <>
      <Controller
        name={props.name}
        control={props.control}
        render={({ field }) => (
          <FormControl fullWidth>
            <Container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography fontWeight={300}>{props.label}</Typography>
              <Checkbox
                {...label}
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: theme.typography.h2,
                  },
                }}
                {...props}
                {...field}
              />
            </Container>
          </FormControl>
        )}
      />
    </>
  );
};

export default FormCheckBox;
