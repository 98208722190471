import { Stack, StepLabel, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { formatMoney } from '../../utils/Helpers/SliderHelperFunctions';

interface DebitOrderLabelProps {
  title: ReactElement;
  amount: number;
}

const DebitOrderLabel = (props: DebitOrderLabelProps) => {
  const { title, amount } = props;
  return (
    <StepLabel>
      <Stack width={'100%'} direction={'row'} justifyContent={'space-between'}>
        <Typography
          fontSize={'1.5rem'}
          fontWeight={300}
          lineHeight={'2.2rem'}
          letterSpacing={'-0.4px'}
          component={'span'}
        >
          {title}
        </Typography>
        <Typography
          fontSize={'1.5rem'}
          fontWeight={700}
          lineHeight={'2.2rem'}
          letterSpacing={'-0.4px'}
          component={'span'}
        >
          {`R ${formatMoney(amount)}`}
        </Typography>
      </Stack>
    </StepLabel>
  );
};

export default DebitOrderLabel;
