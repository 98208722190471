import { Box, styled, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import React from 'react';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import classes from './AppBar.module.scss';
import { MobileMenuItem, WongaLogoIcon } from './AppBar';

interface MobileMenuProps {
  handleOpenNavMenu: (event: React.MouseEvent<HTMLElement>) => void;
  handleCloseNavMenu: () => void;
  isOpen: boolean;
  anchorNavElement: null | HTMLElement;
  mobileMenuItems: MobileMenuItem[];
}

const MuiMenuOpenIcon = styled(MenuOpenRoundedIcon)({
  width: '3em',
  height: '3em',
});

const MuiMenuIcon = styled(MenuRoundedIcon)({
  width: '3em',
  height: '3em',
});

const MuiMenuItem = styled(MenuItem)(({ theme }) => ({
  lineHeight: '2.8rem',
  margin: '0.4rem 0.4rem',
  padding: '0 0.8rem',
  minHeight: '4rem',
  fontWeight: 300,

  [theme.breakpoints.down('md')]: {
    minHeight: '4rem',
  },
}));

const ActiveMuiMenuItem = styled(MenuItem)(({ theme }) => ({
  fontWeight: '600',
  lineHeight: '2.8rem',
  margin: '0.4rem 0.4rem',
  padding: '0 0.8rem',
  minHeight: '4rem',
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.grey['200'],
  borderRadius: '0.8rem',

  [theme.breakpoints.down('md')]: {
    minHeight: '4rem',
  },
}));

const MobileMenu: React.FunctionComponent<MobileMenuProps> = (props: MobileMenuProps) => {
  const { handleOpenNavMenu, handleCloseNavMenu, isOpen, anchorNavElement, mobileMenuItems } = props;

  const buildMobileMenu = (item: MobileMenuItem, index: number) => {
    if (item.showActive) {
      return (
        <Box
          key={index}
          sx={
            item.showBorderBottom
              ? { borderBottom: '1px #e4e4e4 solid', margin: '0.4rem 0.4rem' }
              : { margin: '0.4rem 0.4rem' }
          }
        >
          <ActiveMuiMenuItem onClick={item.onClick}>{item.title}</ActiveMuiMenuItem>
        </Box>
      );
    } else {
      return (
        <Box
          key={index}
          sx={
            item.showBorderBottom
              ? { borderBottom: '1px #e4e4e4 solid', margin: '0.4rem 0.4rem' }
              : { margin: '0.4rem 0.4rem' }
          }
          role="none"
        >
          <MuiMenuItem onClick={item.onClick}>
            <Typography textAlign="center" fontWeight={300}>
              {item.title}
            </Typography>
          </MuiMenuItem>
        </Box>
      );
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: { xs: 'flex', md: 'none' },
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box />
      <Box
        sx={{
          display: { xs: 'flex', md: 'none' },
          alignSelf: 'center',
          marginLeft: '4.5rem',
        }}
        className={classes.logo}
      >
        <WongaLogoIcon />
      </Box>
      <IconButton
        size="large"
        aria-label="navigation menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="primary"
        className={classes['hamburger-icon-wrapper']}
      >
        {isOpen ? (
          <MuiMenuOpenIcon />
        ) : (
          <>
            <MuiMenuIcon />
          </>
        )}
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorNavElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={isOpen}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: 'block', md: 'none' },
        }}
        MenuListProps={{ sx: { minWidth: '17.6rem', py: 0 } }}
      >
        {mobileMenuItems.filter((item) => item.showItem).map(buildMobileMenu)}
      </Menu>
    </Box>
  );
};

export default MobileMenu;
