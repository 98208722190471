import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import styles from './Base.module.scss';
import { Box } from '@mui/material';
import Loading from '../Loading/Loading';

const Base: React.FunctionComponent = () => {
  return (
    <>
      <div className={styles.app}>
        <div className={styles.content}>
          <Suspense
            fallback={
              <Box display={'flex'} justifyContent={'center'} height={'80vh'} width={'100%'}>
                <Loading />
              </Box>
            }
          >
            <Outlet></Outlet>
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default Base;
