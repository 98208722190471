import React from 'react';
import { Avatar, Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import styles from './Eft.module.scss';
import lightbulb from '../../../../../assets/img/svg/lightbulb-big.svg';
import { IOpenLoanDetailsResponse } from '../../../../../clients/LoanClient';
import KeyValuePair from '../../../../../models/KeyValuePair';
import { useNavigate } from 'react-router-dom';
import { formatMoney } from '../../../../../utils/Helpers/SliderHelperFunctions';
import chevronLeft from '../../../../../assets/img/svg/Chevron.svg';
import { formatDateWithDefault } from '../../../../../utils/Helpers/DateHelpers';

interface EftProps {
  openLoanResponse: IOpenLoanDetailsResponse;
}

const Eft: React.FunctionComponent<EftProps> = ({ openLoanResponse }: EftProps) => {
  const { currentBalance, accountNumber } = openLoanResponse.loanData || {};
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const bankDetails: KeyValuePair<string>[] = [
    { key: 'Bank', value: 'Absa' },
    { key: 'Account Name', value: 'Savings' },
    { key: 'Account number', value: '4077955749' },
    { key: 'Branch Code', value: '631009' },
    { key: 'Beneficiary reference', value: accountNumber ?? "" },
  ];

  return (
    <div className={styles['eft-container']}>
      <Box onClick={() => navigate(-1)} style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>
        <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} marginBottom={{ xs: '2rem', md: '2.4rem' }}>
          <Avatar
            src={chevronLeft}
            alt="Back button icon"
            sx={{
              width: '1.4rem',
              height: '1.4rem',
              marginRight: '0.4rem',
            }}
          />
          <Typography
            display={'inline'}
            fontSize={'1.5rem'}
            fontWeight={'300'}
            lineHeight={{ xs: '2.2rem' }}
            letterSpacing={{ xs: '-.0.04rem' }}
          >
            Back
          </Typography>
        </Stack>
      </Box>
      <Typography
        variant="h1"
        fontSize={{ xs: '2.4rem', sm: '3.2rem' }}
        fontWeight={400}
        lineHeight={'3.2rem'}
        letterSpacing={{ xs: '-0.072rem', sm: '-0.05rem' }}
      >
        Pay using EFT
      </Typography>
      <div className={styles['eft-hint-container']}>
        <img src={lightbulb} aria-label="Lightbulb Icon" className={styles['eft-hint-icon']} />
        <Typography fontWeight={300} fontSize={'1.5rem'} component={'span'}>
          Please note that repayments made by EFT that are within
          <Typography fontWeight={500} component={'span'}>
            {" "}5 days{" "}
          </Typography>
          of your next debit order collection may not be processed in time. Once payment is made, this can take
          <Typography fontWeight={500} component={'span'}>
            {" "}2-3 days to reflect{" "}
          </Typography>
          on your loan account.
        </Typography>
      </div>
      <Typography
        variant="body1"
        fontSize={'3.2rem'}
        fontWeight={600}
        lineHeight={'2.2rem'}
        letterSpacing={'-0.04em'}
        textAlign={'center'}
        marginTop={isMobile ? '2.4rem' : '2.8rem'}
        color={theme.palette.primary.dark}
      >
        R {formatMoney(currentBalance ?? 0)}
      </Typography>
      <Stack
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        marginTop={'1.1rem'}
        marginBottom={'2rem'}
      >
        <Typography variant="body1" fontSize={'1.4rem'} fontWeight={400} lineHeight={'1.7rem'}>
          Settlement balance
        </Typography>
        <Typography variant="body1" fontSize={'1.4rem'} fontWeight={300} lineHeight={'1.7rem'}>
          {`${formatDateWithDefault(undefined, 'dd MMM yyyy')}`}
        </Typography>
      </Stack>
      {bankDetails.map((item, index) => (
        <div key={index} className={index === 0 ? styles['eft-table-first-row'] : styles['eft-table']}>
          <div>{item.key}</div>
          <div>{item.value}</div>
        </div>
      ))}
    </div>
  );
};

export default Eft;
