import { Container, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ErrorBlock from '../ErrorBlock/ErrorBlock';
import { theme } from '../../theme/Theme';
import TextButton from '../Buttons/TextButton';
import SliderWithFlap from '../Sliders/slider-with-flap/slider-with-flap';

const ChangeLoanValuesNewDesign: React.FC<{ onEditing?: (isEditing: boolean) => void }> = ({ onEditing }) => {
  const [loanAmount, setLoanAmount] = useState();
  const [showSlider, setShowSlider] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getApplyNowValues = () => {
    const applyValuesString = localStorage.getItem('applyValues');

    if (applyValuesString) {
      const applyValues = JSON.parse(applyValuesString);
      setLoanAmount(applyValues.amount);
    }
  };

  const ChangeLoanDetails = () => {
    setShowSlider(true);
    if (onEditing) {
      onEditing(true);
    }
  };

  const onChangeUpdateLoanValues = () => {
    setShowSlider(false);
    if (onEditing) {
      onEditing(false);
    }
  };

  useEffect(() => {
    getApplyNowValues();
  }, [showSlider]);

  return (
    <>
      {showSlider ? (
        <>
          <Container maxWidth="md">
            <SliderWithFlap updateLoanValues={onChangeUpdateLoanValues} />

            <Grid container justifyContent={'space-between'} sx={{ marginTop: { md: '30px', xs: '20px' } }}>
              <ErrorBlock>
                <Typography variant="body1">Please edit your loan details and click Apply now.</Typography>
              </ErrorBlock>
            </Grid>
          </Container>
        </>
      ) : (
        <Stack direction={isMobile ? 'column' : 'row'} alignItems={'baseline'} spacing={isMobile ? '0rem' : '1.8rem'}>
          <Typography variant="h1" fontSize={{ md: '3.2rem', xs: '2.2rem' }} lineHeight={'3.5rem'} fontWeight={400}>
            You&apos;re applying to borrow <strong>{`R${loanAmount}`}</strong>
          </Typography>
          <TextButton removePadding onClick={ChangeLoanDetails}>
            Change loan amount
          </TextButton>
        </Stack>
      )}
    </>
  );
};

export default ChangeLoanValuesNewDesign;
