import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { FULL_COLUMN_SIZE, THIRD_COLUMN_SIZE, TWO_THIRDS_COLUMN_SIZE } from '../../../utils/GridColumnSizeDefinitions';
import { MobileVerificationData } from '../../Otp/Interfaces';
import { Form } from '../Form';
import FormInputLabel from '../FormInputLabel';
import FormInputWrapper from '../FormInputWrapper';
import InputFormController from '../../MuiInput/FormControllers/InputFormController';
import PrimaryButton from '../../Buttons/PrimaryButton';
import { useAppSettings } from '../../../contexts/AppSettingsContext';
import ButtonLoadingIndicator from '../../Loading/ButtonLoadingIndicator';
import { TurnstileInstance } from '@marsidev/react-turnstile';
import TurnstileSize from '../../../utils/Turnstile';
import { CFTurnstile } from '../../CloudflareTurnstile/CFTurnstile';

interface RequestOtpProps {
  isMobile: boolean;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  /* Not sure what Type would be appropriate here. */
  mobileNumberForm: any;
  requestOtp: (data: MobileVerificationData) => {};
  submitLoading: boolean;
  turnstileWidgetRef: React.MutableRefObject<TurnstileInstance | null>;
  cfTurnstileToken: string | null;
  turnstileSize: TurnstileSize;
  setTurnstileSize: React.Dispatch<React.SetStateAction<TurnstileSize>>;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
}

const RequestOtp: React.FC<RequestOtpProps> = (props) => {
  const { turnstileWidgetRef, turnstileSize, cfTurnstileToken, setTurnstileSize, setToken } = props;
  const appSettings = useAppSettings();
  const cfTurnstileDisabled = appSettings.cfTurnstileConfig.cfTurnstileDisabled;

  return (
    <Form form={props.mobileNumberForm} onSubmit={(data: MobileVerificationData) => props.requestOtp(data)}>
      <Grid container>
        <FormInputLabel>Cell phone number</FormInputLabel>
        <FormInputWrapper>
          <InputFormController
            name="cellphoneNumber"
            label="Cell phone number"
            placeholder="Cell phone number"
            register={props.mobileNumberForm.register}
            control={props.mobileNumberForm.control}
          />
        </FormInputWrapper>
      </Grid>

      {!cfTurnstileDisabled && (
        <Stack alignItems={{ md: 'flex-end', xs: 'center' }} gap={1} marginBottom={{ xs: '2rem', md: '1.6rem' }}>
          <CFTurnstile
            ref={turnstileWidgetRef}
            siteKey={appSettings.cfTurnstileConfig.cfTurnstileOtpWidgetSiteKey}
            component="RequestOtp"
            action="Otp"
            turnstileSize={turnstileSize}
            setTurnstileSize={setTurnstileSize}
            setToken={setToken}
          />
        </Stack>
      )}
      <Grid container marginBottom={'5rem'} marginTop={{ xs: '2.2rem', md: '6.2rem' }}>
        <Grid item xs={0} sm={THIRD_COLUMN_SIZE}></Grid>
        <Grid item xs={FULL_COLUMN_SIZE} sm={TWO_THIRDS_COLUMN_SIZE}>
          {props.submitLoading ? (
            <ButtonLoadingIndicator />
          ) : (
            <Stack
              flexDirection={{ xs: 'column', sm: 'row' }}
              justifyContent={{ xs: 'center', sm: 'space-between' }}
                alignItems={'center'}
              flexGrow={1}
            >
              <Typography variant="body1">We will send you a one time pin to verify your cell phone number</Typography>
              <PrimaryButton
                type="submit"
                disabled={
                  !props.mobileNumberForm.formState.isValid || (!cfTurnstileDisabled && cfTurnstileToken === null)
                }
              >
                Send OTP
              </PrimaryButton>
            </Stack>
          )}
        </Grid>
      </Grid>
    </Form>
  );
};

export default RequestOtp;
