/* eslint-disable @typescript-eslint/no-explicit-any */
import { Controller, useFormContext } from "react-hook-form";
import { FieldName, MenuItem } from "./types";
import InputSelect from "../InputSelect/InputSelect";

interface FormControlProps {
  name: FieldName;
  label: string;
  control: any;
  register: any;
  menuItems: MenuItem[];
  disabled?: boolean;
}

const SelectFormController: React.FC<FormControlProps> = (props) => {
  const { control, name, register, menuItems, disabled } = props;

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <InputSelect
          disabled={disabled}
          name={props.name}
          value={field.value}
          menuItems={menuItems}
          onBlur={field.onBlur}
          register={register}
          isError={!!errors[name]}
          errorText={errors[name]?.message}
        />
      )}
    />
  );
};

export default SelectFormController;
