import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "./Loading.module.scss";
import { Typography } from "@mui/material";

interface LoadingText {
  text?: string;
}
const Loading = ({ text }: LoadingText) => {
  return (
    <div className={styles["circular-progress-container"]}>
      <Typography variant="body1">{text}</Typography>
      <CircularProgress
        sx={{
          marginTop: 1,
          marginBottom: 1,
        }}
      />
    </div>
  );
};

export default Loading;
